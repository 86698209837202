import React from 'react'
import { Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { useGetVenue, useUpdateVenue } from 'api/venue'
import EditVenue from './EditVenue'
import Loading from 'components/Loading'
import type { Venue } from 'lib/supabase'
import ViewVenue from './ViewVenue'

const VenuePage: React.FC = () => {
	const { venueId } = useParams()
	const [editing, setEditing] = React.useState(false)
	const venue = useGetVenue({
		venueId: venueId || '',
		options: { enabled: !!venueId },
	})
	const updateVenue = useUpdateVenue()
	const navigate = useNavigate()

	if (venue.isError) {
		return <p>An error has occurred</p>
	}

	const handleCancel = () => {
		setEditing(false)
	}

	const handleSave = (newVenue: Venue) => {
		updateVenue.mutate({ venue: newVenue })
		setEditing(false)
	}

	const handleEdit = () => {
		setEditing(true)
	}

	return (
		<Box component={'main'}>
			{venue.isSuccess ? (
				<>
					{editing ? (
						<EditVenue
							venue={venue.data}
							onSave={handleSave}
							onCancel={handleCancel}
						/>
					) : (
						<ViewVenue
							venue={venue.data}
							onEdit={handleEdit}
							onBack={() => navigate('/venues')}
						/>
					)}
				</>
			) : (
				<Loading />
			)}
		</Box>
	)
}
export default VenuePage
