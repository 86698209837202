import { useInfiniteQuery } from '@tanstack/react-query'
import type { InfiniteQueryConfig } from 'lib/reactQuery'
import supabase, { TABLE_MESSAGES } from 'lib/supabase'

const MESSAGE_FETCH_LIMIT = 20

// keep the messages between groups and activities separate
export const getInfiniteQueryKey = (groupId: string, activityId: string) => [
	'group',
	groupId,
	'activity',
	activityId,
	'messages',
]

type PageParams = number

type FetchMessagesArgs = {
	pageParam: PageParams
	activityId: string
}
const fetchMessages = async ({
	pageParam = 0,
	activityId,
}: FetchMessagesArgs) => {
	const { data, error } = await supabase
		.from(TABLE_MESSAGES)
		.select()
		.eq('activity_id', activityId)
		.order('created_at', { ascending: false })
		.range(
			pageParam * MESSAGE_FETCH_LIMIT,
			(pageParam + 1) * MESSAGE_FETCH_LIMIT - 1
		)

	if (error) throw error

	return data
}

type getInfiniteByActivityIdArgs = {
	activityId: string
	groupId: string
	options?: InfiniteQueryConfig<typeof fetchMessages, PageParams>
}
export const getInfiniteByActivityId = ({
	activityId,
	groupId,
	options,
}: getInfiniteByActivityIdArgs) => {
	return useInfiniteQuery({
		...options,
		queryKey: getInfiniteQueryKey(groupId, activityId),
		queryFn: ({ pageParam }) => fetchMessages({ pageParam, activityId }),
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages, lastPageParam) => {
			return lastPage.length ? lastPageParam + 1 : undefined
		},
	})
}
