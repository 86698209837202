import React from 'react'
import { Controller, type Control } from 'react-hook-form'
import { FormLabel, Switch, type SwitchProps } from '@mui/material'

type Props = SwitchProps & {
	name: string
	label: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any, any>
}
const FormInputSwitch: React.FC<Props> = ({
	name,
	label,
	control,
	...switchProps
}) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'baseline',
						gap: '0.2rem',
					}}
				>
					<Switch
						{...(switchProps || {})}
						{...field}
						checked={field.value}
					/>
					<FormLabel
						style={{ paddingTop: '0.5rem' }}
						component='legend'
					>
						{label}
					</FormLabel>
				</div>
			)}
		/>
	)
}

export default FormInputSwitch
