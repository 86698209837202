import React, { useState } from 'react'
import {
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListItemAvatar,
	Avatar,
} from '@mui/material'
import AppHeader from 'components/AppHeader'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router'
import { useUserContext } from 'contexts/UserContext'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SubscribeDialog from './SubscribeDialog'
import ConfirmLeaveDialog from './ConfirmLeaveDialog'
import GroupForm, { GroupFormValues } from 'components/forms/prebuilt/GroupForm'
import { v4 as uuidV4 } from 'uuid'
import Loading from 'components/Loading'
import { track } from '@vercel/analytics'
import { useGroupsContext } from 'contexts/GroupsContext'
import { type Group } from 'lib/supabase'
import { addUserToGroup, removeUserFromGroup } from 'api/membership'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { DateTime } from 'luxon'

export default function GroupList() {
	const { profile, activeGroup, setActiveGroup } = useUserContext()
	const { groups, createGroup, userIsAdminOfGroup } = useGroupsContext()
	const { showSnackbar } = useSnackbarContext()
	const navigate = useNavigate()
	const [showLeaveDialog, setShowLeaveDialog] = useState(false)
	const [showSubscribeDialog, setShowSubscribeDialog] = useState(false)
	const [leaveGroupId, setLeaveGroupId] = useState<string>()
	const [creatingNewGroup, setCreatingNewGroup] = useState(false)

	const goBack = () => {
		navigate(-1)
	}

	const handleListClick = async (gid: string) => {
		await setActiveGroup(gid)
		navigate('/')
	}

	const handleNewGroup = () => {
		if (profile?.subscription_status == 'active') {
			setCreatingNewGroup(true)
		} else {
			setShowSubscribeDialog(true)
		}
	}

	const handleEditButtonClick = (path: string) => {
		navigate(`/group/${path}`)
	}

	const handleLeaveButtonClick = (groupId: string) => {
		setShowLeaveDialog(true)
		setLeaveGroupId(groupId)
	}

	const handleLeaveGroup = async () => {
		if (profile && groups.isSuccess && leaveGroupId) {
			const newGroups = groups.data.filter(
				(group) => group.id !== leaveGroupId
			)
			if (profile.active_group === leaveGroupId) {
				await setActiveGroup(newGroups[0].id)
			}
			await removeUserFromGroup({
				userId: profile.id,
				groupId: leaveGroupId,
			})
		}
		setShowLeaveDialog(false)
	}

	const handleCreateGroup = async (values: GroupFormValues) => {
		if (!profile) throw new Error('No profile found')

		try {
			const newGroup: Group = {
				id: uuidV4(),
				name: values.name,
				activity_name: values.activityName,
				admin: profile.id,
				status: 'active',
				created_at: DateTime.now().toISO(),
				updated_at: null,
				default_timezone: DateTime.local().zoneName,
			}
			await createGroup(newGroup)
			await addUserToGroup({
				userId: profile.id,
				groupId: newGroup.id,
			})
			await setActiveGroup(newGroup.id)
			navigate('/')
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			showSnackbar('Error creating group', 'error')
		}
	}

	const handleCancelCreateGroup = () => {
		setCreatingNewGroup(false)
	}

	const handleSubscribe = () => {
		if (profile) {
			const url = import.meta.env.VITE_STRIPE_PAYMENT_LINK_URL_GROUP

			const paymentUrl = url + '?prefilled_email=' + profile.email
			if (import.meta.env.PROD) {
				window.open(paymentUrl, '_self')
			} else {
				window.open(paymentUrl, '_blank')
			}

			track('subscribe')
		}
	}

	return (
		<Box>
			<AppHeader
				leftComponent={
					<IconButton
						size='large'
						edge='start'
						sx={{ color: 'white' }}
						onClick={
							creatingNewGroup
								? () => setCreatingNewGroup(false)
								: goBack
						}
					>
						<ArrowBackIcon />
					</IconButton>
				}
				title='Groups'
			/>

			{groups.isSuccess ? (
				creatingNewGroup ? (
					<Box margin='1rem'>
						<GroupForm
							onSubmit={handleCreateGroup}
							onCancel={handleCancelCreateGroup}
							defaultValues={{
								name: '',
								activityName: 'Activity',
								defaultTimezone:
									DateTime.local().zoneName || '',
							}}
						/>
					</Box>
				) : (
					<Box sx={{ margin: '10px 20px' }}>
						<List>
							{groups.data.map((group) => {
								const secondaryAction = userIsAdminOfGroup(
									group.id
								) ? (
									<Button
										onClick={() =>
											handleEditButtonClick(group.id)
										}
										size='small'
										variant='outlined'
									>
										Edit
									</Button>
								) : (
									<Button
										onClick={() =>
											handleLeaveButtonClick(group.id)
										}
										size='small'
										variant='outlined'
										color='warning'
									>
										Leave
									</Button>
								)

								return (
									<ListItem
										// disablePadding
										style={{
											paddingRight: '100px',
											borderBottom: '1px solid #cccccc',
										}}
										key={group.id}
										secondaryAction={secondaryAction}
									>
										<ListItemButton
											onClick={() =>
												handleListClick(group.id)
											}
											selected={
												activeGroup?.id === group.id
											}
										>
											<ListItemText>
												{group.name}
											</ListItemText>
										</ListItemButton>
									</ListItem>
								)
							})}

							<ListItemButton onClick={handleNewGroup}>
								<ListItemAvatar>
									<Avatar
										style={{
											height: '40px',
											width: '40px',
											marginLeft: '15px',
											marginRight: '10px',
											marginTop: '10px',
											color: 'white',
											backgroundColor: 'green',
										}}
									>
										<AddIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText style={{ marginTop: '10px' }}>
									Create New Group
								</ListItemText>
							</ListItemButton>
						</List>
					</Box>
				)
			) : (
				<Loading />
			)}

			<ConfirmLeaveDialog
				open={showLeaveDialog}
				onLeave={handleLeaveGroup}
				onClose={() => setShowLeaveDialog(false)}
			/>

			<SubscribeDialog
				open={showSubscribeDialog}
				onCancel={() => setShowSubscribeDialog(false)}
				onSubscribe={handleSubscribe}
			/>
		</Box>
	)
}
