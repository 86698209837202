import React from 'react'
import type { Profile } from 'lib/supabase'
import {
	Button,
	Switch,
	FormGroup,
	FormControlLabel,
	Typography,
} from '@mui/material'
import { formatPhoneNumber } from 'utils/funcs'
import AppHeader from 'components/AppHeader'

type Props = {
	profile: Profile
	onBack: () => void
	onEdit: () => void
}

const ViewProfile: React.FC<Props> = ({ profile, onBack, onEdit }) => {
	return (
		<>
			<AppHeader
				leftComponent={
					<Button sx={{ color: '#0195f5' }} onClick={onBack}>
						Back
					</Button>
				}
				title={'Profile'}
				rightComponent={
					<Button sx={{ color: 'white' }} onClick={onEdit}>
						Edit
					</Button>
				}
			/>
			<div style={{ margin: '40px 0px 0px 40px' }}>
				<div style={{ paddingBottom: '20px' }}>
					<div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
						Name
					</div>
					<div>{profile.full_name}</div>
				</div>
				<div style={{ paddingBottom: '20px' }}>
					<div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
						Email
					</div>
					<div>{profile.email ?? 'No email'}</div>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div style={{ width: '60%' }}>
						<div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
							Mobile Phone
						</div>
						<div>
							{profile.phone_number
								? formatPhoneNumber(profile.phone_number)
								: 'No phone number'}
						</div>
					</div>
					<div>
						<FormGroup>
							<FormControlLabel
								control={
									<Switch
										checked={profile.allow_sms}
										disabled
									/>
								}
								label='Allow SMS'
							/>
						</FormGroup>
					</div>
				</div>
				<Typography
					marginTop={'10px'}
					paddingRight={'40px'}
					fontSize={'0.8rem'}
					color={'#666666'}
				>
					If you turn off SMS, you will still receive Joyner
					notifications via email. You will continue to recieve Joyner
					sign-in codes via SMS, regardless of this setting, for
					security purposes.
				</Typography>

				{/* <div
					style={{
						display: 'flex',
						flexDirection: 'row',
						marginTop: '20px',
					}}
				>
					<div style={{ width: '60%' }}>
						<div style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
							WhatsApp User Name
						</div>
						<div>{profile.full_name}</div>
					</div>
					<div>
						<FormGroup>
							<FormControlLabel
								control={
									<Switch
										checked={profile.allow_sms}
										disabled
									/>
								}
								label='Enabled'
							/>
						</FormGroup>
					</div>
				</div>
				<Typography
					marginTop={'10px'}
					paddingRight={'40px'}
					fontSize={'0.8rem'}
					color={'#666666'}
				>
					If you turn on WhatsApp, notifications will be sent to your
					WhatsApp account and you will still receive Joyner
					notifications via email.
				</Typography> */}
			</div>
		</>
	)
}

export default ViewProfile
