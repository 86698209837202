import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import SignIn from './SignIn'
import CreateAccount from './CreateAccount'

const AccountInfo: React.FC = () => {
	const [createAccount, setCreateAccount] = React.useState(false)

	const handleChangeToSignIn = () => {
		setCreateAccount(false)
	}

	const handleChangeToCreateAccount = () => {
		setCreateAccount(true)
	}
	const handleHelp = () => {
		const hurl =
			'https://airtable.com/embed/appV1wlnS9BGi44bT/pagwjekNetCHODW0V/form' +
			'?prefill_SenderID=unauthenticated' +
			'&hide_SenderID=true'

		window.open(hurl, '_blank')
	}

	if (createAccount) {
		return (
			<Stack direction='column'>
				<CreateAccount style={{ paddingBottom: '1rem' }} />
				<Button onClick={handleChangeToSignIn}>cancel</Button>
			</Stack>
		)
	} else {
		return (
			<Stack direction='column' marginTop={'1.0rem'}>
				<Stack direction='row' spacing={1}>
					<Typography fontWeight={'500'} paddingTop={'0.35rem'}>
						If you are a new user:
					</Typography>
					<Button onClick={handleChangeToCreateAccount}>
						Create an Account
					</Button>
				</Stack>
				<Typography
					fontWeight={'500'}
					paddingTop={'1.0rem'}
					paddingBottom={'0.6rem'}
				>
					If you already have a Joyner account, sign in:
				</Typography>
				<SignIn style={{ paddingBottom: '1rem' }} />
				<Stack direction='row' spacing={1} paddingTop={'1.0rem'}>
					<Typography fontWeight={'500'} paddingTop={'0.35rem'}>
						Having trouble?
					</Typography>
					<Button onClick={handleHelp}>Get help signing in</Button>
				</Stack>
			</Stack>
		)
	}
}

export default AccountInfo
