import React from 'react'
import { Button, IconButton, Stack } from '@mui/material'
import {
	KeyboardDoubleArrowLeft,
	KeyboardDoubleArrowRight,
	EventOutlined,
	CalendarMonthOutlined,
} from '@mui/icons-material'
import { DateTime } from 'luxon'
import { SupportedRanges } from 'api/activity/getInRange'
import Loading from 'components/Loading'

type Props = {
	rangeDate: DateTime
	range?: SupportedRanges
	onGotoPresent: () => void
	onGotoNextRange: () => void
	onGotoPrevRange: () => void
	onRangeChange: (newRange: SupportedRanges) => void
}

const DashboardNav: React.FC<Props> = ({
	rangeDate,
	range,
	onGotoPresent,
	onGotoNextRange,
	onGotoPrevRange,
	onRangeChange,
}) => {
	const handleRangeChange = () => {
		if (range === 'week') {
			onRangeChange('month')
		} else {
			onRangeChange('week')
		}
	}

	return (
		<>
			<div
				id='dashboardNav'
				color='primary'
				style={{
					display: 'flex',
					backgroundColor: '#1565c0',
					fontSize: '1.2em',
					position: 'sticky',
					top: '100px',
					width: '100%',
					padding: '2px 0px 2px 0px',
					zIndex: 1000,
				}}
			>
				{/* Left in as spacer; where calender icon was located */}
				<div style={{ width: '100px' }}>
					<IconButton
						sx={{ color: '#ffffff' }}
						onClick={handleRangeChange}
						data-intro='Tap the calendar icon to switch between the month view and the week view.'
					>
						{range == 'week' ? (
							<EventOutlined />
						) : (
							<CalendarMonthOutlined />
						)}
					</IconButton>
				</div>
				<div
					style={{
						flexGrow: 1,
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						width: '100%',
					}}
					data-intro='Tap here to return to the current week/month.'
				>
					<Button
						variant='text'
						size='large'
						onClick={onGotoPresent}
						sx={{
							color: '#ffffff',
							fontSize: '1.1rem',
							textTransform: 'none',
						}}
					>
						{range === 'week' ? (
							<>Week of {rangeDate.toFormat('LLL d, yyyy')}</>
						) : range === 'month' ? (
							<>{rangeDate.toFormat('LLLL yyyy')}</>
						) : (
							<Loading />
						)}
					</Button>
				</div>
				<div
					style={{ width: '100px' }}
					data-intro='Tap the left arrows to view the previous week/month, tap the right arrows to view the next week/month.'
				>
					<Stack direction='row'>
						<div>
							<IconButton
								onClick={onGotoPrevRange}
								edge='start'
								size='large'
								sx={{ color: 'white' }}
							>
								<KeyboardDoubleArrowLeft />
							</IconButton>
						</div>
						<div>
							<IconButton
								onClick={onGotoNextRange}
								edge='start'
								size='large'
								sx={{ color: 'white' }}
							>
								<KeyboardDoubleArrowRight />
							</IconButton>
						</div>
					</Stack>
				</div>
			</div>
			{/* <Box sx={{ paddingTop: '56px' }}></Box> */}
		</>
	)
}

export default DashboardNav
