import React from 'react'
import {
	Button,
	Stack,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@mui/material'
import { Poll, PollChoice, Profile } from 'lib/supabase'
import { useGetProfiles } from 'api/profile'

type Props = {
	isOpen: boolean
	onClose: () => void
	onReset: () => void
	poll: Poll | undefined
	choices: PollChoice[] | undefined
}

const PollResultsDialog: React.FC<Props> = ({
	isOpen,
	onClose,
	onReset,
	poll,
	choices,
}) => {
	const getProfileNames = (votes: string[]) => {
		const voters = useGetProfiles({
			profileIds: votes,
			options: { enabled: !!votes },
		})

		return (
			<div style={{ marginBottom: '10px' }}>
				{voters.map((voter) => (
					<Typography
						key={voter.data?.id}
						style={{ paddingLeft: '10px' }}
					>
						{voter.data?.full_name}
					</Typography>
				))}
			</div>
		)
	}

	const getVoteCount = (votes: number) => {
		return votes === 1 ? '1 vote' : votes + ' votes'
	}

	return (
		<Dialog fullWidth open={isOpen} onClose={onClose}>
			<DialogTitle>Poll Results</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{choices?.map((choice, index) => (
						<div key={index}>
							<Stack direction={'row'} spacing={1}>
								<Typography fontWeight={'bold'}>
									{choice.choice}
								</Typography>
								<Typography fontStyle={'italic'}>
									{choice.choice != '' &&
										getVoteCount(choice.votes.length)}
								</Typography>
							</Stack>
							<Typography>
								{choice.choice != '' &&
									getProfileNames(choice.votes)}
							</Typography>
						</div>
					))}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant='outlined'
					color='warning'
					onClick={() => onReset()}
				>
					Reset Poll
				</Button>
				<Button variant='contained' onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default PollResultsDialog
