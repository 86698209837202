import React from 'react'
import { UserContextProvider } from './UserContext'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from '../lib/reactQuery'
import { HelmetProvider } from 'react-helmet-async'
import { AuthProvider } from './AuthContext'
import { SnackbarContextProvider } from './SnackbarContext'
import { DashboardInfoProvider } from './DashboardInfoContext'
import { GroupsContextProvider } from './GroupsContext'

export const Providers: React.FC<React.PropsWithChildren> = (props) => {
	return (
		<QueryClientProvider client={queryClient}>
			<HelmetProvider>
				<SnackbarContextProvider>
					<AuthProvider>
						<UserContextProvider>
							<GroupsContextProvider>
								<DashboardInfoProvider>
									{props.children}
								</DashboardInfoProvider>
							</GroupsContextProvider>
						</UserContextProvider>
					</AuthProvider>
				</SnackbarContextProvider>
			</HelmetProvider>
		</QueryClientProvider>
	)
}
