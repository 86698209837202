import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material'
import React from 'react'

type Props = {
	open: boolean
	onLeave: () => void
	onClose: () => void
}
const ConfirmLeaveDialog: React.FC<Props> = ({ open, onLeave, onClose }) => {
	return (
		<Dialog
			fullWidth
			open={open}
			onClose={onClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>{'Confirm Leave'}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					Do you want to leave this group?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button
					onClick={onLeave}
					variant='contained'
					color='error'
					autoFocus
				>
					Leave Group
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmLeaveDialog
