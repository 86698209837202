import React, { createContext, useEffect, useState } from 'react'
import supabase from 'lib/supabase'
import { formatPhoneNumber } from 'utils'
import { Session, User } from '@supabase/supabase-js'

export type Providers = 'apple' | 'google'

const providerScopes: Record<Providers, string> = {
	apple: 'email',
	google: 'email profile',
}

export type NewUserInfo = {
	email: string
	groupId?: string
}

// prettier does something fucked here TODO fix it
/* eslint-disable no-mixed-spaces-and-tabs */
type SupabaseOTPData =
	| {
			user: User | null
			session: Session | null
	  }
	| {
			user: null
			session: null
	  }
/* eslint-enable no-mixed-spaces-and-tabs */
type AuthContextProps = {
	session: Session | null
	isAuthenticated: boolean | null
	signInWithEmail: (email: string) => Promise<void>
	signInWithProvider: (provider: Providers) => Promise<void>
	signOut: () => Promise<void>
	sendOTP: (phoneNumber: string) => Promise<string>
	verifyOTP: (phoneNumber: string, code: string) => Promise<SupabaseOTPData>
	signUpWithPhone: (
		phoneNumber: string,
		password: string,
		fullName: string,
		newUserInfo: NewUserInfo
	) => Promise<User | null>
	getNewUserInfo: () => NewUserInfo | undefined
}

const AuthContext = createContext<AuthContextProps>({
	session: null,
	isAuthenticated: false,
	signInWithEmail: () => Promise.reject(),
	signInWithProvider: () => Promise.reject(),
	signOut: () => Promise.reject(),
	sendOTP: () => Promise.reject(),
	verifyOTP: () => Promise.reject(),
	signUpWithPhone: () => Promise.reject(),
	getNewUserInfo: () => undefined,
})

export const useAuthContext = () => React.useContext(AuthContext)

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const [session, setSession] = useState<Session | null>(null)
	const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)
	const [newUserInfo, setNewUserInfo] = useState<NewUserInfo>()

	const updateSession = (session: Session) => {
		setSession(session)
		setIsAuthenticated(true)
	}

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session }, error }) => {
			if (error) throw error
			if (session) {
				updateSession(session)
			} else {
				setSession(null)
				setIsAuthenticated(false)
			}
		})
	}, [])

	useEffect(() => {
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((event, session) => {
			if (event === 'SIGNED_IN') {
				if (session) updateSession(session)
			} else if (event === 'SIGNED_OUT') {
				setSession(null)
				setIsAuthenticated(false)
			}
		})

		return () => {
			subscription.unsubscribe()
		}
	}, [])

	const handleSignInWithEmail = async (email: string) => {
		throw new Error(`not implemented for ${email}`)
	}

	const handleSignInWithProvider = async (provider: Providers) => {
		const { error } = await supabase.auth.signInWithOAuth({
			provider: provider,
			options: {
				redirectTo: window.location.origin,
				queryParams: {
					access_type: 'offline',
					prompt: 'consent',
					scope: providerScopes[provider],
				},
			},
		})
		if (error) throw error
	}

	const handleSendOTP = async (phoneNumber: string): Promise<string> => {
		const formattedPhoneNumber = formatPhoneNumber(phoneNumber, true)
		console.log('formattedPhoneNumber: ', formattedPhoneNumber)

		const { data, error } = await supabase.auth.signInWithOtp({
			phone: formattedPhoneNumber,
			options: {
				shouldCreateUser: false,
			},
		})
		console.log('OTP error: ', JSON.stringify(error))
		if (error) throw error
		console.log('OTP data: ', data)

		// if (data.messageId == null) throw new Error('No message ID')

		return data.messageId || ''
	}

	const handleVerifyOTP = async (
		phoneNumber: string,
		code: string
	): Promise<SupabaseOTPData> => {
		const formattedPhoneNumber = formatPhoneNumber(phoneNumber, true)
		const { data, error } = await supabase.auth.verifyOtp({
			phone: formattedPhoneNumber,
			token: code,
			type: 'sms',
		})

		if (error) throw error
		setIsAuthenticated(true)

		return data
	}

	const handleSignOut = async () => {
		const { error } = await supabase.auth.signOut()
		setIsAuthenticated(false)
		if (error) throw error
	}

	const handleSignUpWithPhone = async (
		phoneNumber: string,
		password: string,
		fullName: string,
		newUserInfo: NewUserInfo
	) => {
		const { data, error } = await supabase.auth.signUp({
			phone: formatPhoneNumber(phoneNumber, true),
			password,
			options: {
				data: {
					full_name: fullName,
				},
			},
		})

		setNewUserInfo(newUserInfo)

		if (error != null) throw error

		return data.user
	}

	return (
		<AuthContext.Provider
			value={{
				session,
				isAuthenticated,
				signInWithEmail: handleSignInWithEmail,
				signInWithProvider: handleSignInWithProvider,
				signOut: handleSignOut,
				sendOTP: handleSendOTP,
				verifyOTP: handleVerifyOTP,
				signUpWithPhone: handleSignUpWithPhone,
				getNewUserInfo: () => newUserInfo,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
