import React from 'react'
import { FlowSteps } from './messageFlowMachine'
import { MachineSnapshot } from 'xstate'

type Props = {
	states: FlowSteps[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	current: MachineSnapshot<any, any, any, any, any, unknown> // we just need the `.matches` method
}
const Match: React.FC<React.PropsWithChildren<Props>> = ({
	children,
	states,
	current,
}) => {
	return states.some((value) => current.matches(value)) ? (
		<>{children}</>
	) : null
}

export default Match
