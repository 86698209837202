import queryClient from '../../lib/reactQuery'
import supabase, { TABLE_GROUPS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import { getQueryKey } from './get'

import type { MutationConfig } from '../../lib/reactQuery'
import type { Group } from '../../lib/supabase'

type DeleteGroupArgs = {
	groupId: string
}
const deleteGroupFunc = async ({ groupId }: DeleteGroupArgs) => {
	// we're soft deleting the groups
	// i.e. setting the status to deleted
	const { error } = await supabase
		.from(TABLE_GROUPS)
		.update({
			status: 'deleted',
		})
		.eq('id', groupId)

	if (error) throw error
}

export const deleteGroup = async ({ groupId }: DeleteGroupArgs) => {
	await deleteGroupFunc({ groupId })

	queryClient.invalidateQueries({ queryKey: getQueryKey(groupId) })
}

type UseDeleteGroupOptions = {
	options?: MutationConfig<typeof deleteGroup>
}
export const useDeleteGroup = ({ options }: UseDeleteGroupOptions = {}) => {
	return useMutation({
		onMutate: async ({ groupId }) => {
			// optimistically update the cache
			await queryClient.cancelQueries({ queryKey: getQueryKey(groupId) })
			const previousGroup = queryClient.getQueryData<Group>(
				getQueryKey(groupId)
			)

			return { previousGroup }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (_, __, context: any) => {
			// revert the cache back to the previous value
			if (context?.previousGroup) {
				queryClient.setQueryData(
					['group', context.previousGroup.id],
					context.previousGroup
				)
			}
		},
		onSuccess: (_, args) => {
			queryClient.invalidateQueries({
				queryKey: getQueryKey(args.groupId),
			})
		},
		...options,
		mutationFn: deleteGroup,
	})
}
