import React, { useState } from 'react'
import { AppBar, Button } from '@mui/material'
import type { Group, Poll, PollChoice } from 'lib/supabase'
import { DateTime } from 'luxon'
import { v4 as uuidV4 } from 'uuid'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useNavigate } from 'react-router'
import PollForm, {
	type PollFormValues,
} from 'components/forms/prebuilt/PollForm'
import { DEFAULT_ERROR_MESSAGE } from 'utils'
import BinaryDialog from 'components/dialogs/BinaryDialog'

type Props = {
	profileId: string
	activeGroup: Group
	onCancel: () => void
	onNewPoll: (
		newPoll: Poll,
		newChoices: PollChoice[],
		sendEmail: boolean
	) => Promise<Error | void>
}

const NewPoll: React.FC<Props> = ({
	profileId,
	activeGroup,
	onCancel,
	onNewPoll,
}) => {
	const [showDialog, setShowDialog] = useState(false)
	const [newPoll, setNewPoll] = useState<Poll | null>(null)
	const [newChoices, setNewChoices] = useState<PollChoice[] | null>(null)
	const navigate = useNavigate()
	const { showSnackbar } = useSnackbarContext()

	const handleSubmit = async (data: PollFormValues) => {
		const pollId = uuidV4()
		const newPoll: Poll = {
			id: pollId,
			group_id: activeGroup!.id,
			title: data.title,
			message: data.message || null,
			choose_multiple:
				data?.choose_multiple !== undefined
					? data.choose_multiple
					: true,
			status: 'active',
			created_at: DateTime.now().toISO(),
			poll_owner_id: profileId,
		}
		const newChoices = [] as PollChoice[]
		if (data.choice_1 !== undefined && data.choice_1 !== '') {
			newChoices.push({
				id: uuidV4(),
				poll_id: pollId,
				created_at: DateTime.now().toISO(),
				votes: [],
				choice: data.choice_1,
				sort_order: 0,
			})
		} else {
			newChoices.push({
				id: '',
				poll_id: '',
				created_at: '',
				votes: [],
				choice: '',
				sort_order: null,
			})
		}
		if (data.choice_2 !== undefined && data.choice_2 !== '') {
			newChoices.push({
				id: uuidV4(),
				poll_id: pollId,
				created_at: DateTime.now().toISO(),
				votes: [],
				choice: data.choice_2,
				sort_order: 1,
			})
		} else {
			newChoices.push({
				id: '',
				poll_id: '',
				created_at: '',
				votes: [],
				choice: '',
				sort_order: null,
			})
		}
		if (data.choice_3 !== undefined && data.choice_3 !== '') {
			newChoices.push({
				id: uuidV4(),
				poll_id: pollId,
				created_at: DateTime.now().toISO(),
				votes: [],
				choice: data.choice_3,
				sort_order: 2,
			})
		} else {
			newChoices.push({
				id: '',
				poll_id: '',
				created_at: '',
				votes: [],
				choice: '',
				sort_order: null,
			})
		}
		if (data.choice_4 !== undefined && data.choice_4 !== '') {
			newChoices.push({
				id: uuidV4(),
				poll_id: pollId,
				created_at: DateTime.now().toISO(),
				votes: [],
				choice: data.choice_4,
				sort_order: 3,
			})
		} else {
			newChoices.push({
				id: '',
				poll_id: '',
				created_at: '',
				votes: [],
				choice: '',
				sort_order: null,
			})
		}
		if (data.choice_5 !== undefined && data.choice_5 !== '') {
			newChoices.push({
				id: uuidV4(),
				poll_id: pollId,
				created_at: DateTime.now().toISO(),
				votes: [],
				choice: data.choice_5,
				sort_order: 4,
			})
		} else {
			newChoices.push({
				id: '',
				poll_id: '',
				created_at: '',
				votes: [],
				choice: '',
				sort_order: null,
			})
		}
		if (data.choice_6 !== undefined && data.choice_6 !== '') {
			newChoices.push({
				id: uuidV4(),
				poll_id: pollId,
				created_at: DateTime.now().toISO(),
				votes: [],
				choice: data.choice_6,
				sort_order: 5,
			})
		} else {
			newChoices.push({
				id: '',
				poll_id: '',
				created_at: '',
				votes: [],
				choice: '',
				sort_order: null,
			})
		}

		setNewPoll(newPoll)
		setNewChoices(newChoices)
		setShowDialog(true)
	}

	const handleAddNewPoll = async (sendEmail: boolean) => {
		if (newPoll === null || newChoices === null) return
		const error = await onNewPoll(newPoll, newChoices, sendEmail)
		if (error) {
			console.error(error)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
			return
		}

		navigate(`/poll/${newPoll?.id}`)
	}

	return (
		<div>
			<PollForm
				id='new-poll-form'
				onSubmit={handleSubmit}
				defaultValues={{
					title: '',
					message: '',
					choose_multiple: true,
					choice_1: '',
					choice_2: '',
					choice_3: '',
					choice_4: '',
					choice_5: '',
					choice_6: '',
				}}
			/>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '1.0rem',
						justifyContent: 'end',
						margin: '10px 20px',
					}}
				>
					<Button onClick={onCancel}>Cancel</Button>
					<Button
						variant='contained'
						type='submit'
						form='new-poll-form'
					>
						Create Poll
					</Button>
				</div>
			</AppBar>

			<BinaryDialog
				isOpen={showDialog}
				onAnswer={(answer: boolean) => handleAddNewPoll(answer)}
				title={'Send Group Notification?'}
				content={
					'Do you want Joyner to automatically send an email notification to all group members about this new poll?'
				}
				yesButtonText='Send Email'
				noButtonText='Do Not Send Email'
			/>
		</div>
	)
}

export default NewPoll
