import React from 'react'
import { useCreateActivity } from 'api/activity'
import { useCreatePoll, useCreateChoice } from 'api/polls'
import { useUserContext } from 'contexts/UserContext'
import { useNavigate, useOutletContext } from 'react-router'
import AdminActionsList from './AdminActionsList'
import { Activity, Venue, Poll, PollChoice } from 'lib/supabase'
import Loading from 'components/Loading'
import NewActivity from '../../Activity/NewActivity'
import NewPoll from '../../Polls/NewPoll'
import { MainOutletContext } from '../MainLayout'
import { useCreateVenue, useGetManyByGroupId } from 'api/venue'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { sendNotification } from '../../../vercel/apicalls'

const Admin: React.FC = () => {
	const { profile, activeGroup } = useUserContext()
	const navigate = useNavigate()
	const createActivity = useCreateActivity()
	const createVenue = useCreateVenue()
	const createPoll = useCreatePoll()
	const createChoice = useCreateChoice()
	const { showSnackbar } = useSnackbarContext()
	const [creatingActivity, setCreatingActivity] = React.useState(false)
	const [creatingPoll, setCreatingPoll] = React.useState(false)
	const { setShowBottomNav } = useOutletContext() as MainOutletContext

	const venues = useGetManyByGroupId({
		groupId: activeGroup?.id,
		options: { enabled: !!activeGroup },
	})

	if (!activeGroup || !profile) return <Loading />
	if (venues.isLoading) return <Loading />

	const handleCreateActivity = () => {
		setCreatingActivity(true)
		setShowBottomNav(false)
	}

	const handleNavigateToGroup = () => navigate(`/group/${activeGroup?.id}`)

	const handleNavigateToVenues = () => navigate('/venues')

	const handleNavigateToNewCal = () => navigate('/newcal')

	const handleNewActivity = async (
		activity: Activity,
		sendGroupNotification: boolean
	): Promise<Error | void> => {
		try {
			await createActivity.mutateAsync({ activity }).then(() => {
				if (sendGroupNotification) {
					let furl = ''
					furl =
						import.meta.env.VITE_VERCELAPI_HOST +
						'email/activity-new'
					furl += '?gid=' + activeGroup?.id
					furl += '&aid=' + activity.id
					sendNotification(furl)

					if (profile.allow_sms) {
						furl =
							import.meta.env.VITE_VERCELAPI_HOST +
							'twilio/activity-new'
						furl += '?gid=' + activeGroup?.id
						furl += '&aid=' + activity.id
						sendNotification(furl)
					}
				}
			})
		} catch (err) {
			return err as Error
		}
		// TODO probably wanna navigate to the new activity page
		// but that requires waiting for the activity to be created on the backend
	}

	const handleCreatePoll = () => {
		setCreatingPoll(true)
		setShowBottomNav(false)
	}

	const handleNewPoll = async (
		poll: Poll,
		choices: PollChoice[],
		sendNotificationEmail: boolean
	): Promise<Error | void> => {
		try {
			// create poll
			await createPoll.mutateAsync({ poll })

			// create choices
			await Promise.all(
				choices.map(async (choice) => {
					if (choice.id !== '' && choice.choice !== null && poll.id) {
						await createChoice.mutateAsync({
							choice: choice,
						})
					}
				})
			)

			// send email
			if (sendNotificationEmail && poll.title) {
				let furl =
					import.meta.env.VITE_VERCELAPI_HOST + 'email/poll-new'
				furl += '?gid=' + activeGroup?.id
				furl += '&pname=' + encodeURIComponent(poll.title)
				sendNotification(furl)
			}
		} catch (err) {
			return err as Error
		}
	}

	const handleCreateVenue = async (venue: Venue): Promise<Error | void> => {
		try {
			await createVenue.mutateAsync({ venue })
		} catch (err) {
			return err as Error
		}
	}

	const handleCancel = () => {
		creatingActivity && setCreatingActivity(false)
		creatingPoll && setCreatingPoll(false)
		setShowBottomNav(true)
	}

	return (
		<>
			{creatingActivity && (
				<NewActivity
					profileId={profile.id}
					venues={venues.data || []}
					activeGroup={activeGroup}
					onCancel={handleCancel}
					onNewActivity={handleNewActivity}
					onCreateVenue={handleCreateVenue}
				/>
			)}
			{creatingPoll && (
				<NewPoll
					profileId={profile.id}
					activeGroup={activeGroup}
					onCancel={handleCancel}
					onNewPoll={handleNewPoll}
				/>
			)}
			{!creatingActivity && !creatingPoll && (
				<AdminActionsList
					onNavigateToCreateActivity={handleCreateActivity}
					onNavigateToCreatePoll={handleCreatePoll}
					onNavigateToGroup={handleNavigateToGroup}
					onNavigateToVenues={handleNavigateToVenues}
					onNavigateToNewCal={handleNavigateToNewCal}
				/>
			)}
		</>
	)
}

export default Admin
