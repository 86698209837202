import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
	Button,
	Box,
	IconButton,
	Stack,
	Typography,
	Skeleton,
} from '@mui/material'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import AppHeader from '../components/AppHeader'
import { useUserContext } from '../contexts/UserContext'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import pbstripe from '../images/stripe-black.svg'
import { track } from '@vercel/analytics'
import { DateTime } from 'luxon'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import {
	SubscriptionInfo,
	StripeData,
	getSubscriptionInfo,
} from 'vercel/stripe-utils'

export default function Account() {
	const { profile } = useUserContext()
	const navigate = useNavigate()
	const [paymentUrlLoading, setPaymentUrlLoading] = useState(false)
	const [stripeAccountLoading, setStripeAccountLoading] = useState(true)
	const [subscription, setSubscription] = useState<SubscriptionInfo | null>(
		null
	)

	const goBack = () => {
		navigate(-1)
	}

	const { showSnackbar } = useSnackbarContext()

	const handleGeneratePaymentLink = async () => {
		if (!profile) return

		setPaymentUrlLoading(true)
		let url = ''
		if (profile?.subscription_status === 'inactive') {
			url = import.meta.env.VITE_STRIPE_PAYMENT_LINK_URL
		}
		if (profile?.subscription_status === 'expired') {
			url = import.meta.env.VITE_STRIPE_PAYMENT_LINK_POSTTRIAL
		}

		const paymentUrl = url + '?prefilled_email=' + profile.email
		if (import.meta.env.PROD) {
			window.open(paymentUrl, '_self')
		} else {
			window.open(paymentUrl, '_blank')
		}

		track('subscribe')
	}

	const handleGetBillingLink = async () => {
		const billingURL = import.meta.env.VITE_STRIPE_BILLING_URL
		let stripePortalURL = billingURL
		if (profile?.email)
			stripePortalURL += '?prefilled_email=' + profile?.email
		window.open(stripePortalURL, '_blank')
	}

	const getStatusColor = () => {
		if (profile?.subscription_status === 'active') return 'green'
		if (profile?.subscription_status === 'expired') return 'red'
		return 'grey'
	}

	useEffect(() => {
		async function getData() {
			if (profile && profile.stripe_customer_id) {
				const data: StripeData = await getSubscriptionInfo(
					profile.stripe_customer_id
				)
				const sub = data.subscription as SubscriptionInfo
				if (sub) {
					setSubscription(sub)
					setStripeAccountLoading(false)
				} else {
					showSnackbar('Unable to load subscription data', 'error')
				}
			}
		}
		if (
			profile?.subscription_status === 'active' ||
			profile?.subscription_status === 'expired'
		) {
			if (profile.stripe_customer_id) getData()
		} else {
			setStripeAccountLoading(false)
		}
	}, [profile])

	const getTrialSubscriptionDetailsBlock = () => {
		return (
			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: '40px',
						marginBottom: '10px',
					}}
				>
					<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
						Hope you are enjoying your FREE trial!
					</Typography>
					<Typography paddingTop={'0.4rem'}>
						You can upgrade your subscription at any time.
					</Typography>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '20px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Subscription Status:
					</Typography>
					<Typography textTransform={'capitalize'}>
						<CircleIcon
							style={{
								fontSize: '0.8rem',
								color: getStatusColor(),
							}}
						/>
						&nbsp;{profile?.subscription_status}
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Subscription Type:
					</Typography>
					<Typography>FREE TRIAL</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Trial Start:
					</Typography>

					<Typography>
						{subscription &&
							DateTime.fromSeconds(
								subscription.periodstart
							).toFormat('MMM d, yyyy')}
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Trial End:
					</Typography>

					<Typography>
						{subscription &&
							DateTime.fromSeconds(
								subscription.trialend
							).toFormat('MMM d, yyyy')}
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Auto-Subscribe:
					</Typography>

					<Typography>OFF</Typography>
				</div>
			</div>
		)
	}

	const getTrialManageAccountBlock = () => {
		return (
			<div>
				<div style={{ textAlign: 'center', marginTop: '40px' }}>
					<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
						Subscribe to Joyner for just $24/year
					</Typography>
					<Typography paddingTop={'0.4rem'}>
						Billed $6 every three months
					</Typography>
				</div>
				<Typography paddingTop={'1.0rem'} fontWeight={'bold'}>
					Your subscription includes:
				</Typography>
				<List style={{ paddingTop: '5px' }}>
					<ListItem disablePadding>
						<ListItemIcon
							style={{
								color: 'green',
								justifyContent: 'center',
							}}
						>
							<CheckCircleIcon />
						</ListItemIcon>
						<ListItemText primary='Unlimited Groups' secondary='' />
					</ListItem>
					<ListItem disablePadding>
						<ListItemIcon
							style={{
								color: 'green',
								justifyContent: 'center',
							}}
						>
							<CheckCircleIcon />
						</ListItemIcon>
						<ListItemText
							primary='Unlimited Group Activities'
							secondary=''
						/>
					</ListItem>
					<ListItem disablePadding>
						<ListItemIcon
							style={{
								color: 'green',
								justifyContent: 'center',
							}}
						>
							<CheckCircleIcon />
						</ListItemIcon>
						<ListItemText
							primary='Unlimited Group Members'
							secondary='Group members use Joyner for FREE'
						/>
					</ListItem>
				</List>

				<div style={{ marginTop: '20px', textAlign: 'center' }}>
					<Button
						variant='contained'
						onClick={handleGetBillingLink}
						color='success'
					>
						Subscribe Now
					</Button>
				</div>
			</div>
		)
	}

	const getPaidSubscriptionDetailsBlock = () => {
		return (
			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: '40px',
						marginBottom: '10px',
					}}
				>
					<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
						Thanks for being a Joyner subscriber
					</Typography>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Subscription Type:
					</Typography>
					<Typography>
						<CircleIcon
							style={{
								fontSize: '0.8rem',
								color: getStatusColor(),
							}}
						/>
						&nbsp;PAID
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Subscription Start:
					</Typography>
					<Typography>
						{subscription &&
							DateTime.fromSeconds(
								subscription.periodstart
							).toFormat('MMM d, yyyy')}
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Subscription End:
					</Typography>
					<Typography>
						{subscription &&
							DateTime.fromSeconds(
								subscription.periodend
							).toFormat('MMM d, yyyy')}
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Auto-Renew:
					</Typography>
					<Typography>
						{subscription && subscription.autorenew ? 'ON' : 'OFF'}
					</Typography>
				</div>
			</div>
		)
	}

	const getPaidManageAccountBlock = () => {
		return (
			<div>
				<Typography
					paddingTop={'2.4rem'}
					fontWeight={'500'}
					fontSize={'1.0rem'}
				>
					Subscriptions are managed in Stripe:
				</Typography>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<ul
						style={{
							textAlign: 'left',
							margin: '0px',
							fontSize: '0.9rem',
						}}
					>
						<li style={{ paddingTop: '0.2rem' }}>
							Cancel your subscription
						</li>
						<li style={{ paddingTop: '0.2rem' }}>
							Update your payment method
						</li>
						<li style={{ paddingTop: '0.2rem' }}>
							View your payment history
						</li>
					</ul>
				</div>
				<div style={{ marginTop: '20px', textAlign: 'center' }}>
					<Button
						variant='contained'
						onClick={handleGetBillingLink}
						color='primary'
					>
						Manage My Subscription
					</Button>
				</div>
			</div>
		)
	}

	const getUnsubscribedBlock = () => {
		return (
			<div>
				{/* NO SUBSCRIPTION CTA */}

				<div style={{ textAlign: 'center', marginTop: '40px' }}>
					<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
						Subscribe to Joyner for just $24/year
					</Typography>
					<Typography paddingTop={'0.4rem'}>
						Subscription includes 90 day FREE trial
					</Typography>
					<Typography paddingTop={'0.4rem'}>
						Billed $6 every three months
					</Typography>
				</div>

				<div style={{ marginTop: '20px' }}>
					<div style={{ marginLeft: '30px' }}>
						<Typography paddingTop={'1.0rem'} fontWeight={'bold'}>
							Your subscription includes:
						</Typography>
						<List style={{ paddingTop: '5px' }}>
							<ListItem disablePadding>
								<ListItemIcon
									style={{
										color: 'green',
										justifyContent: 'center',
									}}
								>
									<CheckCircleIcon />
								</ListItemIcon>
								<ListItemText
									primary='90 day FREE trial'
									secondary=''
								/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemIcon
									style={{
										color: 'green',
										justifyContent: 'center',
									}}
								>
									<CheckCircleIcon />
								</ListItemIcon>
								<ListItemText
									primary='Unlimited Groups'
									secondary=''
								/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemIcon
									style={{
										color: 'green',
										justifyContent: 'center',
									}}
								>
									<CheckCircleIcon />
								</ListItemIcon>
								<ListItemText
									primary='Unlimited Group Activities'
									secondary=''
								/>
							</ListItem>
							<ListItem disablePadding>
								<ListItemIcon
									style={{
										color: 'green',
										justifyContent: 'center',
									}}
								>
									<CheckCircleIcon />
								</ListItemIcon>
								<ListItemText
									primary='Unlimited Group Members'
									secondary='Group members use Joyner for FREE'
								/>
							</ListItem>
						</List>
						<div style={{ marginTop: '20px' }}>
							<Stack direction='row' spacing={1}>
								<Typography
									fontWeight={'bold'}
									fontSize={'3.0rem'}
								>
									$24
								</Typography>
								<div style={{ paddingTop: '10px' }}>
									<Stack direction='column' spacing={0}>
										<Typography>per</Typography>
										<Typography>year</Typography>
									</Stack>
								</div>
								<div
									style={{
										marginLeft: '40px',
										marginTop: '15px',
									}}
								>
									<Button
										disabled={paymentUrlLoading}
										variant='contained'
										color='success'
										onClick={handleGeneratePaymentLink}
									>
										{paymentUrlLoading
											? 'Loading...'
											: 'Subscribe Now'}
									</Button>
								</div>
							</Stack>
						</div>
						<Typography fontSize={'0.8rem'} fontStyle={'italic'}>
							Billing and subscriptions are managed in Stripe
						</Typography>
					</div>
				</div>
			</div>
		)
	}

	const getExpiredSubscriptionDetailsBlock = () => {
		return (
			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: '40px',
						marginBottom: '10px',
					}}
				>
					<Typography fontSize={'1.2rem'} fontWeight={'bold'}>
						Your subscription has expired
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Subscription Type:
					</Typography>
					<Typography>
						<CircleIcon
							style={{
								fontSize: '0.8rem',
								color: getStatusColor(),
							}}
						/>
						&nbsp;EXPIRED
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Subscription Start:
					</Typography>
					<Typography>
						{subscription &&
							DateTime.fromSeconds(
								subscription.periodstart
							).toFormat('MMM d, yyyy')}
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
						width: '100%',
						textAlign: 'left',
						margin: '5px 0px 5px 0px',
					}}
				>
					<Typography width={'210px'} fontWeight={'500'}>
						Subscription End:
					</Typography>
					<Typography>
						{subscription &&
							DateTime.fromSeconds(
								subscription.periodstart
							).toFormat('MMM d, yyyy')}
					</Typography>
				</div>
			</div>
		)
	}

	const getExpiredManageAccountBlock = () => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
				}}
			>
				<div style={{ marginTop: '40px' }}>
					<Typography fontWeight={500} fontSize={'1.2rem'}>
						We&apos;d love to have you back!
					</Typography>
					<Typography paddingTop={'10px'}>
						To re-subscribe, simply click the button.
					</Typography>
				</div>
				<div
					style={{
						marginTop: '20px',
					}}
				>
					<Button
						disabled={paymentUrlLoading}
						variant='contained'
						color='success'
						onClick={handleGeneratePaymentLink}
					>
						{paymentUrlLoading ? 'Loading...' : 'Subscribe Now'}
					</Button>
				</div>
			</div>
		)
	}

	const getLoadingSubscriptionBlock = () => {
		return (
			<div
				style={{
					width: '100%',
					textAlign: 'left',
					margin: '40px 20px 5px 20px',
				}}
			>
				<Typography fontStyle={'italic'} fontWeight={500}>
					Loading Subscription Info...
				</Typography>
				<Stack direction='row' spacing={2}>
					<Skeleton animation='wave' height={33} width={'67%'} />
					<Skeleton animation='wave' height={33} width={'33%'} />
				</Stack>
				<Stack direction='row' spacing={2}>
					<Skeleton animation='wave' height={33} width={'67%'} />
					<Skeleton animation='wave' height={33} width={'33%'} />
				</Stack>
				<Stack direction='row' spacing={2}>
					<Skeleton animation='wave' height={33} width={'67%'} />
					<Skeleton animation='wave' height={33} width={'33%'} />
				</Stack>
			</div>
		)
	}

	const getAboutStripeBlock = () => {
		return (
			<div
				style={{
					margin: '10px 40px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					maxWidth: '500px',
				}}
			>
				<div>
					<Typography
						fontSize={'0.7rem'}
						color={'#777777'}
						marginTop={'2.0rem'}
						paddingY={'0.8rem'}
						borderTop={'1px solid #cccccc'}
					>
						Stripe is a technology company that builds economic
						infrastructure for the internet. Businesses of every
						size—from new startups to public companies—use our
						software to accept payments and manage their businesses
						online.
					</Typography>
				</div>
				<div>
					<a
						href='https://www.stripe.com'
						target='_blank'
						rel='noreferrer'
					>
						<img src={pbstripe} style={{ width: '100px' }}></img>
					</a>
				</div>
			</div>
		)
	}

	return (
		<Box>
			<AppHeader
				leftComponent={
					<div style={{ textAlign: 'center', width: '50px' }}>
						<IconButton
							size='large'
							edge='start'
							sx={{ color: 'white' }}
							onClick={goBack}
						>
							<ArrowBackIcon />
						</IconButton>
					</div>
				}
				title='Account'
			/>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					margin: '0px 40px',
				}}
			>
				{/* UNSUBSCRIBED NEW USER */}
				{profile?.subscription_status === 'inactive' && (
					<>{getUnsubscribedBlock()}</>
				)}

				{/* SKELETON WHILE LOADING */}
				{stripeAccountLoading && <>{getLoadingSubscriptionBlock()}</>}

				{/* SUBSCRIBED USER w/ FREE TRIAL */}
				{subscription &&
					profile?.subscription_status === 'active' &&
					subscription.trialing && (
						<>
							{getTrialSubscriptionDetailsBlock()}
							{getTrialManageAccountBlock()}
						</>
					)}

				{/* SUBSCRIBED USER w/ PAID ACCOUNT */}
				{subscription &&
					profile?.subscription_status === 'active' &&
					!subscription.trialing && (
						<>
							{getPaidSubscriptionDetailsBlock()}
							{getPaidManageAccountBlock()}
						</>
					)}

				{/* USER w/ EXPIRED SUBSCRIPTION */}
				{subscription && profile?.subscription_status === 'expired' && (
					<>
						{getExpiredSubscriptionDetailsBlock()}
						{getExpiredManageAccountBlock()}
					</>
				)}

				{/* ABOUT STRIPE */}
				{getAboutStripeBlock()}
			</div>
		</Box>
	)
}
