import React, { useEffect } from 'react'
import { Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router'
import { useUserContext } from 'contexts/UserContext'
import ActionComplete from 'components/ActionComplete'

type Props = {
	setNextEnabled: (val: boolean) => void
}
const StartFreeTrial: React.FC<Props> = ({ setNextEnabled }) => {
	const { profile } = useUserContext()
	const navigate = useNavigate()
	const [hasActiveSubscription, setHasActiveSubscription] = React.useState(
		() => {
			if (profile?.subscription_status === 'active') {
				return true
			}
			return false
		}
	)

	const handleStartFreeTrial = () => {
		navigate('/account')
	}

	useEffect(() => {
		if (profile?.subscription_status === 'active') {
			setHasActiveSubscription(true)
		} else {
			setHasActiveSubscription(false)
		}
	}, [profile?.subscription_status])

	useEffect(() => {
		setNextEnabled(hasActiveSubscription)
	}, [hasActiveSubscription])

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignContent: 'center',
				alignItems: 'center',
				padding: '20px 40px',
			}}
		>
			<Typography
				fontWeight={'bold'}
				paddingBottom={2}
				fontSize={'1.2rem'}
			>
				Start FREE Trial
			</Typography>
			<Typography paddingBottom={2}>
				To get going with Joyner you will need to start your 90 day{' '}
				<strong>FREE</strong> trial.
			</Typography>
			<Typography paddingBottom={2}>
				During the <strong>FREE</strong> trial, you will have access to
				all the features of Joyner. Any friends that you invite to your
				group(s) use Joyner for <strong>FREE</strong>. They only have to
				subscribe to Joyner if they want to create their own groups.
			</Typography>
			<Typography paddingBottom={3}>
				A credit card is not required to start the <strong>FREE</strong>{' '}
				trial, there is no obligation to subscribe, and you can cancel
				at any time.
			</Typography>
			{hasActiveSubscription ? (
				<ActionComplete message='Successfully started trial!' />
			) : (
				<Button
					variant='contained'
					color='success'
					onClick={handleStartFreeTrial}
					disabled={hasActiveSubscription}
				>
					Start Free Trial
				</Button>
			)}
		</div>
	)
}

export default StartFreeTrial
