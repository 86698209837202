import { Box, Button } from '@mui/material'
import React from 'react'
import AppHeader from '../../components/AppHeader'
import GroupTabs from './GroupTabs'
import { useNavigate, useParams } from 'react-router'
import { useUserContext } from '../../contexts/UserContext'
import { useGetGroup } from 'api/group'
import Loading from 'components/Loading'
import ErrorComponent from 'components/ErrorComponent'

const GroupPage: React.FC = () => {
	const navigate = useNavigate()
	const { groupId } = useParams()
	const { user, profile } = useUserContext()
	const group = useGetGroup({ groupId, options: { enabled: !!groupId } })

	return (
		<>
			<Box>
				<AppHeader
					leftComponent={
						<Button
							sx={{ color: '#0195f5' }}
							onClick={() => navigate(-1)}
						>
							Back
						</Button>
					}
					title={group.data?.name || ''}
				/>

				{group.isSuccess ? (
					<GroupTabs
						group={group.data}
						currentProfile={profile}
						isAdmin={group.data.admin == user?.id}
					/>
				) : group.isError ? (
					<ErrorComponent />
				) : (
					<Loading />
				)}
			</Box>
		</>
	)
}

export default GroupPage
