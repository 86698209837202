import React from 'react'
import { Button, Box } from '@mui/material'

type Props = {
	hasBack: boolean
	nextEnabled: boolean
	onNext: () => void
	onBack: () => void
}
const WelcomeWizardNav: React.FC<Props> = ({
	hasBack,
	nextEnabled,
	onNext,
	onBack,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '1rem 2rem',
				position: 'relative',
				bottom: 0,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '2rem',
					width: '100%',
				}}
			>
				{/* Using visibility here to keep the relative size and spacing of the next button constant */}
				<Button
					sx={{ visibility: hasBack ? 'visible' : 'hidden' }}
					fullWidth
					variant='outlined'
					onClick={onBack}
				>
					Back
				</Button>
				<Button
					fullWidth
					variant='contained'
					onClick={onNext}
					disabled={!nextEnabled}
				>
					Next
				</Button>
			</Box>
		</Box>
	)
}

export default WelcomeWizardNav
