import React from 'react'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Validators } from 'utils/validators'
import { object } from 'yup'
import { v4 as uuidV4 } from 'uuid'
import { useUserContext } from 'contexts/UserContext'
import type { Guest } from 'lib/supabase'
import { FormInputText } from '../forms/elements'

type FormValues = {
	guestName: string
}

type Props = {
	isOpen: boolean
	onCancel: () => void
	onSubmit: (guest: Guest) => void
}

const QuickAddGuestDialog: React.FC<Props> = ({
	isOpen,
	onCancel,
	onSubmit,
}) => {
	const { activeGroup } = useUserContext()
	const activityId = ''
	const { handleSubmit, control, reset } = useForm<FormValues>({
		resolver: yupResolver(
			object().shape({
				guestName: Validators.NAME.required(),
			})
		),
		defaultValues: {
			guestName: '',
		},
	})

	const submitHandler = (data: FormValues) => {
		if (!activeGroup) return

		const newGuest: Guest = {
			id: uuidV4(),
			group_id: activeGroup.id,
			activity_id: activityId,
			full_name: data.guestName,
			created_at: '',
			created_by: '',
		}

		onSubmit(newGuest)
		reset()
	}

	return (
		<Dialog fullWidth open={isOpen} onClose={onCancel}>
			<DialogTitle>Add Guest to Activity</DialogTitle>
			<DialogContent>
				<form
					id='quick-add-form'
					onSubmit={handleSubmit(submitHandler)}
				>
					<FormInputText
						label='Guest Name'
						name='guestName'
						control={control}
						required
						variant='filled'
						fullWidth
						style={{ padding: '10px 5px' }}
					/>
				</form>
				<DialogContentText fontStyle={'italic'} paddingY={'10px'}>
					Guests are one-time attendees who are not in the group.
					Guests will not recieve updates and they cannot sign in to
					see information about activities.
				</DialogContentText>
				<DialogActions style={{ marginTop: '10px' }}>
					<Button onClick={onCancel}>Cancel</Button>
					<Button
						type='submit'
						form='quick-add-form'
						variant='contained'
					>
						Add Guest
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}

export default QuickAddGuestDialog
