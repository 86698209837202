import { Box, Button, AppBar } from '@mui/material'
import AppHeader from 'components/AppHeader'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { Venue } from 'lib/supabase'
import React, { useState } from 'react'
import { useDeleteVenue } from 'api/venue'
import { useNavigate } from 'react-router'
import VenueForm from 'components/forms/prebuilt/VenueForm'

type FormValues = {
	name: string
	phone?: string | null
	url?: string
	street?: string
	city?: string
	state?: string
	zip?: string
}

type Props = {
	readonly venue: Venue
	onSave: (newVenue: Venue) => void
	onCancel: () => void
}
const EditVenue: React.FC<Props> = ({ venue, onSave, onCancel }) => {
	const [showConfirm, setShowConfirm] = useState(false)
	const deleteVenue = useDeleteVenue()
	const navigate = useNavigate()

	const handleSubmitForm = (data: FormValues) => {
		const newVenue: Venue = {
			...venue,
			...data,
		}

		onSave(newVenue)
	}

	const handleDeleteVenue = async () => {
		await deleteVenue.mutateAsync({ venueId: venue.id })
		navigate('/venues', { replace: true })
	}

	return (
		<Box>
			<AppHeader
				leftComponent={
					<Button sx={{ color: '#0195f5' }} onClick={onCancel}>
						Cancel
					</Button>
				}
				title='Edit Venue'
				rightComponent={
					<Button
						sx={{ color: 'white' }}
						type='submit'
						form='edit-form'
					>
						Save
					</Button>
				}
			/>

			<VenueForm
				formId='edit-form'
				defaultValues={{
					name: venue.name,
					phone: venue.phone || '',
					url: venue.url || '',
					street: venue.street || '',
					city: venue.city || '',
					state: venue.state || '',
					zip: venue.zip || '',
				}}
				onSubmit={handleSubmitForm}
			/>

			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						margin: '10px 40px',
					}}
				>
					<Button
						variant='outlined'
						color='error'
						fullWidth
						style={{ maxWidth: '300px' }}
						onClick={() => setShowConfirm(true)}
					>
						Delete Venue
					</Button>
				</div>
			</AppBar>

			<ConfirmationDialog
				isOpen={showConfirm}
				onClose={() => setShowConfirm(false)}
				onConfirm={handleDeleteVenue}
				title={'Confirm Delete Venue'}
				content={'Do you want to permanently delete this venue?'}
				confirmButtonText='Delete Venue'
			/>
		</Box>
	)
}

export default EditVenue
