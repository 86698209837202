import React from 'react'
import { useNavigate } from 'react-router'
import { useAuthContext } from 'contexts/AuthContext'
import { formatPhoneNumber } from 'utils'
import SignInForm, {
	SignInFormValues,
} from 'components/forms/prebuilt/SignInForm'
import { AuthApiError } from '@supabase/supabase-js'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { generateRandomString } from 'utils'

const SignInBox: React.FC = () => {
	const { sendOTP } = useAuthContext()
	const { showSnackbar } = useSnackbarContext()
	const navigate = useNavigate()

	const handlePhoneLogin = async (data: SignInFormValues) => {
		const phone = data.phone
		if (!phone) return

		try {
			let messageId = await sendOTP(phone)
			if (messageId === '') messageId = generateRandomString(8, false)
			console.log('messageId: ' + messageId)

			navigate(`/otp/${messageId}`, {
				state: { phone: formatPhoneNumber(phone, true) },
			})
		} catch (err) {
			console.log('sign in error: ' + err)

			if (err instanceof AuthApiError) {
				if (err.message === 'Signups not allowed for otp') {
					showSnackbar(
						'Phone number not found. Please create an account.',
						'error'
					)
				}
			} else {
				showSnackbar('Unable to sign in with provided number.', 'error')
			}
		}
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				// alignItems: 'center',
				width: '100%',
				maxWidth: '75%',
			}}
		>
			{/* Supabase Email Login */}
			<SignInForm onSubmit={handlePhoneLogin} />
		</div>
	)
}

export default SignInBox
