import React from 'react'
import { Controller, type Control } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers'
import {
	TimePicker,
	DesktopTimePicker,
	type TimePickerProps,
} from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import Adapter from 'services/AdapterLuxonAdapter'

type Props = TimePickerProps<DateTime> & {
	name: string
	label: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any, any>
}
const FormInputTime: React.FC<Props> = ({
	name,
	label,
	control,
	...timePickerProps
}) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, ...otherFields } }) => (
				<LocalizationProvider dateAdapter={Adapter}>
					<TimePicker
						{...(timePickerProps || {})}
						label={label}
						value={DateTime.fromISO(value)}
						minutesStep={5}
						{...otherFields}
					/>
				</LocalizationProvider>
			)}
		/>
	)
}

export default FormInputTime
