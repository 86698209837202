import { sendServerRequest } from '../vercel/apicalls'

export type SubscriptionInfo = {
	status: string
	trialing: boolean
	trialend: number
	periodstart: number
	periodend: number
	autorenew: boolean
	subscription_id: string
}

export type StripeData = {
	stripeinfo: object
	subscription: object
}

export const normalizeSubscriptionStatus = (status: string) => {
	let retStatus = 'inactive'
	if (['active', 'trialing'].indexOf(status) > -1) retStatus = 'active'
	if (
		[
			'canceled',
			'unpaid',
			'past_due',
			'paused',
			'incomplete',
			'incomplete_expired',
		].indexOf(status) > -1
	)
		retStatus = 'expired'
	return retStatus
}

export const getSubscriptionInfo = async (sid: string) => {
	let furl = import.meta.env.VITE_VERCELAPI_HOST + 'stripe/get-subscription'
	furl += '?cid=' + sid
	const data: StripeData = await sendServerRequest(furl)

	return data
}
