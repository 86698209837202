import React, { useState, useEffect } from 'react'
import UserGroupList from './UserGroupList'
import { Drawer, ListItemButton } from '@mui/material'
import Badge, { badgeClasses } from '@mui/material/Badge'
import {
	List,
	ListItemText,
	ListItemAvatar,
	Avatar,
	Divider,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import LogoutIcon from '@mui/icons-material/Logout'
import SupportIcon from '@mui/icons-material/Support'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import AppShortcutIcon from '@mui/icons-material/AppShortcut'
import { useUserContext } from '../contexts/UserContext'
import { useNavigate } from 'react-router'
import { useAuthContext } from 'contexts/AuthContext'
import introJs from 'intro.js'
import { track } from '@vercel/analytics'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

type Props = {
	anchor: Anchor
	disabled?: boolean
}

const SettingsDrawer = (props: Props) => {
	const { profile } = useUserContext()
	const { signOut } = useAuthContext()
	const [initials, setInitials] = useState<string>('')
	const [open, setOpen] = useState(false)
	const navigate = useNavigate()

	const copyyear = new Date().getFullYear()

	useEffect(() => {
		if (profile?.full_name) {
			const names = profile?.full_name.split(' ')
			const firstInitial = names[0].slice(0, 1)
			const lastInitial = names[names.length - 1].slice(0, 1)
			setInitials(firstInitial + lastInitial)
		}
	}, [profile])

	const startTour = () => {
		introJs().start()
		track('taketour-menu')
	}

	return (
		<div>
			<Badge
				overlap='circular'
				color='error'
				variant='dot'
				invisible
				sx={{
					[`& .${badgeClasses.dot}`]: {
						width: '15px',
						height: '15px',
						borderRadius: '50%',
						borderWidth: '2px',
						borderStyle: 'solid',
						borderColor: '#ffffff',
					},
				}}
			>
				<Avatar
					onClick={() => setOpen(true)}
					style={{ fontSize: '1.0rem' }}
					data-intro='Tap your initials to open the Joyner menu where you can switch groups and access Joyner menu items like Help and Support.'
				>
					{initials}
				</Avatar>
			</Badge>

			<Drawer
				anchor={props.anchor}
				open={!props.disabled && open}
				onClose={() => setOpen(false)}
				onClick={() => setOpen(false)}
			>
				<div
					style={{
						height: '100%',
						width: '350px',
						paddingTop: 'env(safe-area-inset-top)',
						paddingBottom: 'env(safe-area-inset-bottom)',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'stretch',
						justifyContent: 'flex-start',
					}}
				>
					{/* USER GROUPS */}
					<UserGroupList />

					{/* USER MENU ITEMS */}
					<div>
						<List style={{ padding: '0px' }}>
							<Divider />
							<div
								style={{
									padding: '20px 0px 5px 10px',
									fontWeight: 'bold',
								}}
							>
								{profile?.full_name}
							</div>
							<ListItemButton
								onClick={() =>
									navigate(`./profile/${profile?.id}`)
								}
							>
								<ListItemAvatar>
									<Avatar>
										<PersonIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Profile' />
							</ListItemButton>
							<ListItemButton
								onClick={() => navigate('/account')}
							>
								<ListItemAvatar>
									<Avatar>
										<AttachMoneyIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Account' />
							</ListItemButton>
							<ListItemButton
								disabled
								onClick={() => navigate('/preferences')}
							>
								<ListItemAvatar>
									<Avatar>
										<SettingsIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Preferences' />
							</ListItemButton>
							<Divider />
							<ListItemButton onClick={startTour}>
								<ListItemAvatar>
									<Avatar>
										<AppShortcutIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Take Dashboard Tour'
									secondary='Quick tour of the dashboard'
								/>
							</ListItemButton>
							<ListItemButton href='https://help.joyner.app'>
								<ListItemAvatar>
									<Avatar>
										<TipsAndUpdatesIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Help'
									secondary='Tips and help pages'
								/>
							</ListItemButton>
							<ListItemButton
								onClick={() => navigate('/support')}
							>
								<ListItemAvatar>
									<Avatar>
										<SupportIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Support'
									secondary='Send us questions, problems, feedback'
								/>
							</ListItemButton>
							<Divider />
							<ListItemButton onClick={signOut}>
								<ListItemAvatar>
									<Avatar>
										<LogoutIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Sign Out' />
							</ListItemButton>
							<Divider />
						</List>
					</div>

					{/* FOOTERS */}
					<div style={{ marginTop: 'auto' }}>
						<div
							style={{
								margin: '10px 0px',
								textAlign: 'center',
								fontSize: '.6rem',
								color: '#666666',
								paddingBottom: '20px',
							}}
						>
							Copyright &copy; {copyyear} Joyner LLC
							<br />
							<span style={{ color: '#bbbbbb' }}>
								{location.hostname}
							</span>
						</div>
					</div>
				</div>
			</Drawer>
		</div>
	)
}

export default SettingsDrawer
