import {
	Routes,
	useLocation,
	createRoutesFromChildren,
	matchRoutes,
	useNavigationType,
} from 'react-router'
import React from 'react'
import {
	BrowserTracing,
	init,
	reactRouterV6Instrumentation,
	replayIntegration,
	withSentryReactRouterV6Routing,
} from '@sentry/react'

const enabledEnvironments = ['production', 'preview']

const initSentry = () => {
	init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_ENV, // different from MODE in Vite
		enabled: enabledEnvironments.includes(import.meta.env.VITE_ENV),

		// Error logging in dev
		debug: import.meta.env.DEV,

		integrations: [
			new BrowserTracing({
				routingInstrumentation: reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				),
			}),
			replayIntegration(),
		],
		// Performance Monitoring
		tracesSampleRate: 0.2,

		// Tracing
		tracePropagationTargets: [
			'localhost',
			new RegExp(import.meta.env.VITE_SITE_URL),
			/supabase\.co/,
		],

		// Session Replay
		replaysSessionSampleRate: import.meta.env.DEV ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

export default initSentry

export const SentryRoutes = withSentryReactRouterV6Routing(Routes)
