import React, { useEffect, useState } from 'react'
import {
	Box,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	ListItemAvatar,
	Avatar,
	Typography,
} from '@mui/material'
import { useUserContext } from '../contexts/UserContext'
import { useNavigate } from 'react-router'
import GroupsIcon from '@mui/icons-material/Groups'
import { Group } from 'lib/supabase'
import { useLocalStorage } from 'usehooks-ts'
import { GROUP_TIMESTAMP_COOKIE_KEY } from 'utils'
import { useGroupsContext } from 'contexts/GroupsContext'

type Props = {
	customHeader?: string
}
const UserGroupList: React.FC<Props> = ({ customHeader }) => {
	const { groups } = useGroupsContext()
	const { activeGroup, setActiveGroup } = useUserContext()
	const [sortedGroups, setSortedGroups] = useState<Group[]>(() =>
		groups.isSuccess ? groups.data : []
	)
	const [groupTimestamps] = useLocalStorage<{
		[groupId: string]: string
	}>(GROUP_TIMESTAMP_COOKIE_KEY, {})
	const navigate = useNavigate()
	const handleListClick = () => {
		navigate('../groups')
	}

	// sort the groups by the last time they were used
	useEffect(() => {
		if (groups.isSuccess) {
			const sorted = groups.data.sort((a, b) => {
				const aTimestamp = groupTimestamps[a.id]
				const bTimestamp = groupTimestamps[b.id]
				if (aTimestamp && bTimestamp) {
					return bTimestamp.localeCompare(aTimestamp)
				} else if (aTimestamp) {
					return -1
				} else if (bTimestamp) {
					return 1
				} else {
					return 0
				}
			})
			setSortedGroups(sorted)
		}
	}, [groups])

	return (
		<Box width={'100%'}>
			<Typography
				sx={{ padding: '10px 0px 10px 10px', fontWeight: 'bold' }}
			>
				{customHeader ? customHeader : 'Select a Group'}
			</Typography>
			<List style={{ padding: '0px', marginLeft: '20px' }}>
				{sortedGroups?.slice(0, 3).map((group) => {
					return (
						<ListItem
							disablePadding
							key={group.id}
							style={{ borderBottom: '1px solid #cccccc' }}
						>
							<ListItemButton
								key={group.id}
								onClick={() => setActiveGroup(group.id)}
								selected={activeGroup?.id === group.id}
							>
								<ListItemText>{group.name}</ListItemText>
							</ListItemButton>
						</ListItem>
					)
				})}
				<ListItemButton onClick={() => handleListClick()}>
					<ListItemAvatar>
						<Avatar>
							<GroupsIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText>Manage Your Groups</ListItemText>
				</ListItemButton>
			</List>
		</Box>
	)
}

export default UserGroupList
