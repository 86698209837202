import queryClient from 'lib/reactQuery'
import supabase from 'lib/supabase'
import { TABLE_MESSAGES } from 'lib/supabase'
import type { QueryConfig } from 'lib/reactQuery'
import { useQuery } from '@tanstack/react-query'

type GetManyByGroupIdArgs = {
	groupId?: string
}
const getManyByGroupIdFunction = async ({ groupId }: GetManyByGroupIdArgs) => {
	if (!groupId) return []

	const { data, error } = await supabase
		.from(TABLE_MESSAGES)
		.select('*')
		.eq('group_id', groupId)
		.order('created_at', { ascending: false })

	if (error) throw error

	return data
}

export const getManyByGroupId = async ({ groupId }: GetManyByGroupIdArgs) =>
	queryClient.fetchQuery({
		queryKey: ['messages', 'byGroupId', groupId],
		queryFn: () => getManyByGroupIdFunction({ groupId }),
	})

type UseGetManyByGroupIdOptions = {
	groupId?: string
	options?: QueryConfig<typeof getManyByGroupIdFunction>
}
export const useGetManyByGroupId = ({
	groupId,
	options,
}: UseGetManyByGroupIdOptions) => {
	return useQuery({
		...options,
		queryKey: ['messages', 'byGroupId', groupId],
		queryFn: () => getManyByGroupIdFunction({ groupId }),
	})
}
