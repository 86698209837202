import { string, number, boolean } from 'yup'

export const Validators = {
	EMAIL: string(),
	PASSWORD: string().min(8).max(72),
	NAME: string()
		.matches(/^[a-zA-Z0-9\s'&-+!@&#$*()]+$/)
		.min(2)
		.max(30),
	ACTIVITY_NAME: string()
		.matches(/^[a-zA-Z0-9\s'&-+!@#&$*()]+$/)
		.min(2)
		.max(30),
	ACTIVITY_NOTE: string()
		.nullable()
		.transform((o, c) => (o === '' ? null : c))
		.max(1000),
	VENUE_NAME: string()
		.matches(/^[a-zA-Z0-9\s'&-+!@#&$*()]+$/)
		.min(2)
		.max(30),
	ADDRESS: string(),
	CITY: string(),
	STATE: string(),
	ZIP: string(),
	COUNTRY: string(),
	UUID: string().uuid(),
	PHONE: string()
		.matches(/^[0-9]+$/, { message: 'Phone can only contain numbers' })
		.nullable()
		.transform((o, c) => (o === '' ? null : c))
		.min(10)
		.max(11),
	// TODO make these better
	DATE: string(),
	TIME: string(),
	STRING: string(),
	NUMBER: number(),
	URL: string().url(),
	BOOLEAN: boolean(),
	OTP: string().length(6),
	DEFAULT_TIMEZONE: string(),
}
