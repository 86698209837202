import queryClient from '../../lib/reactQuery'
import { TABLE_PROFILES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import { createGeneric } from '../generic'

import type { Profile } from '../../lib/supabase'
import type { MutationConfig } from "../../lib/reactQuery"

type CreateProfileArgs = {
	profile: Profile
}
export const createProfile = async ({ profile }: CreateProfileArgs) => createGeneric({ tableName: TABLE_PROFILES, data: profile })

type UseCreateProfileOptions = {
	options?: MutationConfig<typeof createProfile>
}
export const useCreateProfile = ({ options }: UseCreateProfileOptions = {}) => {
	return useMutation({
		onSuccess: (profile) => {
			queryClient.setQueryData(['profile', profile.id], profile)
		},
		...options,
		mutationFn: createProfile
	})
}
