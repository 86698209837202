import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
	getQueryKey,
	useDeleteActivity,
	useGetActivity,
	useUpdateActivity,
} from 'api/activity'
import { useCreateVenue, useGetManyByGroupId } from 'api/venue'
import { useCreateGuest, useGetManyByActivityId } from 'api/guest'
import { useGetProfiles } from 'api/profile'
import Loading from 'components/Loading'
import ViewActivity from './ViewActivity'
import type { Activity, Profile, Venue, Guest } from 'lib/supabase'
import EditActivity from './EditActivity'
import supabase, { TABLE_ACTIVITIES } from 'lib/supabase'
import queryClient from 'lib/reactQuery'
import ErrorComponent from 'components/ErrorComponent'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useUserContext } from 'contexts/UserContext'
import { useGroupsContext } from 'contexts/GroupsContext'
import { sendNotification } from '../../vercel/apicalls'
import QuickAddGuestDialog from 'components/dialogs/QuickAddGuestDialog'
import GroupMembersDialog from 'components/dialogs/GroupMembersDialog'
import { DateTime } from 'luxon'

const ActivityPage: React.FC = () => {
	const { profile, activeGroup } = useUserContext()
	const { userIsAdminOfGroup } = useGroupsContext()
	const { showSnackbar } = useSnackbarContext()
	const navigate = useNavigate()
	const [editing, setEditing] = useState(false)
	const updateActivity = useUpdateActivity()
	const deleteActivity = useDeleteActivity()
	const createVenue = useCreateVenue()
	const createGuest = useCreateGuest()
	const [showGuestQuickAdd, setShowGuestQuickAdd] = useState(false)
	const [showGroupMembers, setShowGroupMembers] = useState(false)

	const { activityId } = useParams()
	const activity = useGetActivity({
		activityId: activityId!,
		options: { enabled: !!activityId },
	})

	const venues = useGetManyByGroupId({
		groupId: activeGroup?.id,
		options: { enabled: !!activeGroup },
	})

	if (activity.isError || venues.isError) {
		console.error(activity.error)
	}

	const tins = useGetProfiles({
		profileIds: activity?.data?.ins || [],
		options: { enabled: activity.isSuccess },
	})
	const ins = tins
		.filter((user) => user.isSuccess)
		.map((user) => user.data) as Profile[]

	const guests = useGetManyByActivityId({
		activityId: activityId!,
		options: { enabled: !!activityId },
	})

	let gins: Profile[] = []
	if (guests.data) {
		gins = guests.data.filter((g: Profile) =>
			activity?.data?.ins.find((i) => i === g.id)
		)
	}
	const combinedIns = [...(ins || []), ...(gins || [])]

	const outs = useGetProfiles({
		profileIds: activity?.data?.outs || [],
		options: { enabled: activity.isSuccess },
	})

	const venue = useMemo(() => {
		return venues.data?.find((v) => v.id === activity.data?.venue_id)
	}, [venues.data, activity.data?.venue_id])
	useEffect(() => {
		if (activity.isSuccess) {
			const chan = supabase
				.channel('db-changes')
				.on(
					'postgres_changes',
					{
						event: '*',
						schema: 'public',
						table: TABLE_ACTIVITIES,
					},
					async (payload) => {
						const newActivity = payload.new as Activity
						if (
							newActivity &&
							newActivity.id === activity.data.id
						) {
							await queryClient.setQueryData(
								getQueryKey({ activityId: newActivity.id }),
								payload.new
							)
						}
					}
				)
				.subscribe()

			return () => {
				chan.unsubscribe()
			}
		}
	}, [activity])

	const handleEdit = () => {
		setEditing(true)
	}

	const handleCancel = () => {
		setEditing(false)
	}

	const handleSave = async (
		newActivity: Partial<Omit<Activity, 'id'>>,
		notifyMembers: boolean
	) => {
		if (!activity.isSuccess) return

		await updateActivity.mutateAsync({
			activityId: activity.data.id,
			activity: newActivity,
		})

		if (notifyMembers) {
			let furl = ''
			// send email notification
			furl = import.meta.env.VITE_VERCELAPI_HOST + 'email/activity-update'
			furl += '?gid=' + encodeURIComponent(activeGroup?.id || '')
			furl += '&aid=' + encodeURIComponent(activity.data.id || '')
			sendNotification(furl)

			// send sms notification
			if (profile?.allow_sms) {
				furl =
					import.meta.env.VITE_VERCELAPI_HOST +
					'twilio/activity-update'
				furl += '?gid=' + encodeURIComponent(activeGroup?.id || '')
				furl += '&aid=' + encodeURIComponent(activity.data.id || '')
				sendNotification(furl)
			}
		}

		setEditing(false)
	}

	const handleRemoveIn = async (uid: string) => {
		if (!activity.data) return
		const newIns = activity.data.ins
		const index = newIns.findIndex((i) => i === uid)
		newIns.splice(index, 1)
		await updateActivity.mutateAsync({
			activityId: activity.data.id,
			activity: {
				ins: newIns,
				outs: activity.data.outs,
			},
		})
	}

	const showAddGuest = () => {
		setShowGuestQuickAdd(true)
	}

	const handleAddGuest = async (newGuest: Guest) => {
		activityId && (newGuest.activity_id = activityId)
		profile?.id && (newGuest.created_by = profile?.id)
		newGuest.created_at = DateTime.now().toISO()

		createGuest.mutate({ guest: newGuest })
		if (activity.data) {
			const newIns = activity.data.ins
			newIns.push(newGuest.id)
			await updateActivity.mutateAsync({
				activityId: activity.data.id,
				activity: {
					ins: newIns,
					outs: activity.data.outs,
				},
			})
		}
		setShowGuestQuickAdd(false)
	}

	const showAddMember = () => {
		setShowGroupMembers(true)
	}

	const handleAddMember = async (selected: string[]) => {
		if (!activity.data) return
		const newIns = activity.data.ins
		selected.map((member) => {
			const index = newIns.findIndex((i) => i === member)
			if (index === -1) newIns.push(member)
		})
		await updateActivity.mutateAsync({
			activityId: activity.data.id,
			activity: {
				ins: newIns,
				outs: activity.data.outs,
			},
		})

		setShowGroupMembers(false)
	}

	const handleDeleteActivity = () => {
		if (activityId && activity.isSuccess) {
			let furl =
				import.meta.env.VITE_VERCELAPI_HOST + 'email/activity-delete'
			furl += '?gid=' + encodeURIComponent(activeGroup?.id || '')
			furl += '&aname=' + encodeURIComponent(activity.data.name || '')
			furl += '&adate=' + encodeURIComponent(activity.data.datetime || '')

			sendNotification(furl).then(() => {
				showSnackbar('Activity Deleted', 'success')
				deleteActivity.mutate({ activityId: activityId })
				navigate('/')
			})

			if (profile?.allow_sms) {
				furl =
					import.meta.env.VITE_VERCELAPI_HOST +
					'twilio/activity-delete'
				furl += '?gid=' + encodeURIComponent(activeGroup?.id || '')
				furl += '&aname=' + encodeURIComponent(activity.data.name || '')
				furl +=
					'&adate=' + encodeURIComponent(activity.data.datetime || '')
				sendNotification(furl)
			}
		}
	}

	const handleCreateVenue = async (venue: Venue): Promise<Error | void> => {
		try {
			await createVenue.mutateAsync({ venue })
		} catch (err) {
			return err as Error
		}
	}

	const handleRemind = async () => {
		if (!activity.data || !profile) return

		let furl = ''
		furl = import.meta.env.VITE_VERCELAPI_HOST + 'email/activity-reminder'
		furl += '?gid=' + encodeURIComponent(activeGroup?.id || '')
		furl += '&aid=' + encodeURIComponent(activity.data.id)
		sendNotification(furl)

		if (profile.allow_sms) {
			furl =
				import.meta.env.VITE_VERCELAPI_HOST + 'twilio/activity-reminder'
			furl += '?gid=' + encodeURIComponent(activeGroup?.id || '')
			furl += '&aid=' + encodeURIComponent(activity.data.id || '')
			sendNotification(furl)
		}

		showSnackbar('Reminder Sent to All Members', 'success')
	}

	const handleSendNote = async () => {
		console.log('send note')
		if (!activity.data || !profile) return

		let furl = ''
		if (profile.allow_sms) {
			furl = import.meta.env.VITE_VERCELAPI_HOST + 'twilio/activity-note'
			furl += '?gid=' + encodeURIComponent(activeGroup?.id || '')
			furl += '&aid=' + encodeURIComponent(activity.data.id || '')
			sendNotification(furl)
		}
	}

	return (
		<main>
			{activity.isSuccess && venues.isSuccess && venue && profile ? (
				editing ? (
					<EditActivity
						venues={venues.data}
						activity={activity.data}
						onSave={handleSave}
						onCancel={handleCancel}
						onDeleteActivity={handleDeleteActivity}
						onCreateVenue={handleCreateVenue}
					/>
				) : (
					<ViewActivity
						activity={activity.data}
						canViewButtons={
							activity.data.activity_owner_id === profile.id ||
							userIsAdminOfGroup(activity.data.group_id)
						}
						ins={combinedIns}
						outs={
							outs
								.filter((user) => user.isSuccess)
								.map((user) => user.data) as Profile[]
						}
						venue={venue}
						onBack={() => navigate('/')}
						onEdit={handleEdit}
						onRemind={handleRemind}
						onSendNote={handleSendNote}
						onRemoveIn={handleRemoveIn}
						onShowAddGuest={showAddGuest}
						onShowAddMember={showAddMember}
					/>
				)
			) : activity.isError || venues.isError ? (
				<ErrorComponent />
			) : (
				<Loading />
			)}

			<QuickAddGuestDialog
				isOpen={showGuestQuickAdd}
				onCancel={() => setShowGuestQuickAdd(false)}
				onSubmit={(newGuest: Guest) => handleAddGuest(newGuest)}
			/>
			<GroupMembersDialog
				isOpen={showGroupMembers}
				onCancel={() => setShowGroupMembers(false)}
				onList={(selected: string[]) => handleAddMember(selected)}
				activeGroup={activeGroup?.id}
				ins={activity?.data?.ins}
				outs={activity?.data?.outs}
				title='Add Member(s) to Activity'
				buttonText='Add Selected'
				selectAll={false}
				filterList={true}
			/>
		</main>
	)
}

export default ActivityPage
