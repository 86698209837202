import React, { useEffect } from 'react'
import { Box, Divider, List, Typography } from '@mui/material'
import { useGetManyByUserId } from 'api/activity/getManyByUserId'
import { useGetManyByGroupId as getMessagesByGroupId } from 'api/messages/getManyByGroupId'
import Loading from 'components/Loading'
import { useUserContext } from 'contexts/UserContext'
import MessageBox from './MessageBox'
import { DateTime } from 'luxon'
import { Activity, Message } from 'lib/supabase'

const Messages: React.FC = () => {
	const { profile, activeGroup } = useUserContext()
	const activities = useGetManyByUserId({
		userId: profile?.id,
		groupId: activeGroup?.id,
		options: { enabled: !!profile },
	})
	const [filteredActivities, setFilteredActivities] =
		React.useState<Activity[]>()

	useEffect(() => {
		if (activities.isSuccess) {
			setFilteredActivities(
				activities.data.filter(
					(activity) =>
						activity.active &&
						DateTime.fromISO(activity.datetime) >
							DateTime.now().plus({ hours: 1 })
				)
			)
		}
	}, [activities.data, activities.isSuccess])

	const { data: messages } = getMessagesByGroupId({
		groupId: profile?.active_group || '',
		options: { enabled: !!profile?.active_group },
	})

	const getLatestMessageDateForActivity = (activityId: string) => {
		if (activityId && messages) {
			const latestDate = messages.filter(
				(message: Message) => message.activity_id === activityId
			)[0]?.created_at
			return latestDate
		} else {
			return ''
		}
	}

	return (
		<Box style={{ paddingBottom: '60px' }}>
			{activities.isSuccess ? (
				<>
					<div style={{ textAlign: 'center', marginTop: '20px' }}>
						<Typography variant='h6'>Activity Chats</Typography>
					</div>
					<List>
						<Divider />
						{filteredActivities && filteredActivities.length > 0 ? (
							filteredActivities.map((activity) => {
								const latestMessage =
									getLatestMessageDateForActivity(activity.id)

								return (
									<MessageBox
										key={activity.id}
										activity={activity}
										latestDate={latestMessage}
									/>
								)
							})
						) : (
							<Typography
								textAlign='center'
								marginTop={'2.0rem'}
								marginX={'2.0rem'}
							>
								No activity chats to display.
							</Typography>
						)}
					</List>
				</>
			) : (
				<Loading />
			)}
		</Box>
	)
}

export default Messages
