import { DateTime } from 'luxon'
import { INTL_PHONE_NUMBER_LENGTH } from './constants'

// returns the date of the monday starting 'date' week
export function calculateWeek(date: DateTime): DateTime {
	const weekday = date.weekday - 1
	const newDate = date.minus({ days: weekday })
	return newDate
}

export function nextWeek(date: DateTime): DateTime {
	const weekday = date.weekday - 1
	const next = date.plus({ days: 7 - weekday })
	return next
}

export function prevWeek(date: DateTime): DateTime {
	const weekday = date.weekday - 1
	const next = date.minus({ days: 7 + weekday })
	return next
}

export function nextMonth(date: DateTime): DateTime {
	const next = date.plus({ months: 1 }).startOf('month')
	return next
}

export function prevMonth(date: DateTime): DateTime {
	const next = date.minus({ months: 1 }).startOf('month')
	return next
}

export function thisWeek() {
	const luxdt = DateTime.now()
	const thisWeekStart = calculateWeek(luxdt)
	return thisWeekStart
}

export function jsDateToLuxDateTime(date: Date) {
	return DateTime.fromJSDate(date)
}

export function pluralize(s: string): string {
	let ss = s
	if (ss.slice(-1) == 's') {
		ss += "'"
	} else if (ss.slice(-1) == 'y') {
		ss = ss.slice(0, -1) + 'ies'
	} else {
		ss += 's'
	}
	return ss
}

// returns the suffix for a number
export function nth(d: number) {
	if (d > 3 && d < 21) return 'th'
	switch (d % 10) {
		case 1:
			return 'st'
		case 2:
			return 'nd'
		case 3:
			return 'rd'
		default:
			return 'th'
	}
}

export function formatPhoneNumber(phoneNumber: string, isE164 = false): string {
	const cleanedNumber = phoneNumber.replaceAll(/\D/g, '')
	if (cleanedNumber.length === INTL_PHONE_NUMBER_LENGTH) {
		return `+${cleanedNumber.slice(0, 1)}${
			isE164 ? '' : ' ('
		}${cleanedNumber.slice(1, 4)}${isE164 ? '' : ') '}${cleanedNumber.slice(
			4,
			7
		)}${isE164 ? '' : '-'}${cleanedNumber.slice(7)}`
	}
	return `+1${isE164 ? '' : ' ('}${cleanedNumber.slice(0, 3)}${
		isE164 ? '' : ') '
	}${cleanedNumber.slice(3, 6)}${isE164 ? '' : '-'}${cleanedNumber.slice(6)}`
}

export const groupBy = <T extends Record<string, unknown>>(
	list: T[],
	callback: (element: T) => string
): Record<string, T[]> =>
	list.reduce((acc, currentItem) => {
		const group = callback(currentItem)
		if (!acc[group]) {
			acc[group] = []
		}
		acc[group].push(currentItem)
		return acc
	}, {} as Record<string, T[]>)

export const objectMap = <
	T extends Record<string, unknown>,
	U extends Record<string, unknown>
>(
	object: T,
	mapFn: (value: T[keyof T], key: keyof T, index: number) => U[keyof U]
): U =>
	Object.fromEntries(
		Object.entries(object).map(([key, value], index) => [
			key,
			mapFn(value as T[keyof T], key as keyof T, index),
		])
	) as U

export const objectFlatMap = <
	T extends Record<string, unknown>,
	U extends Record<string, unknown>
>(
	object: T,
	mapFn: (value: T[keyof T], key: keyof T) => U[keyof U][]
): U[] => Object.entries(objectMap(object, mapFn)).flat() as U[]

export const combineDateAndTime = (date: string, time: string): string => {
	return date.split('T')[0] + 'T' + time.split('T')[1]
}

export const generateRandomString = (
	length: number,
	inclSymbols: boolean
): string => {
	const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
	const numbers = '0123456789'
	const symbols = '!@#$%^&*()_+-=[]{}|;:,.<>?'

	let resp = ''
	resp =
		window.crypto.randomUUID().substring(0, length) +
		letters[Math.floor(Math.random() * letters.length)].toLowerCase() +
		letters[Math.floor(Math.random() * letters.length)].toUpperCase() +
		numbers[Math.floor(Math.random() * numbers.length)]
	if (inclSymbols) resp += symbols[Math.floor(Math.random() * symbols.length)]
	return resp
}
