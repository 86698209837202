import { useQuery } from '@tanstack/react-query'
import queryClient from 'lib/reactQuery'
import supabase, { Activity } from 'lib/supabase'
import { DateTime } from 'luxon'
import { TABLE_ACTIVITIES } from 'lib/supabase'
import { getQueryKey } from './get'

import type { QueryConfig } from 'lib/reactQuery'

export type SupportedRanges = 'week' | 'month'

export const getActivitiesInRangeQueryKey = ({
	date,
	groupId,
	range,
}: GetActivityInRangeArgs) => [
	'group',
	groupId,
	'activities',
	range,
	date.toISOWeekDate(),
]

type GetActivityInRangeArgs = {
	date: DateTime
	groupId: string
	range?: SupportedRanges
}
const getActivitiesInRangeFunction = async ({
	date,
	groupId,
	range,
}: GetActivityInRangeArgs) => {
	if (!date || !groupId.length || !range) return []

	const { data, error } = await supabase
		.from(TABLE_ACTIVITIES)
		.select()
		.gte('datetime', date.startOf(range).toISO())
		.lte('datetime', date.endOf(range).toISO())
		.order('datetime', { ascending: true })
		.eq('group_id', groupId)

	if (error) throw error

	return data
}

export const getActivitiesInRange = async ({
	date,
	groupId,
	range,
}: GetActivityInRangeArgs) =>
	// eslint-disable-next-line @tanstack/query/exhaustive-deps
	queryClient.fetchQuery({
		queryKey: getActivitiesInRangeQueryKey({ date, groupId, range }),
		queryFn: () => getActivitiesInRangeFunction({ date, groupId, range }),
	})

type UseGetActivityOptions = {
	date: DateTime
	groupId: string
	range?: SupportedRanges
	options?: QueryConfig<() => Promise<Activity[]>>
}
export const useGetActivitiesInRange = ({
	date,
	groupId,
	range,
	options,
}: UseGetActivityOptions) => {
	return useQuery({
		...options,
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: getActivitiesInRangeQueryKey({ date, groupId, range }),
		queryFn: async () => {
			// get the activity ids for the week
			const activities = await getActivitiesInRangeFunction({
				date,
				groupId,
				range,
			})

			activities.forEach((activity) => {
				queryClient.setQueryData(
					getQueryKey({ activityId: activity.id }),
					activity
				)
			})

			return activities
		},
	})
}
