import React, { useState } from 'react'
import type { Message } from 'lib/supabase'
import {
	Box,
	Typography,
	Avatar,
	Stack,
	Menu,
	MenuItem,
	Divider,
} from '@mui/material'
import { useGetProfile } from 'api/profile'

// TODO add the sender's profile to props
// so we're not fetching it for each bubble
type Props = {
	message: Message
	userIsAuthor: boolean
}

const generateInitials = (name: string) => {
	let inits: string = ''
	name.split(' ').map((word) => {
		inits += word.substring(0, 1)
	})
	return inits.toUpperCase()
}

const Bubble = React.forwardRef<Element | undefined, Props>(
	({ message, userIsAuthor }, ref) => {
		const profile = useGetProfile({
			profileId: message.sender,
		})

		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

		const handleClick = (event: React.MouseEvent<HTMLElement>) => {
			console.log('open menu')
			setAnchorEl(event.currentTarget)
		}

		const handleClose = () => {
			console.log('close menu')
			setAnchorEl(null)
		}

		const handleCopy = (msg: Message) => {
			navigator.clipboard.writeText(msg.body)
			setAnchorEl(null)
			console.log('copied message')
		}

		const handleEdit = (msg: Message) => {
			console.log('edit message')
			setAnchorEl(null)
		}

		const handleDelete = (msg: Message) => {
			console.log('delete message')
			setAnchorEl(null)
		}

		const messageBody = (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: userIsAuthor ? 'flex-end' : 'flex-start',
					paddingLeft: userIsAuthor ? '0' : '0.3rem',
					paddingRight: userIsAuthor ? '0.3rem' : '0',
					paddingY: '0.3rem',
				}}
			>
				<Box
					sx={{
						maxWidth: '80%',
						textAlign: userIsAuthor ? 'right' : 'left',
						backgroundColor: userIsAuthor ? '#C8E6C9' : '#ECEFF1',
						padding: '0.5rem 0.7rem',
						borderRadius: '5px',
					}}
					onClick={handleClick}
				>
					{userIsAuthor ? (
						<Stack direction={'row'} gap={'10px'}>
							<Stack direction={'column'}>
								<Typography component={'p'}>
									{message.body}
								</Typography>
							</Stack>
						</Stack>
					) : (
						<Stack direction={'row'} gap={'10px'}>
							<Avatar>
								{generateInitials(
									profile.data?.full_name || ''
								)}
							</Avatar>
							<Stack direction={'column'}>
								<Typography
									sx={{
										fontWeight: 'bold',
										fontSize: '0.8rem',
										height: '0.8rem',
										color: 'grey.700',
									}}
								>
									{profile.data?.full_name}
								</Typography>
								<Typography component={'p'}>
									{message.body}
								</Typography>
							</Stack>
						</Stack>
					)}
				</Box>

				<Menu
					id='basic-menu'
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					<MenuItem disabled onClick={() => handleEdit(message)}>
						Edit...
					</MenuItem>
					<MenuItem disabled onClick={() => handleDelete(message)}>
						Delete
					</MenuItem>
					<Divider />
					<MenuItem onClick={() => handleCopy(message)}>
						Copy
					</MenuItem>
				</Menu>
			</Box>
		)

		// for triggering the new message fetch
		const content = ref ? (
			<Box component={'article'} ref={ref}>
				{messageBody}
			</Box>
		) : (
			<Box component={'article'}>{messageBody}</Box>
		)

		return content
	}
)

// I have no idea why this is needed
// but eslint throws a fit if I don't add this
Bubble.displayName = 'ChatBubble'

export default Bubble
