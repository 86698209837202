import React, { useEffect } from 'react'
import { useGetInvite } from 'api/invites/get'
import Loading from 'components/Loading'
import { useParams } from 'react-router'
import AnonHeader from 'components/AnonHeader'
import { Box, Typography } from '@mui/material'
import { useAuthContext } from 'contexts/AuthContext'
import AccountInfo from './AccountInfo'
import Join from './Join'
import { DateTime } from 'luxon'
import InviteExpired from './InviteExpired'
import { ErrorComponent } from 'components/ErrorComponent'

const InvitePage = () => {
	const { isAuthenticated } = useAuthContext()
	const { inviteId } = useParams()
	const [isExpired, setIsExpired] = React.useState<boolean>()

	if (!inviteId) throw new Error('No inviteId provided')

	const invite = useGetInvite({
		inviteId,
		options: { enabled: !!inviteId },
	})

	if (invite.isError) {
		console.error(invite.error)
	}

	useEffect(() => {
		if (invite.isSuccess) {
			const now = DateTime.now()
			const expires = DateTime.fromISO(invite.data.expires_at)
			setIsExpired(expires < now)
		}
	}, [invite.isSuccess])

	return (
		<>
			{invite.isSuccess ? (
				<>
					<AnonHeader />
					<Typography paddingTop='1rem' align='center'>
						You have been invited to join
					</Typography>
					<Typography align='center' fontWeight='bold'>
						{invite.data.group_name}
					</Typography>
					<Typography align='center'>by</Typography>
					<Typography align='center' fontWeight='bold'>
						{invite.data.created_by_name}
					</Typography>
					<Box padding='1rem 2rem'>
						{isExpired ? (
							<InviteExpired />
						) : (
							<>
								{isAuthenticated ? (
									<Join groupId={invite.data.group_id} />
								) : (
									<AccountInfo />
								)}
							</>
						)}
					</Box>
				</>
			) : invite.isError ? (
				<ErrorComponent />
			) : (
				<Loading />
			)}
		</>
	)
}

export default InvitePage
