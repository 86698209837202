import React, { useCallback, useEffect } from 'react'
import supabase, {
	TABLE_GROUPS,
	type Group,
	TABLE_MEMBERSHIP,
	Membership,
} from 'lib/supabase'
import { type UseQueryResult } from '@tanstack/react-query'
import { useGetGroups } from 'api/group/getMany'
import { useUserContext } from './UserContext'
import {
	getGroup as retrieveGroupAPI,
	useCreateGroup,
	useUpdateGroup,
	useDeleteGroup,
} from 'api/group'
import { useAddUserToGroup, useRemoveUserFromGroup } from 'api/membership'
import { useSnackbarContext } from './SnackbarContext'

type UserIdAndGroupId = {
	userId: string
	groupId: string
}
type AddUserToGroupArgs = UserIdAndGroupId
type RemoveUserFromGroupArgs = UserIdAndGroupId

type GroupsContextType = {
	groups: UseQueryResult<Group[], Error>

	createGroup: (newGroup: Group) => Promise<void>
	retrieveGroup: (groupId: string) => Promise<Group>
	updateGroup: (newGroup: Group) => Promise<void>
	deleteGroup: (groupId: string) => Promise<void>

	addUserToGroup: (args: AddUserToGroupArgs) => Promise<void>
	removeUserFromGroup: (args: RemoveUserFromGroupArgs) => Promise<void>

	userIsAdminOfGroup: (groupId: string) => boolean
}
const GroupsContext = React.createContext<GroupsContextType | undefined>(
	undefined
)

export const useGroupsContext = () => {
	const context = React.useContext(GroupsContext)
	if (!context) {
		throw new Error('No groups context provider')
	}

	return context
}

export const GroupsContextProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const { profile, activeGroup, setActiveGroup } = useUserContext()
	const { showSnackbar } = useSnackbarContext()
	const createGroupHook = useCreateGroup()
	const updateGroupHook = useUpdateGroup()
	const deleteGroupHook = useDeleteGroup()
	const addUserToGroupHook = useAddUserToGroup()
	const removeUserFromGroupHook = useRemoveUserFromGroup()

	const groups = useGetGroups({
		userId: profile?.id,
		options: {
			enabled: !!profile,
		},
	})

	const createFilter = useCallback(
		(groups: Array<Group | null>) => {
			const groupsJoin = groups.map((group) => group?.id).join(', ')
			const filter = `id=in.(${groupsJoin})`
			return filter
		},
		[groups?.data]
	)

	useEffect(() => {
		// user has no groups but has an active group
		// therefore we need to invalidate their active group
		// which sends them to the welcome page
		if (
			groups.isSuccess &&
			!groups.isFetching &&
			groups.data.length === 0 &&
			profile?.active_group !== null &&
			// hacky but we don't want this running when the user's joining a group
			window.location.pathname.match(/\/group\/join\//) === null
		) {
			setActiveGroup(null)
			showSnackbar('You have no valid groups.', 'info')
		}
	}, [profile, groups.isSuccess])

	// subscribe to realtime changes for current groups
	useEffect(() => {
		if (groups.isSuccess) {
			const currentGroupsFilter = createFilter(groups.data)
			const chan = supabase
				.channel('groups-db-changes')
				// handle updates
				.on(
					'postgres_changes',
					{
						event: 'UPDATE',
						schema: 'public',
						table: TABLE_GROUPS,
						filter: currentGroupsFilter,
					},
					async (payload) => {
						const group = payload.new as Group
						if (
							group.id === activeGroup?.id &&
							group.status === 'deleted'
						) {
							const remainingGroups = groups.data.filter(
								(g) => g.id !== group.id
							)
							if (remainingGroups.length > 0) {
								await setActiveGroup(remainingGroups[0].id)
							} else {
								// send users to the welcome page
								await setActiveGroup(null)
							}
							showSnackbar('Group was deleted.', 'info')
						}
						await groups.refetch()
					}
				)
				.on(
					'postgres_changes',
					{
						event: 'DELETE',
						schema: 'public',
						table: TABLE_MEMBERSHIP,
						filter: `user_id=eq.${profile?.id}`,
					},
					async (payload) => {
						const membership = payload.old as Membership
						if (membership.group_id === activeGroup?.id) {
							const remainingGroups = groups.data.filter(
								(group) => group.id !== membership.group_id
							)
							if (remainingGroups.length > 0) {
								await setActiveGroup(remainingGroups[0].id)
							} else {
								// send user to the welcome page
								await setActiveGroup(null)
							}
							showSnackbar('Removed from group.', 'info')
						}
					}
				)
				// update groups when added to new group
				.on(
					'postgres_changes',
					{
						event: 'INSERT',
						schema: 'public',
						table: TABLE_MEMBERSHIP,
						filter: `user_id=eq.${profile?.id}`,
					},
					async () => {
						await groups.refetch()
					}
				)
				.subscribe()

			return () => {
				chan.unsubscribe()
			}
		}
	})

	const createGroup = async (group: Group): Promise<void> => {
		await createGroupHook.mutateAsync({ group })
	}
	const retrieveGroup = async (groupId: string): Promise<Group> => {
		return await retrieveGroupAPI({ groupId })
	}
	const updateGroup = async (group: Group): Promise<void> => {
		await updateGroupHook.mutateAsync({ group })
	}
	const deleteGroup = async (groupId: string): Promise<void> => {
		await deleteGroupHook.mutateAsync({ groupId })
	}

	const addUserToGroup = async ({
		userId,
		groupId,
	}: AddUserToGroupArgs): Promise<void> => {
		await addUserToGroupHook.mutateAsync({ userId, groupId })
	}
	const removeUserFromGroup = async ({
		userId,
		groupId,
	}: RemoveUserFromGroupArgs): Promise<void> => {
		await removeUserFromGroupHook.mutateAsync({ userId, groupId })
	}

	const userIsAdminOfGroup = (groupId: string) => {
		if (!profile || !groups.isSuccess) return false
		const groupAdminId = groups.data.find(
			(group) => group.id === groupId
		)?.admin
		return groupAdminId == profile.id
	}

	return (
		<GroupsContext.Provider
			value={{
				groups,
				createGroup,
				retrieveGroup,
				updateGroup,
				deleteGroup,
				addUserToGroup,
				removeUserFromGroup,
				userIsAdminOfGroup,
			}}
		>
			{children}
		</GroupsContext.Provider>
	)
}
