import {
	ListItem,
	ListItemButton,
	ListItemText,
	ListItemAvatar,
	Avatar,
	Divider,
	Typography,
} from '@mui/material'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import { Activity } from 'lib/supabase'
import { DateTime } from 'luxon'
import { useUserContext } from 'contexts/UserContext'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

type Props = {
	activity: Activity
	latestDate: string
}
const MessageBox: React.FC<Props> = ({ activity, latestDate }) => {
	const navigate = useNavigate()

	const handleNavigateToChat = () => {
		navigate(`/chat/${activity.id}`)
	}

	return (
		<div>
			<ListItem disableGutters>
				<ListItemButton
					onClick={handleNavigateToChat}
					sx={{
						fontSize: '1.2rem',
						color: '#1565C0',
						fontWeight: 'bold',
					}}
				>
					<ListItemAvatar>
						<Avatar alt='Joyner'>
							<ForumOutlinedIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primaryTypographyProps={{ fontWeight: 'bold' }}
						primary={activity.name}
						secondary={
							<Typography
								fontSize={'0.7rem'}
								color={'#666666'}
								fontStyle={'italic'}
							>
								{latestDate
									? 'Latest message posted ' +
									  DateTime.fromISO(latestDate)
											.toFormat('ccc, LLL d')
											.toLocaleString() +
									  ' at ' +
									  DateTime.fromISO(latestDate)
											.toFormat('h:mm a')
											.toLocaleString()
									: 'No messages yet'}
							</Typography>
						}
					/>
				</ListItemButton>
			</ListItem>
			<Divider />
		</div>
	)
}

export default MessageBox
