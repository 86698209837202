import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import AppHeader from '../components/AppHeader'
import { useUserContext } from '../contexts/UserContext'
import {
	Box,
	IconButton,
	Stack,
	Typography,
	Skeleton,
	LinearProgress,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Profile } from '../lib/supabase'

export default function Support() {
	const navigate = useNavigate()
	const goBack = () => {
		navigate('/')
	}
	const { profile } = useUserContext()

	const getSupportUrl = (profile: Profile) => {
		return (
			'https://airtable.com/embed/appV1wlnS9BGi44bT/pagwjekNetCHODW0V/form?prefill_SenderEmail=' +
			profile?.email +
			'&prefill_SenderName=' +
			profile?.full_name +
			'&prefill_SenderID=' +
			profile?.id +
			'&hide_SenderID=true'
		)
	}

	const [loading, setLoading] = useState(true)
	const hideLoading = () => {
		setLoading(false)
	}

	return (
		<Box>
			<AppHeader
				leftComponent={
					<div style={{ textAlign: 'center', width: '50px' }}>
						<IconButton
							size='large'
							edge='start'
							sx={{ color: 'white' }}
							onClick={goBack}
						>
							<ArrowBackIcon />
						</IconButton>
					</div>
				}
				title='Support'
			/>

			<div
				style={{
					display: loading ? 'flex' : 'none',
					justifyContent: 'center',
					paddingTop: '60px',
				}}
			>
				<Stack direction='column' spacing={1}>
					<LinearProgress />
					<Typography fontStyle='italic' fontWeight='500'>
						Loading Joyner Support...
					</Typography>
					<Skeleton variant='text' sx={{ fontSize: '3.0rem' }} />
					<Skeleton variant='text' sx={{ fontSize: '1.0rem' }} />
					<Skeleton variant='rounded' width={210} height={60} />
					<Skeleton variant='text' sx={{ fontSize: '1.0rem' }} />
					<Skeleton variant='rounded' width={210} height={60} />
					<Skeleton variant='circular' width={10} height={10} />
					<Skeleton variant='circular' width={10} height={10} />
					<Skeleton variant='circular' width={10} height={10} />
				</Stack>
			</div>
			<iframe
				src={getSupportUrl(profile!)}
				width='100%'
				height='1000px'
				onLoad={hideLoading}
				style={{ background: 'transparent', border: '0px solid #ccc' }}
			/>
		</Box>
	)
}
