import React, { useCallback, useEffect, useState } from 'react'
import { Button, IconButton, Typography } from '@mui/material'
import {
	ThumbUpOffAlt,
	ThumbDownOffAlt,
	Forum,
	ForumOutlined,
} from '@mui/icons-material'
import { DateTime } from 'luxon'
import type { Activity } from '../../../lib/supabase'
import { useGetVenue } from '../../../api/venue'
import Attending from '../../../components/Attending'
import { useUserContext } from 'contexts/UserContext'

type Props = {
	activity: Activity
	buttonsDisabled: boolean
	chatDisabled: boolean
	introSteps: boolean
	onGoing: () => Promise<void>
	onNotGoing: () => Promise<void>
	onMoreInfo: () => void
	onNavigateToChat: () => void
}

const ActivityBox: React.FC<Props> = (props) => {
	const { profile } = useUserContext()
	const [userId, setUserId] = useState<string>()

	useEffect(() => {
		if (profile) {
			setUserId(profile.id)
		}
	}, [profile])

	const venue = useGetVenue({
		venueId: props.activity.venue_id,
	})
	const [activityDateTime] = useState(
		DateTime.fromISO(props.activity.datetime)
	)

	const isActivityFull = useCallback(() => {
		if (userId) {
			return (
				props.activity.outs.includes(userId) &&
				props.activity.ins.length === props.activity.max_spots
			)
		}
	}, [props.activity.ins, props.activity.max_spots])

	const isActivityExpired = useCallback(() => {
		return activityDateTime.diff(DateTime.now()).milliseconds < 0
	}, [props.activity.datetime])

	const isDisabled =
		props.buttonsDisabled ||
		isActivityFull() ||
		isActivityExpired() ||
		!props.activity.active

	return (
		<div
			style={{
				borderRadius: '5px',
				background: '#f7f7f7',
				margin: '0.6rem 0.6rem 0.0rem 0.6rem',
				overflow: 'hidden',
			}}
		>
			<div
				style={{
					textAlign: 'left',
					margin: '0.0rem 0.6rem 0.0rem 0.6rem',
					overflow: 'hidden',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<div>
						<Button
							style={{
								textTransform: 'none',
								fontSize: '1.2rem',
								fontWeight: 'bold',
								paddingLeft: '0px',
							}}
							variant='text'
							// startIcon={<MoreVertOutlined sx={{}}/>}
							onClick={props.onMoreInfo}
							data-intro={
								props.introSteps
									? 'Tap the activity title to view more details about the activity.'
									: undefined
							}
						>
							{props.activity.name}
						</Button>
					</div>
					<div style={{ width: '40px' }}>
						<IconButton
							className='moreInfoIcon'
							style={{
								paddingTop: '0.8rem',
								color: '#1565C0',
							}}
							onClick={props.onNavigateToChat}
						>
							<ForumOutlined />
						</IconButton>
					</div>
				</div>

				<Typography fontSize={'0.9rem'}>
					{activityDateTime.toFormat('h:mm a ZZZZ').toLocaleString()}
					&nbsp;&ndash;&nbsp;
					{venue.isSuccess && venue.data.name}
				</Typography>

				<Typography
					color={'#333333'}
					fontStyle={'italic'}
					fontSize={'0.8rem'}
					style={{
						marginTop: '0.2rem',
						marginRight: '0.6rem',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{props.activity.note}
				</Typography>
			</div>

			<div
				style={{
					paddingTop: '1.2rem',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					marginTop: '5px',
					marginBottom: '5px',
				}}
				data-intro={
					props.introSteps
						? 'Tap Thumb Up if you can make the activity, tap Thumb Down if you cannot.'
						: undefined
				}
			>
				<Button
					variant={
						props.activity.ins.includes(userId || '')
							? 'contained'
							: 'outlined'
					}
					color={
						props.activity.ins.includes(userId || '')
							? 'success'
							: 'primary'
					}
					disabled={isDisabled}
					onClick={props.onGoing}
					style={{
						margin: '0 0.5rem',
					}}
				>
					<ThumbUpOffAlt />
				</Button>
				<div style={{ marginLeft: '10px', marginRight: '10px' }}>
					&nbsp;
				</div>
				<Button
					variant={
						props.activity.outs.includes(userId || '')
							? 'contained'
							: 'outlined'
					}
					color={
						props.activity.outs.includes(userId || '')
							? 'warning'
							: 'primary'
					}
					disabled={isDisabled}
					onClick={props.onNotGoing}
				>
					<ThumbDownOffAlt />
				</Button>
			</div>

			<div style={{ marginBottom: '5px' }}>
				<Attending activity={props.activity} />
			</div>
		</div>
	)
}

export default ActivityBox
