import React from 'react'
import CreateAccountForm, {
	CreateAccountFormValues,
} from 'components/forms/prebuilt/CreateAccountForm'
import { useAuthContext } from 'contexts/AuthContext'
import { AuthApiError } from '@supabase/supabase-js'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { sendNotification } from '../../vercel/apicalls'

type Props = {
	style?: React.CSSProperties
}
const CreateAccount: React.FC<Props> = ({ style }) => {
	const { signUpWithPhone } = useAuthContext()
	const { showSnackbar } = useSnackbarContext()

	const handleCreateAccount = async (values: CreateAccountFormValues) => {
		try {
			await signUpWithPhone(
				values.phone,
				values.password,
				values.fullName,
				{
					email: values.email,
				}
			).then(() => {
				// send welcome email to new user
				let furl =
					import.meta.env.VITE_VERCELAPI_HOST + 'email/user-welcome'
				furl += '?un=' + encodeURIComponent(values.fullName)
				furl += '&ue=' + encodeURIComponent(values.email)
				sendNotification(furl)

				// post slack message about new user sign up
				let surl =
					import.meta.env.VITE_VERCELAPI_HOST + 'slack/notify-newuser'
				surl += '?un=' + encodeURIComponent(values.fullName)
				sendNotification(surl)
			})
		} catch (err) {
			if (err instanceof AuthApiError) {
				if (err.message === 'User already registered') {
					showSnackbar(
						'Phone number already registered, do you already have an account?',
						'error'
					)
				}
			} else {
				showSnackbar('Unable to create account', 'error')
			}
		}
	}

	return (
		<>
			<CreateAccountForm
				onSubmit={handleCreateAccount}
				style={{ ...style }}
			/>
		</>
	)
}

export default CreateAccount
