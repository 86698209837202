import queryClient from '../../lib/reactQuery'
import supabase, { TABLE_PROFILES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { Profile } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'
import { getQueryKey } from './get'

type UpdateProfileArgs = {
	profileId: string
	profile: Partial<Omit<Profile, 'id'>>
}
export const updateProfile = async ({
	profileId,
	profile,
}: UpdateProfileArgs) => {
	const { data, error } = await supabase
		.from(TABLE_PROFILES)
		.update(profile)
		.eq('id', profileId)
		.select()
		.single()

	if (error) throw error

	return data
}

type UseUpdateProfileOptions = {
	options?: MutationConfig<typeof updateProfile>
}
export const useUpdateProfile = ({ options }: UseUpdateProfileOptions = {}) => {
	return useMutation({
		onMutate: (variables) => {
			const previousProfile = queryClient.getQueryData(
				getQueryKey(variables.profileId)
			)
			// optimistically update the cache
			queryClient.setQueryData(
				getQueryKey(variables.profileId),
				variables.profile
			)
			// for reverting the optimistic update
			return { previousProfile }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (error, variables, context: any) => {
			if (context) {
				// revert the cache back to the previous value
				queryClient.setQueryData(
					getQueryKey(context.previousProfile.id),
					context.previousProfile
				)
			}
		},
		onSuccess: (profile) => {
			// fetch the latest version in the background
			queryClient.invalidateQueries({
				queryKey: getQueryKey(profile.id),
			})
		},
		...options,
		mutationFn: updateProfile,
	})
}
