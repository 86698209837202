import React from 'react'
import { AlertColor } from '@mui/material'
import SnackbarAlert from 'components/SnackbarAlert'

type SnackbarContextType = {
	showSnackbar: (message: string, status: AlertColor) => void
}

export type SnackbarMessage = {
	key: string
	message: string
	status: AlertColor
}

const SnackbarContext = React.createContext<SnackbarContextType | undefined>(
	undefined
)

export const useSnackbarContext = () => {
	const context = React.useContext(SnackbarContext)
	if (!context) {
		throw new Error('No snackbar context provider')
	}

	return context
}

export const SnackbarContextProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const [snackPack, setSnackPack] = React.useState<
		readonly SnackbarMessage[]
	>([])
	const [open, setOpen] = React.useState(false)
	const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage>()

	React.useEffect(() => {
		if (snackPack.length && !messageInfo) {
			// Set a new snack when we don't have an active one
			setMessageInfo({ ...snackPack[0] })
			setSnackPack((prev) => prev.slice(1))
			setOpen(true)
		} else if (snackPack.length && messageInfo && open) {
			// Close an active snack when a new one is added
			setOpen(false)
		}
	}, [snackPack, messageInfo, open])

	const handleExited = () => {
		setMessageInfo(undefined)
	}

	const showSnackbar = (message: string, status: AlertColor) => {
		setSnackPack((prev) => [
			...prev,
			{ key: new Date().toISOString(), message, status },
		])
	}

	return (
		<SnackbarContext.Provider value={{ showSnackbar }}>
			{children}
			<SnackbarAlert
				keyProp={messageInfo ? messageInfo.key : undefined}
				open={open}
				message={messageInfo ? messageInfo.message : undefined}
				status={messageInfo ? messageInfo.status : undefined}
				onClose={() => setOpen(false)}
				onExited={handleExited}
			/>
		</SnackbarContext.Provider>
	)
}
