import React from 'react'
import { Controller, type Control } from 'react-hook-form'
import TextField, { type TextFieldProps } from '@mui/material/TextField'
import { Button, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

type Props = TextFieldProps & {
	name: string
	label: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any, any>
}
const FormInputNumber: React.FC<Props> = ({
	name,
	control,
	label,
	...textFieldProps
}) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<TextField
						variant='outlined'
						{...(textFieldProps || {})}
						error={!!error}
						helperText={error ? error.message : null}
						label={label}
						autoComplete='off'
						{...field}
						sx={{ width: '120px' }}
					/>
					<Button
						size='large'
						variant='contained'
						disabled={field.value == 1000}
						style={{ margin: '0.4rem 0.0rem 0.4rem 0.4rem' }}
						onClick={() => field.onChange(field.value + 1)}
					>
						<AddCircleIcon />
					</Button>
					<Button
						size='large'
						variant='contained'
						disabled={field.value == 0}
						style={{ margin: '0.4rem 0.0rem 0.4rem 0.4rem' }}
						onClick={() => field.onChange(field.value - 1)}
					>
						<RemoveCircleIcon />
					</Button>
					<Typography
						fontSize={'0.8rem'}
						paddingLeft={'0.6rem'}
						paddingTop={'0.6rem'}
						fontStyle={'italic'}
					>
						Set to zero if not applicable
					</Typography>
				</div>
			)}
		/>
	)
}

export default FormInputNumber
