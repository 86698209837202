import React from 'react'
import { Typography, Button, Box } from '@mui/material'
import ActivityForm, {
	ActivityFormValues,
} from 'components/forms/prebuilt/ActivityForm'
import { Activity, Venue } from 'lib/supabase'
import { useCreateVenue, useGetManyByGroupId } from 'api/venue'
import { useUserContext } from 'contexts/UserContext'
import ErrorComponent from 'components/ErrorComponent'
import Loading from 'components/Loading'
import ActionComplete from 'components/ActionComplete'
import { useCreateActivity } from 'api/activity'
import { DateTime } from 'luxon'
import { v4 as uuidV4 } from 'uuid'
import { DEFAULT_ERROR_MESSAGE, combineDateAndTime } from 'utils'
import { useSnackbarContext } from 'contexts/SnackbarContext'

const FORM_ID = 'create-activity-form'

const CreateActivity: React.FC = () => {
	const { profile } = useUserContext()
	const { showSnackbar } = useSnackbarContext()
	const venues = useGetManyByGroupId({
		groupId: profile?.active_group || '',
		options: { enabled: !!profile?.active_group },
	})
	const createVenue = useCreateVenue()
	const createActivity = useCreateActivity()
	const [activityCreated, setActivityCreated] = React.useState(false)

	if (venues.isError) {
		console.error(venues.error)
	}

	const handleSubmit = async (data: ActivityFormValues) => {
		if (!profile?.active_group) return

		try {
			const datetime = combineDateAndTime(data.date, data.time)
			const newActivity: Activity = {
				id: uuidV4(),
				group_id: profile.active_group,
				name: data.name,
				venue_id: data.venue,
				datetime,
				note: data.note || null,
				link: data.url || null,
				max_spots: data.maxSpots,
				published: true,
				active: true,
				ins: [],
				outs: [],
				created_at: DateTime.now().toISO(),
				updated_at: null,
				activity_owner_id: profile.id,
			}

			await createActivity.mutateAsync({ activity: newActivity })
			setActivityCreated(true)
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}

			setActivityCreated(false)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
		}
	}

	const handleQuickAddVenue = async (venue: Venue) => {
		await createVenue.mutateAsync({ venue })
		return venue
	}

	return (
		<Box>
			{venues.isSuccess ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '20px 40px',
					}}
				>
					<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
						Create Your First Activity
					</Typography>
					<Typography paddingBottom={2}>(Optional)</Typography>
					<Typography paddingBottom={1}>
						You successfully created your first group.
					</Typography>
					<Typography paddingBottom={1}>
						Now create your first group activity!
					</Typography>
					<Typography>
						If you skip this optional step, you can create
						activities by tapping the admin button in the bottom
						nav.
					</Typography>
					{activityCreated ? (
						<ActionComplete message='Activity Created!' />
					) : (
						<>
							<ActivityForm
								id={FORM_ID}
								venues={venues.data}
								defaultValues={{
									name: '',
									venue: '',
									date: DateTime.now()
										.startOf('hour')
										.toISO(),
									time: DateTime.now()
										.startOf('hour')
										.plus({ hours: 1 })
										.toISO(),
									note: '',
									url: '',
									maxSpots: 0,
								}}
								hideNoteField={true}
								hideLinkField={true}
								onSubmit={handleSubmit}
								onQuickAddVenue={handleQuickAddVenue}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'end',
									width: '100%',
								}}
							>
								<Button
									variant='contained'
									color='primary'
									type='submit'
									form={FORM_ID}
								>
									Create Activity
								</Button>
							</div>
						</>
					)}
				</div>
			) : venues.isError ? (
				<ErrorComponent />
			) : (
				<Loading />
			)}
		</Box>
	)
}

export default CreateActivity
