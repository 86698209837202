import React from 'react'
import { CircularProgress } from '@mui/material'

type Props = {
  message?: string
}

export default function Loading(props: Props) {
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '1rem',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {props.message && props.message}
      <CircularProgress
        style={{
          padding: '1rem',
        }}
      />
    </div>
  )
}
