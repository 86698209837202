import { Button } from '@mui/material'
import { useGroupsContext } from 'contexts/GroupsContext'
import React from 'react'

type Props = {
	groupId: string
	onClick: () => void

	buttonText?: string
}
const ProtectedEditButton: React.FC<Props> = ({
	groupId,
	buttonText,
	onClick,
}) => {
	const { userIsAdminOfGroup } = useGroupsContext()
	return (
		<>
			{userIsAdminOfGroup(groupId) && (
				<Button sx={{ color: 'white' }} onClick={onClick}>
					{buttonText || 'Edit'}
				</Button>
			)}
		</>
	)
}

export default ProtectedEditButton
