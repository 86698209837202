import React, { useState } from 'react'
import { Box } from '@mui/material'
import EditProfile from './EditProfile'
import ViewProfile from './ViewProfile'
import type { Profile } from 'lib/supabase'
import { useUserContext } from 'contexts/UserContext'
import Loading from 'components/Loading'
import { useGetProfile, useUpdateProfile } from 'api/profile'
import { useNavigate } from 'react-router'

const Profile: React.FC = () => {
	const [editing, setEditing] = useState(false)
	const { user } = useUserContext()
	const profile = useGetProfile({
		profileId: user!.id,
		options: { enabled: !!user },
	})
	const updateProfile = useUpdateProfile()
	const navigate = useNavigate()

	const handleEdit = () => {
		setEditing(true)
	}

	const handleCancel = () => {
		setEditing(false)
	}

	const handleSave = (newProfile: Partial<Omit<Profile, 'id'>>) => {
		if (profile.isSuccess) {
			updateProfile.mutate({
				profileId: profile.data.id,
				profile: newProfile,
			})
		} else {
			console.warn('Profile is not loaded yet')
		}
		setEditing(false)
	}

	return (
		<Box component={'main'}>
			{profile.isSuccess ? (
				<>
					{editing ? (
						<EditProfile
							profile={profile.data}
							onSave={handleSave}
							onCancel={handleCancel}
						/>
					) : (
						<ViewProfile
							profile={profile.data}
							onBack={() => navigate(-1)}
							onEdit={handleEdit}
						/>
					)}
				</>
			) : (
				<Loading />
			)}
		</Box>
	)
}

export default Profile
