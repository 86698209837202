import queryClient from '../../lib/reactQuery'
import { useMutation } from '@tanstack/react-query'
import { TABLE_GUESTS } from 'lib/supabase'
import { createGeneric } from '../generic'

import type { MutationConfig } from '../../lib/reactQuery'
import type { Guest } from '../../lib/supabase'

type CreateGuestArgs = {
	guest: Guest
}
export const createGuest = async ({ guest }: CreateGuestArgs) =>
	createGeneric({ tableName: TABLE_GUESTS, data: guest })

type UseCreateGuestOptions = {
	options?: MutationConfig<typeof createGuest>
}
export const useCreateGuest = ({ options }: UseCreateGuestOptions = {}) => {
	return useMutation({
		onSuccess: (guest) => {
			queryClient.setQueryData(['guest', guest.id], guest)
			queryClient.invalidateQueries({ queryKey: ['guests'] })
		},
		...options,
		mutationFn: createGuest,
	})
}
