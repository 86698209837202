import React from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material'

interface Props {
	isOpen: boolean
	title: string
	content: string
	confirmButtonText: string
	onClose: () => void
	onConfirm: () => void
}

const ConfirmationDialog = ({
	isOpen,
	title,
	content,
	confirmButtonText,
	onClose,
	onConfirm,
}: Props) => {
	return (
		<Dialog fullWidth open={isOpen}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button variant='contained' color='error' onClick={onConfirm}>
					{confirmButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmationDialog
