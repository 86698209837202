import { updateGeneric } from '../generic'
import { TABLE_POLL_CHOICES } from 'lib/supabase'
import queryClient from '../../lib/reactQuery'
import { useMutation } from '@tanstack/react-query'

import type { ToUpdate } from '../generic'
import type { PollChoice } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type ChoiceToUpdate = ToUpdate<PollChoice>

type UpdateChoiceArgs = {
	choice: ChoiceToUpdate
}
export const updateChoice = async ({ choice }: UpdateChoiceArgs) =>
	updateGeneric({ tableName: TABLE_POLL_CHOICES, data: choice })

type UseUpdateChoiceOptions = {
	options?: MutationConfig<typeof updateChoice>
}
export const useUpdateChoice = ({ options }: UseUpdateChoiceOptions = {}) => {
	return useMutation({
		onSuccess: (choice) => {
			queryClient.setQueryData(['choice', choice.id], choice)
		},
		...options,
		mutationFn: updateChoice,
	})
}
