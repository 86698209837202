import React, { useState, useEffect } from 'react'
import { SupportedRanges } from 'api/activity/getInRange'
import { DateTime } from 'luxon'
import { Poll, Message } from '../lib/supabase'
import { useUserContext } from './UserContext'
import { useGetManyByGroupId as useGetPollsByGroupId } from 'api/polls'
import { useGetManyByGroupId as useGetMessagesByGroupId } from 'api/messages'

type DashboardInfoContextType = {
	rangeDate: DateTime
	range: SupportedRanges
	setRangeDate: (date: DateTime) => void
	setRange: (range: SupportedRanges) => void
	showPollsBadge: boolean
	showMessagesBadge: boolean
}

const DashboardInfoContext = React.createContext<DashboardInfoContextType>({
	rangeDate: DateTime.now(),
	range: 'week',
	setRangeDate: () => {},
	setRange: () => {},
	showPollsBadge: false,
	showMessagesBadge: false,
})

export const useDashboardInfo = () => React.useContext(DashboardInfoContext)

export const DashboardInfoProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const [rangeDate, setRangeDate] = useState<DateTime>(DateTime.now())
	const [range, setRange] = useState<SupportedRanges>('week')
	const { profile, prevLastAccess, activeGroup } = useUserContext()

	const [showPollsBadge, setShowPollBadge] = useState(false)
	const [showMessagesBadge, setShowMessageBadge] = useState(false)

	const { data: p } = useGetPollsByGroupId({
		groupId: activeGroup?.id,
		options: { enabled: !!activeGroup },
	})

	const { data: m } = useGetMessagesByGroupId({
		groupId: activeGroup?.id,
		options: { enabled: !!activeGroup },
	})

	// *** QUERY functions return data in date descending order
	// just need to grab the first element's created_at timestamp
	useEffect(() => {
		if (
			Array.isArray(p) &&
			p.length > 0 &&
			Array.isArray(m) &&
			m.length > 0
		) {
			setAlerts(p[0], m[0])
		}
	}, [p, m])

	// set alerts for polls and messages
	// don't show badge if state is changed by current user
	const setAlerts = (latestPoll: Poll, latestMessage: Message) => {
		if (
			latestPoll &&
			prevLastAccess &&
			latestPoll.poll_owner_id !== profile?.id
		) {
			DateTime.fromISO(prevLastAccess) <
			DateTime.fromISO(latestPoll.created_at)
				? setShowPollBadge(true)
				: setShowPollBadge(false)
		}

		if (
			latestMessage &&
			prevLastAccess &&
			latestMessage.sender !== profile?.id
		) {
			DateTime.fromISO(prevLastAccess) <
			DateTime.fromISO(latestMessage.created_at)
				? setShowMessageBadge(true)
				: setShowMessageBadge(false)
		}
	}

	return (
		<DashboardInfoContext.Provider
			value={{
				rangeDate,
				range,
				setRangeDate,
				setRange,
				showPollsBadge,
				showMessagesBadge,
			}}
		>
			{children}
		</DashboardInfoContext.Provider>
	)
}
