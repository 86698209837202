import React from 'react'
import { useNavigate } from 'react-router'
import AppHeader from '../components/AppHeader'
import { useUserContext } from '../contexts/UserContext'
import { Box, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Profile } from '../lib/supabase'

export default function Purchase() {
	const navigate = useNavigate()
	const goBack = () => {
		navigate('/')
	}
	const { profile } = useUserContext()

  const getStripePurchaseURL = (profile: Profile) => {
    return (
    'https://buy.stripe.com/test_5kA7uXg2wbiZbTO9AA?prefilled_email=' +
    profile?.email
    )
  }

  return (
    <Box>
			<AppHeader
				leftComponent={
					<div style={{ textAlign: 'center', width: '50px' }}>
						<IconButton
							size="large"
							edge="start"
							sx={{ color: 'white' }}
							onClick={goBack}
						>
							<ArrowBackIcon />
						</IconButton>
					</div>
				}
				title="Purchase"
			/>    

      <iframe 
        src={getStripePurchaseURL(profile!)} 
        width="100%" 
        height="1000px"
        style={{background: 'transparent', border: '0px solid #ccc'}}
      />

    </Box>
  )
}