import React from 'react'
import {
	FormControl,
	InputLabel,
	Select,
	type SelectProps,
} from '@mui/material'
import { Controller, type Control } from 'react-hook-form'

type Props = SelectProps & {
	name: string
	label: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any, any>
}
const FormInputSelect: React.FC<Props> = ({
	name,
	label,
	control,
	children,
	...selectProps
}) => {
	const labelId = `select-${name}`
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<FormControl fullWidth disabled={selectProps.disabled}>
					<InputLabel id={labelId} required={selectProps.required}>
						{label}
					</InputLabel>
					<Select
						variant='outlined'
						fullWidth
						{...(selectProps || {})}
						labelId={labelId}
						error={!!error}
						label={label}
						{...field}
					>
						{children}
					</Select>
				</FormControl>
			)}
		/>
	)
}

export default FormInputSelect
