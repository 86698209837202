export const REFRESH_INTERVAL = 5 * 60 * 1000 // 5 minutes
export const version = '515.230828' // TODO remove this, we should be using the package.json version
export const INTL_PHONE_NUMBER_LENGTH = 11
export const PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
export const GROUP_TIMESTAMP_COOKIE_KEY = 'joyner-groupTimestamps'
export const FTU_DIALOG_COOKIE_KEY = 'joyner-ftuDialog'
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong.'

export type voteController = {
	choice_id: string
	choice: string | null
	checked: boolean
}
