import React from 'react'
import { Controller, type Control } from 'react-hook-form'
import TextField, { type TextFieldProps } from '@mui/material/TextField'

type Props = TextFieldProps & {
	name: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any, any>
	showErrorText?: boolean
}
const FormInputText: React.FC<Props> = ({
	name,
	control,
	showErrorText = true,
	...textFieldProps
}) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<TextField
					variant='outlined'
					fullWidth
					autoComplete='off'
					autoCorrect='off'
					autoCapitalize='off'
					spellCheck='false'
					{...(textFieldProps || {})}
					error={!!error || textFieldProps.error}
					helperText={
						error && showErrorText
							? error.message
							: textFieldProps.helperText
					}
					{...field}
					disabled={textFieldProps.disabled}
				/>
			)}
		/>
	)
}

export default FormInputText
