import React from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material'

interface Props {
	isOpen: boolean
	title: string
	content: string
	noButtonText: string
	yesButtonText: string
	onAnswer: (answer: boolean) => void
}

const BinaryDialog = ({
	isOpen,
	title,
	content,
	noButtonText,
	yesButtonText,
	onAnswer,
}: Props) => {
	return (
		<Dialog fullWidth open={isOpen}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant='outlined' onClick={() => onAnswer(false)}>
					{noButtonText}
				</Button>
				<Button variant='contained' onClick={() => onAnswer(true)}>
					{yesButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default BinaryDialog
