import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Validators } from 'utils/validators'
import { object } from 'yup'
import { FormInputText } from '../elements'
import { Button, Stack, Autocomplete, TextField } from '@mui/material'
import timezones from 'utils/timezones'
import { DateTime } from 'luxon'

export type GroupFormValues = {
	name: string
	activityName: string
	defaultTimezone: string
}

type Props = {
	defaultValues?: GroupFormValues
	disabled?: boolean
	onSubmit: (data: GroupFormValues) => void
	onCancel?: () => void
}
const GroupForm: React.FC<Props> = ({
	defaultValues,
	disabled,
	onSubmit,
	onCancel,
}) => {
	const [defaultTimezone, setDefaultTimezone] = useState(
		DateTime.local().zoneName || null
	)

	const { handleSubmit, control } = useForm<GroupFormValues>({
		resolver: yupResolver(
			object().shape({
				name: Validators.NAME.required(),
				activityName: Validators.ACTIVITY_NAME.required(),
				defaultTimezone: Validators.DEFAULT_TIMEZONE.required(),
			})
		),
		defaultValues: defaultValues || {
			name: '',
			activityName: '',
			defaultTimezone: DateTime.local().zoneName || '',
		},
	})

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={2}>
				<FormInputText
					label='Group Name'
					name='name'
					variant='filled'
					control={control}
					required
					disabled={disabled}
				/>
				<FormInputText
					label='Activity Name'
					name='activityName'
					variant='filled'
					control={control}
					required
					disabled={disabled}
				/>
				<Autocomplete
					disablePortal
					options={timezones}
					disabled
					onChange={(
						event: React.SyntheticEvent,
						newValue: string | null
					) => {
						setDefaultTimezone(newValue)
					}}
					value={defaultTimezone || DateTime.local().zoneName || null}
					renderInput={(params) => (
						<TextField
							{...params}
							name='defaultTimezone'
							label='Timezone'
							variant='filled'
							fullWidth
						/>
					)}
				/>
			</Stack>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'end',
					gap: '1.0rem',
					marginTop: '2.0rem',
				}}
			>
				<Button
					sx={{ visibility: onCancel ? 'visible' : 'hidden' }}
					onClick={onCancel}
					disabled={disabled}
				>
					Cancel
				</Button>
				<Button disabled={disabled} variant='contained' type='submit'>
					Save
				</Button>
			</div>
		</form>
	)
}

export default GroupForm
