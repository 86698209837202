import React from 'react'
import { Button, AppBar } from '@mui/material'
import AppHeader from 'components/AppHeader'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { Poll, PollChoice } from 'lib/supabase'
import PollForm, {
	PollFormValues,
} from '../../components/forms/prebuilt/PollForm'

type Props = {
	poll: Poll
	choices: PollChoice[]
	onSave: (newPoll: Partial<Poll>, newChoices: PollChoice[]) => void
	onCancel: () => void
	onDelete: (pollId: string) => void
}

const EditPoll: React.FC<Props> = ({
	poll,
	choices,
	onSave,
	onCancel,
	onDelete,
}) => {
	const submitForm = (data: PollFormValues) => {
		const newChoices = choices
		if (data.choice_1 !== undefined && data.choice_1 !== '') {
			newChoices[0].choice = data.choice_1
		} else {
			newChoices[0].choice = null
			newChoices[0].votes = []
		}
		if (data.choice_2 !== undefined && data.choice_2 !== '') {
			newChoices[1].choice = data.choice_2
		} else {
			newChoices[1].choice = null
			newChoices[1].votes = []
		}
		if (data.choice_3 !== undefined && data.choice_3 !== '') {
			newChoices[2].choice = data.choice_3
		} else {
			newChoices[2].choice = null
			newChoices[2].votes = []
		}
		if (data.choice_4 !== undefined && data.choice_4 !== '') {
			newChoices[3].choice = data.choice_4
		} else {
			newChoices[3].choice = null
			newChoices[3].votes = []
		}
		if (data.choice_5 !== undefined && data.choice_5 !== '') {
			newChoices[4].choice = data.choice_5
		} else {
			newChoices[4].choice = null
			newChoices[4].votes = []
		}
		if (data.choice_6 !== undefined && data.choice_6 !== '') {
			newChoices[5].choice = data.choice_6
		} else {
			newChoices[5].choice = null
			newChoices[5].votes = []
		}

		const newPoll: Partial<Poll> = {
			title: data.title,
			message: data.message,
			choose_multiple: data.choose_multiple,
		}

		onSave(newPoll, newChoices)
	}

	const [showConfirmDelete, setShowConfirmDelete] = React.useState(false)
	const [showConfirmClose, setShowConfirmClose] = React.useState(false)

	const handleClosePoll = async () => {
		console.log('close poll')
	}

	return (
		<div>
			<AppHeader
				leftComponent={<Button onClick={onCancel}>Cancel</Button>}
				title='Edit Poll'
				rightComponent={
					<Button type='submit' form='edit-form'>
						Save
					</Button>
				}
			/>
			<PollForm
				id='edit-form'
				defaultValues={{
					title: poll?.title || '',
					message: poll?.message || '',
					choose_multiple: poll?.choose_multiple,
					choice_1: choices[0].choice || '',
					choice_2: choices[1].choice || '',
					choice_3: choices[2].choice || '',
					choice_4: choices[3].choice || '',
					choice_5: choices[4].choice || '',
					choice_6: choices[5].choice || '',
				}}
				onSubmit={submitForm}
			/>
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '5px',
						alignItems: 'center',
						margin: '10px 40px',
					}}
				>
					{/* <Button
						variant='contained'
						fullWidth
						style={{ maxWidth: '300px' }}
						onClick={() => setShowConfirmClose(true)}
					>
						Close Poll
					</Button> */}
					<Button
						variant='outlined'
						color='error'
						fullWidth
						style={{ maxWidth: '300px' }}
						onClick={() => setShowConfirmDelete(true)}
					>
						Delete Poll
					</Button>
				</div>
			</AppBar>

			<ConfirmationDialog
				isOpen={showConfirmDelete}
				onClose={() => setShowConfirmDelete(false)}
				onConfirm={() => onDelete(poll.id)}
				title={'Confirm Delete Poll'}
				content={'Do you want to permanently delete this poll?'}
				confirmButtonText='Delete Poll'
			/>
			<ConfirmationDialog
				isOpen={showConfirmClose}
				onClose={() => setShowConfirmClose(false)}
				onConfirm={handleClosePoll}
				title={'Confirm Close Poll'}
				content={'Do you want to permanently close this poll?'}
				confirmButtonText='Close Poll'
			/>
		</div>
	)
}

export default EditPoll
