import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuthContext } from 'contexts/AuthContext'

const ProtectedRoute: React.FC = () => {
	// const { isValid } = useUserContext()
	const { isAuthenticated } = useAuthContext()

	return isAuthenticated ? (
		<Outlet />
	) : (
		<Navigate
			to='/login'
			replace
			state={{ resumePath: window.location.pathname }}
		/>
	)
}

export default ProtectedRoute
