import React from 'react'
import { Profile } from '../../lib/supabase'
import {
	ListItem,
	Button,
	ListItemText,
	Divider,
	Typography,
} from '@mui/material'

interface Props {
	member: Profile
	adminId: string | null
	isAdmin: boolean
	isCurrentUser: boolean
	onRemove: (profile: Profile) => void
	onLeave: (profile: Profile) => void
}

const GroupMemberItem: React.FC<Props> = (props) => {
	const getSecondaryAction = () => {
		if (props.member.id === props.adminId) {
			return (
				<Typography
					fontSize='0.9rem'
					color='text.secondary'
					fontWeight='500'
					paddingRight='0.2rem'
				>
					ADMIN
				</Typography>
			)
		}
		if (props.isCurrentUser) {
			return (
				<Button
					onClick={() => props.onLeave(props.member)}
					size='small'
					variant='contained'
					color='error'
				>
					LEAVE
				</Button>
			)
		}
		if (props.isAdmin) {
			return (
				<Button
					onClick={() => props.onRemove(props.member)}
					size='small'
					variant='outlined'
					color='error'
				>
					REMOVE
				</Button>
			)
		}
	}

	return (
		<>
			<ListItem secondaryAction={getSecondaryAction()}>
				<ListItemText>{props.member.full_name}</ListItemText>
			</ListItem>
			<Divider />
		</>
	)
}

export default GroupMemberItem
