import React from 'react'
import {
	ListItem,
	ListItemText,
	ListItemButton,
	ListItemAvatar,
	Avatar,
	Divider,
} from '@mui/material'
import PollOutlinedIcon from '@mui/icons-material/PollOutlined'
import { Poll } from 'lib/supabase'
import { useNavigate } from 'react-router'
import { DateTime } from 'luxon'

type Props = {
	poll: Poll
}

const PollsItem: React.FC<Props> = ({ poll }) => {
	const navigate = useNavigate()

	const handleListClick = () => {
		navigate(`/poll/${poll.id}`)
	}

	return (
		<div key={poll.id}>
			<ListItem key={poll.id} disableGutters>
				<ListItemButton
					onClick={handleListClick}
					sx={{
						fontSize: '1.2rem',
						color: '#1565C0',
						fontWeight: 'bold',
					}}
				>
					<ListItemAvatar>
						<Avatar alt='Joyner'>
							<PollOutlinedIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primaryTypographyProps={{
							fontWeight: 'bold',
						}}
						primary={poll.title}
						secondary={DateTime.fromISO(
							poll.created_at
						).toLocaleString(DateTime.DATE_HUGE)}
					/>
				</ListItemButton>
			</ListItem>
			<Divider />
		</div>
	)
}

export default PollsItem
