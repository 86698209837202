import {
	BottomNavigation,
	BottomNavigationAction,
	Paper,
	Badge,
} from '@mui/material'
import useRouteMatch from 'hooks/useRouteMatch'
import React from 'react'
import { Link } from 'react-router-dom'
import EventIcon from '@mui/icons-material/Event'
import PollOutlinedIcon from '@mui/icons-material/PollOutlined'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

type Props = {
	pollBadge: boolean
	messageBadge: boolean
}

const BottomNav: React.FC<Props> = ({ pollBadge, messageBadge }) => {
	const routeMatch = useRouteMatch(['/', '/polls', '/messages', '/admin'])
	const currentTab = routeMatch?.pattern?.path
	const [showPollBadge, setShowPollBadge] = React.useState(false)
	const [showMessageBadge, setShowMessageBadge] = React.useState(false)

	React.useEffect(() => {
		setShowPollBadge(pollBadge)
		setShowMessageBadge(messageBadge)
	}, [pollBadge, messageBadge])

	const toggleBadgeState = (badge: string) => {
		if (badge === 'poll') {
			setShowPollBadge(false)
		} else if (badge === 'message') {
			setShowMessageBadge(false)
		}
	}

	return (
		<Paper
			sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
			elevation={3}
		>
			<BottomNavigation
				style={{ paddingBottom: '20px' }}
				value={currentTab}
				data-intro="Navigate to other parts of the Joyner application using the bottom navbar.<br/><br/>You can return to this tour at any time by tapping 'Take Dashboard Tour' in the Joyner menu."
			>
				<BottomNavigationAction
					label='Activites'
					value='/'
					to='/'
					component={Link}
					icon={<EventIcon />}
				/>
				<BottomNavigationAction
					label='Polls'
					value='/polls'
					to='/polls'
					component={Link}
					onClick={() => toggleBadgeState('poll')}
					icon={
						<Badge
							color='error'
							variant='dot'
							invisible={!showPollBadge}
						>
							<PollOutlinedIcon />
						</Badge>
					}
				/>
				<BottomNavigationAction
					label='Messages'
					value='/messages'
					to='/messages'
					component={Link}
					onClick={() => toggleBadgeState('message')}
					icon={
						<Badge
							color='error'
							variant='dot'
							invisible={!showMessageBadge}
						>
							<ForumOutlinedIcon />
						</Badge>
					}
				/>
				<BottomNavigationAction
					label='Admin'
					value='/admin'
					to='/admin'
					component={Link}
					icon={<SettingsOutlinedIcon />}
				/>
			</BottomNavigation>
		</Paper>
	)
}

export default BottomNav
