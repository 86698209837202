import React from 'react'
import { Controller, type Control } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker, type DatePickerProps } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
// import Adapter from 'services/AdapterLuxonAdapter'

type Props = DatePickerProps<DateTime> & {
	name: string
	label: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any, any>
}

const FormInputDate: React.FC<Props> = ({
	name,
	label,
	control,
	...datePickerProps
}) => {
	return (
		<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='en-US'>
			<Controller
				name={name}
				control={control}
				render={({ field: { value, ...otherFields } }) => (
					<DatePicker
						{...(datePickerProps || {})}
						label={label}
						value={DateTime.fromISO(value)}
						{...otherFields}
						// onOpen={handleOpen}
					/>
				)}
			/>
		</LocalizationProvider>
	)
}

export default FormInputDate
