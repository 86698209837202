import React from 'react'
import { useNavigate } from 'react-router'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import Adapter from 'services/AdapterLuxonAdapter'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Button from '@mui/material/Button'

const NewCal: React.FC = () => {
	const navigate = useNavigate()
	const handleGoBack = () => navigate('/')

	const handleOpen = () => {
		setTimeout(function () {
			let fucker = ''
			fucker +=
				'<span class="MuiTypography-root MuiTypography-caption MuiDayCalendar-weekDayLabel css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel" role="columnheader" aria-label="Sunday">S</span>'
			fucker +=
				'<span class="MuiTypography-root MuiTypography-caption MuiDayCalendar-weekDayLabel css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel" role="columnheader" aria-label="Monday">M</span>'
			fucker +=
				'<span class="MuiTypography-root MuiTypography-caption MuiDayCalendar-weekDayLabel css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel" role="columnheader" aria-label="Tuesday">T</span>'
			fucker +=
				'<span class="MuiTypography-root MuiTypography-caption MuiDayCalendar-weekDayLabel css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel" role="columnheader" aria-label="Wednesday">W</span>'
			fucker +=
				'<span class="MuiTypography-root MuiTypography-caption MuiDayCalendar-weekDayLabel css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel" role="columnheader" aria-label="Thursday">T</span>'
			fucker +=
				'<span class="MuiTypography-root MuiTypography-caption MuiDayCalendar-weekDayLabel css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel" role="columnheader" aria-label="Friday">F</span>'
			fucker +=
				'<span class="MuiTypography-root MuiTypography-caption MuiDayCalendar-weekDayLabel css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel" role="columnheader" aria-label="Saturday">S</span>'

			// console.log(fucker)
			const dowheader = document.getElementsByClassName(
				'MuiDayCalendar-header'
			)
			console.log('DOWHEADER: ' + dowheader.length)
			dowheader[0].innerHTML = fucker
		}, 100)
	}

	return (
		<div>
			<div style={{ marginTop: '80px' }}></div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<div style={{ padding: '10px' }}>DayJS Adapter</div>
				<div>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale='en'
					>
						<DatePicker />
					</LocalizationProvider>
				</div>
			</div>

			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: '20px',
					}}
				>
					<div style={{ padding: '10px' }}>Luxon Adapter</div>
					<LocalizationProvider
						dateAdapter={AdapterLuxon}
						adapterLocale='en-US'
					>
						<DatePicker />
					</LocalizationProvider>
				</div>
			</div>

			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: '20px',
					}}
				>
					<div style={{ padding: '10px' }}>
						Luxon Adapter Workaround
					</div>
					<LocalizationProvider
						dateAdapter={Adapter}
						adapterLocale='en-US'
					>
						<DatePicker onOpen={handleOpen} />
					</LocalizationProvider>
				</div>
			</div>

			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: '20px',
					}}
				>
					<div>
						<Button variant='contained' onClick={handleGoBack}>
							Back
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewCal
