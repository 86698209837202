
import { updateGeneric } from "../generic"
import { TABLE_VENUES } from "lib/supabase"
import queryClient from "../../lib/reactQuery"
import { useMutation } from "@tanstack/react-query"

import type { ToUpdate } from "../generic"
import type { Venue } from "../../lib/supabase"
import type { MutationConfig } from "../../lib/reactQuery"

type VenueToUpdate = ToUpdate<Venue>

type UpdateVenueArgs = {
	venue: VenueToUpdate
}
export const updateVenue = async ({ venue }: UpdateVenueArgs) => updateGeneric({ tableName: TABLE_VENUES, data: venue })

type UseUpdateVenueOptions = {
	options?: MutationConfig<typeof updateVenue>
}
export const useUpdateVenue = ({ options }: UseUpdateVenueOptions = {}) => {
	return useMutation({
		onSuccess: (venue) => {
			queryClient.setQueryData(['venue', venue.id], venue)
		},
		...options,
		mutationFn: updateVenue
	})
}