import React, { useRef, useCallback, useEffect } from 'react'
import { Box, Button, IconButton, Stack, Typography, Icon } from '@mui/material'
import { useGetActivity } from 'api/activity'
import AppHeader from 'components/AppHeader'
import Loading from 'components/Loading'
import { useUserContext } from 'contexts/UserContext'
import useMessagesByActivity from 'hooks/useMessagesByActivity'
import { useNavigate, useParams } from 'react-router'
import { v4 } from 'uuid'
import Bubble from './Bubble'
import InputBox from './InputBox'
import { Message } from 'lib/supabase'
import ErrorComponent from 'components/ErrorComponent'
import { DateTime } from 'luxon'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ForumOutlined from '@mui/icons-material/ForumOutlined'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

// number of elements from the top to trigger the fetch of new messages
// 0 = last element
const FETCH_NEW_MESSAGES_THRESHOLD = 0
const DATE_SEPARATOR_THRESHOLD_HOURS = 1

const ChatPage: React.FC = () => {
	const { activityId } = useParams()
	const { profile } = useUserContext()
	const navigate = useNavigate()

	if (!activityId) {
		console.error('No activityId')
		return <ErrorComponent />
	}

	const messages = useMessagesByActivity({
		activityId: activityId,
		groupId: profile?.active_group || '',
	})
	const activity = useGetActivity({
		activityId: activityId,
	})

	// fetch more messages when the user scrolls to the top
	const intObserver = useRef<IntersectionObserver>()
	const lastPostRef = useCallback(
		(message: Element) => {
			if (messages.isFetchingNextPage) return

			if (intObserver.current) intObserver.current.disconnect()

			intObserver.current = new IntersectionObserver(
				(entries) => {
					if (
						entries[0].isIntersecting &&
						messages.hasNextPage &&
						!messages.isFetchingNextPage
					) {
						messages.fetchNextPage()
					}
				},
				{
					root: messagesContainerRef.current,
					rootMargin: '0px 0px 150px 0px',
				}
			)

			if (message) intObserver.current.observe(message)
		},
		[messages.isFetchingNextPage, messages.hasNextPage]
	)

	// out element for controlling the height of the chat
	const messagesContainerRef = useRef<Element>()
	// inner element for controlling scroll position
	const messagesRef = useRef<HTMLElement>()
	// reference to the current scroll position
	const scrollTopRef = useRef<number>(0.5)

	// reset the scroll position when new messages are fetched
	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			if (messagesContainerRef.current) {
				entries[0].target.scrollTo({ top: scrollTopRef.current })
			}
		})

		if (messagesRef.current) resizeObserver.observe(messagesRef.current)

		return () => {
			resizeObserver.disconnect()
		}
	}, [messagesRef.current])

	// keep a reference to the scroll position
	useEffect(() => {
		const handleScroll = () => {
			if (messagesRef.current) {
				scrollTopRef.current = messagesRef.current.scrollTop
			}
		}

		if (messagesRef.current) {
			messagesRef.current.addEventListener('scroll', handleScroll)
		}

		return () => {
			if (messagesRef.current)
				messagesRef.current.removeEventListener('scroll', handleScroll)
		}
	}, [messagesRef.current])

	// dynamically change the chat height based on the input box height
	const inputBoxRef = useRef<HTMLElement>()
	const [inputBoxHeight, setInputBoxHeight] = React.useState<number>(40)
	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			setInputBoxHeight(entries[0].contentRect.height)
		})
		if (inputBoxRef.current) resizeObserver.observe(inputBoxRef.current)

		return () => {
			resizeObserver.disconnect()
		}
	}, [inputBoxRef.current])

	// handle sending messages and scrolling back down to the bottom
	// when you send a message to the chat
	const handleSendMessage = async (messageBody: string) => {
		if (!profile) return

		const message: Message = {
			id: v4().toString(),
			activity_id: activityId,
			body: messageBody,
			created_at: new Date().toISOString(),
			sender: profile.id,
			group_id: profile.active_group,
		}

		await messages.sendMessage(message)

		// scrolls the chat to the bottom to show the new message
		if (messagesRef.current) {
			messagesRef.current.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
		}
	}

	// generate chat bubbles from paginated messages
	const content = messages.data?.pages.flatMap((page) => {
		return page.map((message, i) => {
			// this element triggers the fetch of new messages
			if (page.length - FETCH_NEW_MESSAGES_THRESHOLD === i + 1) {
				return (
					<Bubble
						ref={lastPostRef}
						key={message.id}
						message={message}
						userIsAuthor={profile?.id === message.sender}
					/>
				)
			}

			if (message.created_at)
				// all other bubbles are normal
				return (
					<Bubble
						key={message.id}
						message={message}
						userIsAuthor={profile?.id === message.sender}
					/>
				)
		})
	})

	// add date separators to messages with more than an hour of difference
	content?.forEach((element, i, elements) => {
		const currentMessageDate = DateTime.fromISO(
			element?.props.message?.created_at
		)
		const previousMessageDate = DateTime.fromISO(
			elements[i - 1]?.props.message?.created_at
		)

		if (
			previousMessageDate.diff(currentMessageDate, 'hours').hours >
			DATE_SEPARATOR_THRESHOLD_HOURS
		) {
			elements.splice(
				i,
				0,
				<Typography
					key={i}
					sx={{
						textAlign: 'center',
						paddingY: '1rem',
						fontSize: '0.8rem',
						color: 'grey.500',
					}}
				>
					{currentMessageDate.toLocaleString(DateTime.DATETIME_MED)}
				</Typography>
			)
		}
	})

	if (messages.isError) {
		console.error('Error fetching messages')
		return <ErrorComponent />
	}

	return (
		<>
			<AppHeader
				title={activity.data?.name || ''}
				leftComponent={
					<IconButton
						size='large'
						edge='start'
						sx={{ color: 'white' }}
						onClick={() => navigate(-1)}
					>
						<ArrowBackIcon />
					</IconButton>
				}
			/>

			{messages.isLoading ? (
				<Loading />
			) : (
				<>
					<Box
						ref={messagesContainerRef}
						component={'main'}
						sx={{
							overflowY: 'auto',
							height: `calc(100vh - 105px - ${
								inputBoxHeight + 15
							}px)`,
							boxSizing: 'border-box',
						}}
					>
						{content && content?.length > 0 ? (
							<Box
								ref={messagesRef}
								sx={{
									display: 'flex',
									flexDirection: 'column-reverse',
									height: '100%',
									overflowY: 'auto',
									overflowX: 'hidden',
									paddingBottom: '20px',
								}}
							>
								{content}
								<Stack
									direction={'row'}
									alignItems={'center'}
									justifyContent={'center'}
									height={-'3rem'}
								>
									{messages.isFetchingNextPage ? (
										<Loading />
									) : (
										<Typography
											textAlign={'center'}
											paddingY={'.5rem'}
											color={'grey.500'}
										>
											You&apos;ve reached the end of the
											chat.
										</Typography>
									)}
								</Stack>
							</Box>
						) : (
							<Box>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										margin: '100px 40px 0px 40px',
									}}
								>
									<SvgIcon
										sx={{
											fontSize: '5.0rem',
											color: 'grey.700',
										}}
									>
										<ForumOutlined />
									</SvgIcon>
									<Typography
										textAlign={'center'}
										fontWeight={'500'}
										fontSize={'1.2rem'}
									>
										<p>
											There are no messages yet for this
											activity.
										</p>
										<p>
											Create the first message below to
											get the chat going.
										</p>
									</Typography>
								</div>
							</Box>
						)}

						<Box
							ref={inputBoxRef}
							sx={{
								position: 'fixed',
								bottom: 0,
								left: 0,
								right: 0,
								backgroundColor: 'grey.100',
								padding: '.5rem',
							}}
						>
							<InputBox onSendMessage={handleSendMessage} />
						</Box>
					</Box>
				</>
			)}
		</>
	)
}

export default ChatPage
