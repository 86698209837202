import queryClient from '../../lib/reactQuery'
import { TABLE_POLL_CHOICES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'
import supabase from '../../lib/supabase'

import type { MutationConfig } from '../../lib/reactQuery'
import type { PollChoice } from '../../lib/supabase'

type DeleteChoicesByPollIdArgs = {
	pollId: string
}
export const deleteChoicesByPollId = async ({
	pollId,
}: DeleteChoicesByPollIdArgs) => {
	const { error } = await supabase
		.from(TABLE_POLL_CHOICES)
		.delete()
		.eq('poll_id', pollId)
}

type UseDeleteChoicesByPollIdOptions = {
	options?: MutationConfig<typeof deleteChoicesByPollId>
}
export const useDeleteChoicesByPollId = ({
	options,
}: UseDeleteChoicesByPollIdOptions = {}) => {
	return useMutation({
		onMutate: async ({ pollId }) => {
			// optimistically update the cache
			await queryClient.cancelQueries({ queryKey: ['poll', pollId] })
			const previousChoice = queryClient.getQueryData<PollChoice>([
				'poll',
				pollId,
			])

			return { previousChoice }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (_, __, context: any) => {
			// revert the cache back to the previous value
			if (context?.previousChoice) {
				queryClient.setQueryData(
					['choice', context.previousChoice.id],
					context.previousChoice
				)
			}
		},
		onSuccess: (pollId) => {
			queryClient.invalidateQueries({ queryKey: ['poll', pollId] })
			queryClient.invalidateQueries({ queryKey: ['choices'] })
		},
		...options,
		mutationFn: deleteChoicesByPollId,
	})
}
