import React from 'react'
import { Stack, IconButton } from '@mui/material'
import { Send } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { object } from 'yup'
import { Validators } from 'utils/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormInputText } from 'components/forms/elements'

type FormValues = {
	message: string
}
type Props = {
	onSendMessage: (messageBody: string) => Promise<void>
}
const InputBox: React.FC<Props> = ({ onSendMessage }) => {
	const { handleSubmit, control, reset } = useForm<FormValues>({
		resolver: yupResolver(
			object().shape({ message: Validators.STRING.required() })
		),
		defaultValues: {
			message: '',
		},
	})

	const handleSendMessage = async (values: FormValues) => {
		reset()
		await onSendMessage(values.message)
	}

	return (
		<form
			onSubmit={handleSubmit(handleSendMessage)}
			style={{ height: '60px' }}
		>
			<Stack direction={'row'} spacing={1}>
				<FormInputText
					control={control}
					name='message'
					showErrorText={false}
					multiline
					maxRows={4}
					size='small'
					sx={{
						width: '100%',
						backgroundColor: 'white',
					}}
				/>
				<IconButton type='submit' sx={{ width: '2.5rem' }}>
					<Send />
				</IconButton>
			</Stack>
		</form>
	)
}

export default InputBox
