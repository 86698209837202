import queryClient from "../../lib/reactQuery"
import { TABLE_ACTIVITIES } from "lib/supabase"
import { useMutation } from "@tanstack/react-query"
import { createGeneric } from "../generic"

import type { Activity } from "../../lib/supabase"
import type { MutationConfig } from "../../lib/reactQuery"

type CreateActivityArgs = {
	activity: Activity
}
export const createActivity = async ({ activity }: CreateActivityArgs) => createGeneric({ tableName: TABLE_ACTIVITIES, data: activity })

type UseCreateActivityOptions = {
	options?: MutationConfig<typeof createActivity>
}
export const useCreateActivity = ({ options }: UseCreateActivityOptions = {}) => {
	return useMutation({
		onSuccess: (_, activity) => {
			queryClient.setQueryData(['activity', activity.activity.id], activity.activity)
		},
		...options,
		mutationFn: createActivity
	})
}
