import React from 'react'
import {
	List,
	ListItem,
	ListItemButton,
	ListItemAvatar,
	Avatar,
	ListItemText,
	Divider,
} from '@mui/material'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'
import EventIcon from '@mui/icons-material/Event'
import GroupIcon from '@mui/icons-material/Group'
import BugReportIcon from '@mui/icons-material/BugReport'
import PollIcon from '@mui/icons-material/Poll'
import { useUserContext } from 'contexts/UserContext'
import { useGroupsContext } from 'contexts/GroupsContext'

type Props = {
	onNavigateToCreateActivity: () => void
	onNavigateToCreatePoll: () => void
	onNavigateToGroup: () => void
	onNavigateToVenues: () => void
	onNavigateToNewCal: () => void
}
const AdminActionsList: React.FC<Props> = ({
	onNavigateToCreateActivity,
	onNavigateToCreatePoll,
	onNavigateToGroup,
	onNavigateToVenues,
	onNavigateToNewCal,
}) => {
	const { activeGroup } = useUserContext()
	const { userIsAdminOfGroup } = useGroupsContext()

	return (
		<List>
			{/* create activity */}
			<ListItem disableGutters>
				<ListItemButton onClick={onNavigateToCreateActivity}>
					<ListItemAvatar>
						<Avatar>
							<EventIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary='Create New Activity'
						secondary='Add new activity for the group'
					/>
				</ListItemButton>
			</ListItem>
			<Divider />

			{/* create poll */}
			<ListItem disableGutters>
				<ListItemButton onClick={onNavigateToCreatePoll}>
					<ListItemAvatar>
						<Avatar>
							<PollIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary='Create New Poll'
						secondary='Add new poll for the group'
					/>
				</ListItemButton>
			</ListItem>
			<Divider />

			{/* manage venues */}
			<ListItem disableGutters>
				<ListItemButton onClick={onNavigateToVenues}>
					<ListItemAvatar>
						<Avatar>
							<MapsHomeWorkIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary='Manage Venues'
						secondary='Add, update, delete venues for this group'
					/>
				</ListItemButton>
			</ListItem>
			<Divider />

			{/* manage group */}
			{activeGroup && userIsAdminOfGroup(activeGroup?.id) && (
				<>
					<ListItem disableGutters>
						<ListItemButton onClick={onNavigateToGroup}>
							<ListItemAvatar>
								<Avatar>
									<GroupIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary='Manage Group'
								secondary='Add, remove members, update group info'
							/>
						</ListItemButton>
					</ListItem>
					<Divider />
				</>
			)}

			{/* manage New Cal Testing Page */}
			<ListItem disableGutters>
				<ListItemButton onClick={onNavigateToNewCal}>
					<ListItemAvatar>
						<Avatar>
							<BugReportIcon />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary='[DEBUG] Date Picker'
						secondary='Debugging date selection issues'
					/>
				</ListItemButton>
			</ListItem>
			<Divider />
		</List>
	)
}

export default AdminActionsList
