import React from 'react'
import { Typography, Checkbox, Button, Link, Stack, Box } from '@mui/material'
import { FormInputText } from '../elements'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import { Validators } from 'utils/validators'

export type CreateAccountFormValues = {
	fullName: string
	email: string
	phone: string
	password: string
}
type Props = {
	defaultValues?: CreateAccountFormValues
	style?: React.CSSProperties
	onSubmit: (values: CreateAccountFormValues) => void | Promise<void>
}

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const numbers = '0123456789'
// const symbols = '!@#$%^&*()_+-=[]{}|;:,.<>?'

const CreateAccountForm: React.FC<Props> = ({
	defaultValues,
	style,
	onSubmit,
}) => {
	const { handleSubmit, control } = useForm<CreateAccountFormValues>({
		resolver: yupResolver(
			object().shape({
				fullName: Validators.NAME.required(),
				email: Validators.EMAIL.required(),
				phone: Validators.PHONE.required(),
				password: Validators.PASSWORD.required(),
			})
		),
		defaultValues: defaultValues || {
			fullName: '',
			email: '',
			phone: '',
			password:
				window.crypto.randomUUID().substring(0, 8) +
				letters[
					Math.floor(Math.random() * letters.length)
				].toLowerCase() +
				letters[
					Math.floor(Math.random() * letters.length)
				].toUpperCase() +
				numbers[Math.floor(Math.random() * numbers.length)] +
				'!',
		},
	})

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			autoComplete='off'
			style={{
				...style,
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<FormInputText
				control={control}
				name='fullName'
				label='Full Name'
				variant='filled'
				required
			/>

			<FormInputText
				control={control}
				name='email'
				label='Email Address'
				variant='filled'
				required
			/>

			<FormInputText
				control={control}
				style={{ display: 'none' }}
				required
				fullWidth
				name='password'
				label='Password'
				type='password'
				variant='filled'
			/>

			<Box>
				<FormInputText
					control={control}
					name='phone'
					label='Phone'
					type='tel'
					fullWidth
					variant='filled'
					required
				/>
				<Typography variant='caption'>
					By providing your phone number, you agree to receive SMS
					notifications from Joyner.
				</Typography>
			</Box>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'baseline',
				}}
			>
				<Stack alignItems='center' direction='row'>
					<Checkbox required />
					<Typography variant='caption' component={'em'}>
						By checking this box, you agree to the Joyner{' '}
						<Link
							href='https://www.joyner.app/legal'
							target='_blank'
							rel='noreferrer'
						>
							terms of service
						</Link>{' '}
						and{' '}
						<Link
							href='https://www.joyner.app/legal'
							target='_blank'
							rel='noreferrer'
						>
							privacy policy
						</Link>
						.
					</Typography>
				</Stack>
			</div>

			<Button variant='contained' type='submit'>
				Create Account
			</Button>
		</form>
	)
}

export default CreateAccountForm
