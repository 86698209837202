import { QueryClient, DefaultOptions, UseQueryOptions, UseMutationOptions, UseInfiniteQueryOptions, QueryKey, InfiniteData } from "@tanstack/react-query"

const queryConfig: DefaultOptions = {
	queries: {
		retry: false,
    // staleTime: 1000 * 60 // 1 minute
	}
}

const queryClient = new QueryClient({
	defaultOptions: queryConfig,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
  ReturnType<FnType>
>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InfiniteQueryConfig<InfiniteQueryFnType extends (...args: any) => any, PageParamType> = Omit<
  UseInfiniteQueryOptions<ExtractFnReturnType<InfiniteQueryFnType>, Error, InfiniteData<ExtractFnReturnType<InfiniteQueryFnType>>, ExtractFnReturnType<InfiniteQueryFnType>, QueryKey, PageParamType>,
  'queryKey' | 'queryFn' | 'getNextPageParam' | 'initialPageParam' 
>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  Error,
  Parameters<MutationFnType>[0]
>

export default queryClient
