import { useQuery } from '@tanstack/react-query'
import queryClient, { QueryConfig } from 'lib/reactQuery'
import supabase, { TABLE_ACTIVITIES } from 'lib/supabase'

type GetManyByUserIdArgs = {
	userId?: string
	groupId?: string
}
const getManyByUserIdFunction = async ({
	userId,
	groupId,
}: GetManyByUserIdArgs) => {
	if (!userId || !groupId) throw new Error('undefined userId or groupId')

	const { data, error } = await supabase
		.from(TABLE_ACTIVITIES)
		.select()
		// .contains('ins', [userId])
		.eq('group_id', groupId)
		.order('datetime', { ascending: true })

	if (error) throw error

	return data
}

export const getManyByUserId = async ({
	userId,
	groupId,
}: GetManyByUserIdArgs) =>
	queryClient.fetchQuery({
		queryKey: ['activity', 'byUserId', userId, 'group', groupId],
		queryFn: () => getManyByUserIdFunction({ userId, groupId }),
	})

type UseGetManyByUserIdOptions = {
	userId?: string
	groupId?: string
	options?: QueryConfig<typeof getManyByUserIdFunction>
}
export const useGetManyByUserId = ({
	userId,
	groupId,
	options,
}: UseGetManyByUserIdOptions) => {
	return useQuery({
		...options,
		queryKey: ['activity', 'byUserId', userId, 'group', groupId],
		queryFn: () => getManyByUserIdFunction({ userId, groupId }),
	})
}
