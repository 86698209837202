import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { Providers } from 'contexts/Providers'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { initVercelAnalytics } from 'lib/vercelAnalytics'
import AppRoutes from 'appRoutes'
import initSentry from 'lib/sentry'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

initVercelAnalytics()
initSentry()

const rootEle = document.getElementById('root')
if (rootEle) {
	const root = ReactDOM.createRoot(rootEle)
	root.render(
		<React.StrictMode>
			<Providers>
				<ReactQueryDevtools
					initialIsOpen={false}
					buttonPosition='top-left'
				/>
				<AppRoutes />
			</Providers>
		</React.StrictMode>
	)
}
