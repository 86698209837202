import queryClient from '../../lib/reactQuery'
import { deleteGeneric } from '../generic'
import { TABLE_POLLS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { MutationConfig } from '../../lib/reactQuery'
import type { Poll } from '../../lib/supabase'

type DeletePollArgs = {
	pollId: string
}
export const deletePoll = async ({ pollId }: DeletePollArgs) =>
	deleteGeneric({ tableName: TABLE_POLLS, id: pollId })

type UseDeletePollOptions = {
	options?: MutationConfig<typeof deletePoll>
}
export const useDeletePoll = ({ options }: UseDeletePollOptions = {}) => {
	return useMutation({
		onMutate: async ({ pollId }) => {
			// optimistically update the cache
			await queryClient.cancelQueries({ queryKey: ['poll', pollId] })
			const previousPoll = queryClient.getQueryData<Poll>([
				'poll',
				pollId,
			])

			return { previousPoll }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (_, __, context: any) => {
			// revert the cache back to the previous value
			if (context?.previousPoll) {
				queryClient.setQueryData(
					['poll', context.previousPoll.id],
					context.previousPoll
				)
			}
		},
		onSuccess: (pollId) => {
			queryClient.invalidateQueries({ queryKey: ['poll', pollId] })
			queryClient.invalidateQueries({ queryKey: ['polls'] })
		},
		...options,
		mutationFn: deletePoll,
	})
}
