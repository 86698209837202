import {
	useGetActivitiesInRange,
	type SupportedRanges,
	getActivitiesInRangeQueryKey,
} from 'api/activity/getInRange'
import queryClient from 'lib/reactQuery'
import supabase, { TABLE_ACTIVITIES, type Activity } from 'lib/supabase'
import { useEffect } from 'react'
import { DateTime } from 'luxon'
import { getQueryKey } from 'api/activity'
import { useUserContext } from 'contexts/UserContext'

type Args = {
	date: DateTime
	groupId: string
	range?: SupportedRanges
}
const useActivitiesInRange = ({ date, groupId, range }: Args) => {
	const activities = useGetActivitiesInRange({
		date,
		groupId,
		range,
		options: { enabled: !!date },
	})
	const { activeGroup } = useUserContext()

	useEffect(() => {
		const sub = supabase
			.channel('db-changes')
			.on(
				'postgres_changes',
				{ event: '*', schema: 'public', table: TABLE_ACTIVITIES },
				async (payload) => {
					if (payload.eventType === 'DELETE') {
						queryClient.removeQueries({
							queryKey: getQueryKey({
								activityId: (payload.old as Activity).id,
							}),
						})
						await queryClient.invalidateQueries({
							queryKey: getActivitiesInRangeQueryKey({
								date,
								groupId,
								range,
							}),
						})
					}

					if (
						payload.eventType === 'UPDATE' &&
						activities.data?.findIndex(
							(a) => a.id === (payload.new as Activity).id
						) !== -1
					) {
						await queryClient.setQueryData(
							getQueryKey({
								activityId: (payload.new as Activity).id,
							}),
							payload.new
						)
						await queryClient.invalidateQueries({
							queryKey: getActivitiesInRangeQueryKey({
								date,
								groupId,
								range,
							}),
						})
					}

					if (
						payload.eventType === 'INSERT' &&
						(payload.new as Activity).group_id === activeGroup?.id
					) {
						await queryClient.setQueryData(
							getQueryKey({
								activityId: (payload.new as Activity).id,
							}),
							payload.new
						)
						await queryClient.refetchQueries({
							queryKey: getActivitiesInRangeQueryKey({
								date,
								groupId,
								range,
							}),
						})
					}
				}
			)
			.subscribe((status, err) => {
				if (status === 'CHANNEL_ERROR' || status === 'TIMED_OUT') {
					if (err) console.error(err)
					sub.unsubscribe()
				}
			})

		return () => {
			sub.unsubscribe()
		}
	}, [supabase, activities.data, date])

	return activities
}

export default useActivitiesInRange
