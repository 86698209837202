import React, { useState } from 'react'
import type { Activity, Venue } from 'lib/supabase'
import { Box, Button, AppBar } from '@mui/material'
import AppHeader from 'components/AppHeader'
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog'
import { DateTime } from 'luxon'
import ActivityForm, {
	ActivityFormValues,
} from 'components/forms/prebuilt/ActivityForm'
import { DEFAULT_ERROR_MESSAGE, combineDateAndTime } from 'utils'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import BinaryDialog from 'components/dialogs/BinaryDialog'

type Props = {
	venues: Venue[]
	activity: Activity
	onSave: (newActivity: Partial<Activity>, notifyMembers: boolean) => void
	onCancel: () => void
	onDeleteActivity: () => void
	onCreateVenue: (venue: Venue) => Promise<Error | void>
}

const EditActivity: React.FC<Props> = ({
	venues,
	activity,
	onSave,
	onCancel,
	onDeleteActivity,
	onCreateVenue,
}) => {
	const { showSnackbar } = useSnackbarContext()
	const [showConfirm, setShowConfirm] = useState(false)
	const openConfirmDelete = () => {
		setShowConfirm(true)
	}
	const handleConfirmDelete = () => {
		onDeleteActivity()
		setShowConfirm(false)
	}

	const [showDialog, setShowDialog] = useState(false)
	const [updatedActivity, setUpdatedActivity] =
		useState<Partial<Activity> | null>(null)

	const handleQuickAddVenue = async (venue: Venue) => {
		const error = await onCreateVenue(venue)
		if (error) {
			console.error(error)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
			return null
		}

		return venue
	}

	const submitForm = (data: ActivityFormValues) => {
		const dateTime = combineDateAndTime(data.date, data.time)

		const newActivity: Partial<Activity> = {
			name: data.name,
			venue_id: data.venue,
			datetime: dateTime,
			note: data.note || null,
			link: data.url || null,
			max_spots: data.maxSpots,
		}

		setUpdatedActivity(newActivity)
		setShowDialog(true)
	}

	const handleUpdateActivity = (notifyMembers: boolean) => {
		if (updatedActivity == null) return
		onSave(updatedActivity, notifyMembers)
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppHeader
				leftComponent={<Button onClick={onCancel}>Cancel</Button>}
				title='Edit activity'
				rightComponent={
					<Button type='submit' form='edit-form'>
						Save
					</Button>
				}
			/>

			<ActivityForm
				onSubmit={submitForm}
				venues={venues}
				defaultValues={{
					name: activity?.name || '',
					venue: activity?.venue_id || '',
					date: activity?.datetime || DateTime.now().toISODate(),
					time: activity?.datetime || DateTime.now().toISODate(),
					note: activity?.note || '',
					url: activity?.link || '',
					maxSpots: activity?.max_spots || 0,
				}}
				onQuickAddVenue={handleQuickAddVenue}
			/>

			<BinaryDialog
				isOpen={showDialog}
				onAnswer={(answer: boolean) => handleUpdateActivity(answer)}
				title={'Send Group Notification?'}
				content={
					'Do you want Joyner to automatically send a notification to all group members about this updated activity?'
				}
				yesButtonText='Send'
				noButtonText='Do Not Send'
			/>

			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<div
					style={{
						textAlign: 'center',
						margin: '10px 40px',
					}}
				>
					<Button
						variant='outlined'
						color='error'
						fullWidth
						onClick={openConfirmDelete}
					>
						Delete Activity
					</Button>
				</div>
			</AppBar>

			<ConfirmationDialog
				isOpen={showConfirm}
				onClose={() => setShowConfirm(false)}
				onConfirm={handleConfirmDelete}
				title={'Confirm Delete Activity'}
				content={'Do you want to permanently delete this activity?'}
				confirmButtonText='Delete Activity'
			/>
		</Box>
	)
}

export default EditActivity
