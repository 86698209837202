import React from 'react'
import { Typography } from '@mui/material'
import InviteLink from 'components/InviteLink'
import { useUserContext } from 'contexts/UserContext'

const InviteMembers: React.FC = () => {
	const { profile, activeGroup } = useUserContext()
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '20px 40px',
			}}
		>
			<Typography fontWeight={'bold'} fontSize={'1.2rem'}>
				Invite Your Friends
			</Typography>
			<Typography paddingBottom={2}>(Optional)</Typography>
			<Typography paddingBottom={1}>
				Last step - invite your friends to your group.
			</Typography>
			<Typography paddingBottom={1}>
				Your friends use Joyner for <strong>FREE</strong> as there is no
				charge to be a group member.
			</Typography>
			<Typography paddingBottom={3}>
				If you skip this step now, you can invite users later by tapping
				the group name in the header.
			</Typography>
			{/* <Button variant='contained' color='success'>
				Invite Friends
			</Button> */}
			<InviteLink group={activeGroup} profile={profile} />
		</div>
	)
}

export default InviteMembers
