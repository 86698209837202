import { updateGeneric } from '../generic'
import { TABLE_POLLS } from 'lib/supabase'
import queryClient from '../../lib/reactQuery'
import { useMutation } from '@tanstack/react-query'

import type { ToUpdate } from '../generic'
import type { Poll } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type PollToUpdate = ToUpdate<Poll>

type UpdatePollArgs = {
	poll: PollToUpdate
}
export const updatePoll = async ({ poll }: UpdatePollArgs) =>
	updateGeneric({ tableName: TABLE_POLLS, data: poll })

type UseUpdatePollOptions = {
	options?: MutationConfig<typeof updatePoll>
}
export const useUpdatePoll = ({ options }: UseUpdatePollOptions = {}) => {
	return useMutation({
		onSuccess: (poll) => {
			queryClient.setQueryData(['poll', poll.id], poll)
		},
		...options,
		mutationFn: updatePoll,
	})
}
