import { Typography } from '@mui/material'
import React from 'react'

const InviteExpired: React.FC = () => {
	return (
		<Typography align='center'>
			Invite has <strong>expired</strong>, please contact the group admin.
		</Typography>
	)
}

export default InviteExpired
