import supabase from "../../lib/supabase"

import type { TableNames } from "../../lib/supabase"

type DeleteGenericArgs = {
	tableName: TableNames
	id: string
}
export const deleteGeneric = async ({ tableName, id }: DeleteGenericArgs) => {
	if (id == null || id.length == 0) throw new Error('No id provided')
	
	const { error } = await supabase
		.from(tableName)
		.delete()
		.eq('id', id)

	if (error) throw error

	return id
}