import { useMutation } from '@tanstack/react-query'
import { getManyQueryKey } from 'api/group/getMany'
import { getManyQueryKey as getManyProfilesQueryKey } from 'api/profile'
import queryClient, { MutationConfig } from 'lib/reactQuery'
import supabase, { TABLE_MEMBERSHIP } from 'lib/supabase'

type AddUserToGroupArgs = {
	userId: string
	groupId: string
}
const addUserToGroupFunc = async ({ userId, groupId }: AddUserToGroupArgs) => {
	const { error } = await supabase
		.from(TABLE_MEMBERSHIP)
		.insert({ user_id: userId, group_id: groupId })

	if (error) throw error
}

export const addUserToGroup = async (args: AddUserToGroupArgs) => {
	await addUserToGroupFunc(args)
	queryClient.invalidateQueries({ queryKey: getManyQueryKey(args.userId) })
	queryClient.invalidateQueries({
		queryKey: getManyProfilesQueryKey(args.groupId),
	})
}

type UseAddUserToGroupArgs = {
	options?: MutationConfig<typeof addUserToGroupFunc>
}
export const useAddUserToGroup = ({ options }: UseAddUserToGroupArgs = {}) => {
	return useMutation({
		onSuccess(_, variables) {
			queryClient.invalidateQueries({
				queryKey: getManyQueryKey(variables.userId),
			})
			queryClient.invalidateQueries({
				queryKey: getManyProfilesQueryKey(variables.groupId),
			})
		},
		...options,
		mutationFn: addUserToGroupFunc,
	})
}
