import React from 'react'
import { Venue } from 'lib/supabase'
import { Box, Button, Typography, Stack } from '@mui/material'
import AppHeader from 'components/AppHeader'
import ProtectedEditButton from 'components/ProtectedEditButton'

type Props = {
	venue: Venue
	onEdit: () => void
	onBack: () => void
}
const ViewVenue: React.FC<Props> = ({ venue, onEdit, onBack }) => {
	const notProvided = (
		<Typography fontSize={'0.8rem'} fontStyle={'italic'} color={'#bbbbbb'}>
			Not provided
		</Typography>
	)

	return (
		<Box>
			<AppHeader
				leftComponent={
					<Button sx={{ color: '#0195f5' }} onClick={onBack}>
						Back
					</Button>
				}
				title={venue.name}
				rightComponent={
					<ProtectedEditButton
						groupId={venue.group_id}
						onClick={onEdit}
					/>
				}
			/>
			<Box
				sx={{
					margin: '2rem',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'left',
				}}
			>
				<div style={{ borderBottom: '1px solid #cccccc' }}>
					<Typography
						fontSize={'0.9rem'}
						fontWeight={'500'}
						paddingY={'0.2rem'}
					>
						Name
					</Typography>
					<Typography paddingY={'0.2rem'}>{venue.name}</Typography>
				</div>

				<div
					style={{
						marginTop: '20px',
						borderBottom: '1px solid #cccccc',
					}}
				>
					<Typography
						fontSize={'0.9rem'}
						fontWeight={'500'}
						paddingY={'0.2rem'}
					>
						Phone
					</Typography>
					{venue.phone ? (
						<Typography>{venue.phone}</Typography>
					) : (
						notProvided
					)}
				</div>

				<div
					style={{
						marginTop: '20px',
						borderBottom: '1px solid #cccccc',
					}}
				>
					<Typography
						fontSize={'0.9rem'}
						fontWeight={'500'}
						paddingY={'0.2rem'}
					>
						Website
					</Typography>
					{venue.url ? (
						<Typography>{venue.url}</Typography>
					) : (
						notProvided
					)}
				</div>

				<div
					style={{
						marginTop: '20px',
						borderBottom: '1px solid #cccccc',
					}}
				>
					<Typography
						fontSize={'0.9rem'}
						fontWeight={'500'}
						paddingY={'0.2rem'}
					>
						Address
					</Typography>
					{venue.street ? (
						<Typography>{venue.street}</Typography>
					) : (
						notProvided
					)}
				</div>

				<div
					style={{
						marginTop: '20px',
						borderBottom: '1px solid #cccccc',
					}}
				>
					<Typography
						fontSize={'0.9rem'}
						fontWeight={'500'}
						paddingY={'0.2rem'}
					>
						City
					</Typography>
					{venue.city ? (
						<Typography>{venue.city}</Typography>
					) : (
						notProvided
					)}
				</div>

				<Stack direction='row' spacing={2}>
					<div
						style={{
							marginTop: '20px',
							borderBottom: '1px solid #cccccc',
							width: '60%',
						}}
					>
						<Typography
							fontSize={'0.9rem'}
							fontWeight={'500'}
							paddingY={'0.2rem'}
						>
							State
						</Typography>
						{venue.state ? (
							<Typography>{venue.state}</Typography>
						) : (
							notProvided
						)}
					</div>

					<div
						style={{
							marginTop: '20px',
							borderBottom: '1px solid #cccccc',
							width: '40%',
						}}
					>
						<Typography
							fontSize={'0.9rem'}
							fontWeight={'500'}
							paddingY={'0.2rem'}
						>
							Zip
						</Typography>
						{venue.zip ? (
							<Typography>{venue.zip}</Typography>
						) : (
							notProvided
						)}
					</div>
				</Stack>
			</Box>
		</Box>
	)
}

export default ViewVenue
