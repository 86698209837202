import React from 'react'
import TrapFocus from '@mui/material/Unstable_TrapFocus'
import { Fade, Paper, Stack, Box, Typography, Button } from '@mui/material'
import { useLocalStorage } from 'usehooks-ts'

const CookieConsentDialog: React.FC = () => {
	const [cookiesAcknowledged, setCookiesAcknowledged] = useLocalStorage(
		'joyner-cookiesAcknowledged',
		false
	)

	return (
		<TrapFocus open disableAutoFocus disableEnforceFocus>
			<Fade appear={false} in={!cookiesAcknowledged}>
				<Paper
					role='dialog'
					aria-modal='false'
					aria-label='Cookie banner'
					square
					variant='outlined'
					tabIndex={-1}
					sx={{
						position: 'fixed',
						bottom: 0,
						left: 0,
						right: 0,
						m: 0,
						p: 2,
						borderWidth: 0,
						borderTopWidth: 1,
					}}
				>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent='space-between'
						gap={2}
					>
						<Box
							sx={{
								flexShrink: 1,
								alignSelf: { xs: 'flex-start', sm: 'center' },
							}}
						>
							<Typography fontWeight='bold'>
								This website uses cookies
							</Typography>
							<Typography variant='body2'>
								joyner.app relies on cookies to properly
								function and to improve your experience. By
								using our website you consent to all cookies in
								accordance with our{' '}
								<a
									href='https://joyner.app/legal'
									rel='noreferrer'
									target='_blank'
								>
									Cookie Policy
								</a>
								.
							</Typography>
						</Box>
						<Stack
							gap={2}
							direction={{
								xs: 'row-reverse',
								sm: 'row',
							}}
							sx={{
								flexShrink: 0,
								alignSelf: { xs: 'flex-end', sm: 'center' },
							}}
						>
							<Button
								size='small'
								onClick={() => setCookiesAcknowledged(true)}
								variant='contained'
							>
								Understood
							</Button>
						</Stack>
					</Stack>
				</Paper>
			</Fade>
		</TrapFocus>
	)
}

export default CookieConsentDialog
