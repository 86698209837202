import React from 'react'
import { Alert, AlertColor, IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
	keyProp?: string
	open: boolean
	message?: string
	status?: AlertColor
	onClose: () => void
	onExited: () => void
}
const SnackbarAlert: React.FC<Props> = ({
	keyProp,
	open,
	message,
	status,
	onClose,
	onExited,
}) => {
	return (
		<Snackbar
			key={keyProp}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={open}
			autoHideDuration={4000}
			onClose={onClose}
			TransitionProps={{ onExited: onExited }}
			style={{ paddingBottom: '20px' }}
			action={
				<IconButton
					aria-label='close'
					color='inherit'
					sx={{ p: 0.5 }}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			}
		>
			<Alert severity={status} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	)
}

export default SnackbarAlert
