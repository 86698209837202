import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, Typography, Zoom } from '@mui/material'

type Props = {
	message?: string
}
const ActionComplete: React.FC<Props> = ({ message }) => {
	return (
		<Box
			color={'green'}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Zoom in={true} timeout={300}>
				<CheckCircleOutlineIcon
					sx={{ width: '3rem', height: '100%' }}
				/>
			</Zoom>
			{message && <Typography>{message}</Typography>}
		</Box>
	)
}

export default ActionComplete
