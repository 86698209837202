import queryClient from '../../lib/reactQuery'
import { createGeneric } from '../generic'
import { TABLE_VENUES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { Venue } from '../../lib/supabase'
import type { MutationConfig } from "../../lib/reactQuery"

type CreateVenueArgs = {
	venue: Venue
}
export const createVenue = async ({ venue }: CreateVenueArgs) => createGeneric({ tableName: TABLE_VENUES, data: venue })

type UseCreateVenueOptions = {
	options?: MutationConfig<typeof createVenue>
}
export const useCreateVenue = ({ options }: UseCreateVenueOptions = {}) => {
	return useMutation({
		onSuccess: (venue) => {
			queryClient.setQueryData(['venue', venue.id], venue)
			queryClient.invalidateQueries({queryKey: ['venues']})
		},
		...options,
		mutationFn: createVenue
	})
}