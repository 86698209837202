import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { useNavigate, useLocation, useParams } from 'react-router'
import { useAuthContext } from 'contexts/AuthContext'
import AnonHeader from 'components/AnonHeader'
import OTPForm from 'components/forms/prebuilt/OTPForm'
import { AuthApiError } from '@supabase/supabase-js'
import { useSnackbarContext } from 'contexts/SnackbarContext'

type FormValues = {
	otp: string
}

const OTPChallengePage = () => {
	const { sendOTP, verifyOTP } = useAuthContext()
	const { showSnackbar } = useSnackbarContext()
	const location = useLocation()
	const [phone] = useState<string>(() => {
		return location.state.phone
	})
	const { challengeId } = useParams()
	const navigate = useNavigate()

	const [otpError, setOtpError] = React.useState(false)

	if (!challengeId || !phone) {
		navigate('/login', { replace: true })
		return <p>Error with OTP challenge, going to login page</p>
	}

	const handleSubmitForm = async (data: FormValues) => {
		const { otp } = data

		try {
			const verificationData = await verifyOTP(phone, otp)
			if (verificationData.user?.aud !== 'authenticated') return
			navigate('/')
		} catch (err) {
			setOtpError(true)
			if (err instanceof AuthApiError) {
				showSnackbar(err.message, 'error')
			} else {
				showSnackbar('Unable to sign in with provided number', 'error')
			}
		}
	}

	const handleResendCode = async () => {
		await sendOTP(phone)
	}

	return (
		<>
			{/* HERO IMAGE */}
			<AnonHeader />

			<Typography
				component='h1'
				sx={{
					textAlign: 'center',
					fontWeight: '500',
					fontSize: '1.6em',
					marginTop: '2rem',
					marginBottom: '2rem',
				}}
			>
				Sign In to Joyner
			</Typography>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<div style={{ marginBottom: '10px' }}>
					<Typography fontSize={'0.9rem'}>
						Enter 6 digit code sent to your phone ending in{' '}
						{phone.slice(-4)}
					</Typography>
				</div>

				<OTPForm
					error={otpError}
					onSubmit={handleSubmitForm}
					onResendCode={handleResendCode}
				/>
			</div>
		</>
	)
}

export default OTPChallengePage
