import { createClient } from '@supabase/supabase-js'
import { Database, Tables } from './database.types'

const supabaseUrl = import.meta.env.VITE_API_URL
if (!supabaseUrl) throw new Error('Supabase URL is undefined')
const supabaseAnonKey = import.meta.env.VITE_API_ANON_KEY
if (!supabaseAnonKey) throw new Error('Supabase Anon Key is undefined')
const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey)

export default supabase

export const TABLE_ACTIVITIES = 'activities'
export const TABLE_PROFILES = 'profiles'
export const TABLE_GROUPS = 'groups'
export const TABLE_VENUES = 'venues'
export const TABLE_MESSAGES = 'messages'
export const TABLE_INVITES = 'invites'
export const TABLE_MEMBERSHIP = 'membership'
export const TABLE_GUESTS = 'guests'
export const TABLE_POLLS = 'polls'
export const TABLE_POLL_CHOICES = 'poll_choices'

export type TableNames = keyof Database['public']['Tables']

export type Activity = Tables<typeof TABLE_ACTIVITIES>
export type Profile = Tables<typeof TABLE_PROFILES>
export type Group = Tables<typeof TABLE_GROUPS>
export type Venue = Tables<typeof TABLE_VENUES>
export type Message = Tables<typeof TABLE_MESSAGES>
export type Invite = Tables<typeof TABLE_INVITES>
export type Membership = Tables<typeof TABLE_MEMBERSHIP>
export type Guest = Tables<typeof TABLE_GUESTS>
export type Poll = Tables<typeof TABLE_POLLS>
export type PollChoice = Tables<typeof TABLE_POLL_CHOICES>
