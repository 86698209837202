import React from 'react'
import OTPForm, { OTPFormValues } from 'components/forms/prebuilt/OTPForm'
import SignInForm, {
	SignInFormValues,
} from 'components/forms/prebuilt/SignInForm'
import { useAuthContext } from 'contexts/AuthContext'
import { formatPhoneNumber } from 'utils'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { AuthApiError } from '@supabase/supabase-js'

type Props = {
	style?: React.CSSProperties
}
const SignIn: React.FC<Props> = ({ style }) => {
	const { sendOTP, verifyOTP } = useAuthContext()
	const { showSnackbar } = useSnackbarContext()
	const [phone, setPhone] = React.useState<string>()
	const [otpError, setOtpError] = React.useState(false)
	const [verificationTime, setVerificationTime] = React.useState(false)

	const handleSignIn = async (values: SignInFormValues) => {
		try {
			await sendOTP(formatPhoneNumber(values.phone))
			setPhone(values.phone)
			setVerificationTime(true)
		} catch (err) {
			if (err instanceof AuthApiError) {
				if (err.message === 'Signups not allowed for otp') {
					showSnackbar(
						'Phone number not found. Please create an account.',
						'error'
					)
				}
			} else {
				showSnackbar('Unable to sign in with provided number', 'error')
			}
		}
	}

	const handleVerifyOTP = async (values: OTPFormValues) => {
		setOtpError(false)
		if (!phone) return
		try {
			const verificationData = await verifyOTP(phone, values.otp)

			if (verificationData.user?.aud !== 'authenticated')
				throw new Error('User not authenticated')
		} catch (err) {
			setOtpError(true)
			showSnackbar('Unable to validate OTP', 'error')
		}
	}

	const handleResendOTP = async () => {
		if (phone) await sendOTP(formatPhoneNumber(phone))
	}

	return (
		<>
			{verificationTime ? (
				<OTPForm
					phone={phone}
					onSubmit={handleVerifyOTP}
					onResendCode={handleResendOTP}
					error={otpError}
					style={{ ...style }}
				/>
			) : (
				<SignInForm onSubmit={handleSignIn} style={{ ...style }} />
			)}
		</>
	)
}

export default SignIn
