import queryClient from '../../lib/reactQuery'
import { createGeneric } from '../generic'
import { TABLE_POLLS } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { Poll } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'

type CreatePollArgs = {
	poll: Poll
}
export const createPoll = async ({ poll }: CreatePollArgs) =>
	createGeneric({ tableName: TABLE_POLLS, data: poll })

type UseCreatePollOptions = {
	options?: MutationConfig<typeof createPoll>
}
export const useCreatePoll = ({ options }: UseCreatePollOptions = {}) => {
	return useMutation({
		onSuccess: (poll) => {
			queryClient.setQueryData(['poll', poll.id], poll)
			queryClient.invalidateQueries({ queryKey: ['polls'] })
		},
		...options,
		mutationFn: createPoll,
	})
}
