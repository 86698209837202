import { useQuery } from "@tanstack/react-query"
import { getGeneric } from "api/generic"
import queryClient, { type QueryConfig } from "lib/reactQuery"
import { type Invite, TABLE_INVITES } from "lib/supabase"

type GetInviteArgs = {
	inviteId: string
}
const getInviteFunction = ({ inviteId }: GetInviteArgs) => getGeneric<Invite>({ tableName: TABLE_INVITES, id: inviteId })

export const getInvite = async ({ inviteId }: GetInviteArgs) => queryClient.fetchQuery({ queryKey: ['activity', inviteId], queryFn: () => getInviteFunction({ inviteId })})

type UseGetInviteOptions = {
	inviteId: string,
	options?: QueryConfig<typeof getInviteFunction>,
}
export const useGetInvite = ({ inviteId, options }: UseGetInviteOptions) => {
	return useQuery({
		...options,
		queryKey: ['invite', inviteId],
		queryFn: () => getInviteFunction({ inviteId })
	})
}