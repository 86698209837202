import queryClient from "lib/reactQuery"
import supabase, { TABLE_PROFILES } from "lib/supabase"


type GetMaybeArgs = {
	profileId: string
}
const getMaybeFunction = async ({ profileId }: GetMaybeArgs) => {
	const { data, error } = await supabase
		.from(TABLE_PROFILES)
		.select()
		.eq('id', profileId)
		.maybeSingle()

	if (error) throw error

	return data
}

export const getMaybe = async ({ profileId }: GetMaybeArgs) => queryClient.fetchQuery({ queryKey: ['profile', profileId], queryFn: () => getMaybeFunction({ profileId }) })
