import supabase from "../../lib/supabase"

import type { TableNames } from "../../lib/supabase"

type GetGenericArgs = {
	tableName: TableNames
	id?: string
}
export const getGeneric = async <R>({ tableName, id }: GetGenericArgs) => {
	if (id == null || id.length == 0) throw new Error('No id provided')
	
	const { data, error } = await supabase
		.from(tableName)
		.select('*')
		.eq('id', id)
		.single<R>()

	if (error) throw error

	return data
}