import { AppBar, Stack, Button } from '@mui/material'
import React from 'react'

type Props = {
	visible: boolean
	onEditClick: () => void
	onSendReminderClick: () => void
	// onSendNoteClick: () => void
}
const ActivityButtons: React.FC<Props> = ({
	visible,
	onEditClick,
	onSendReminderClick,
	// onSendNoteClick,
}) => {
	if (visible) {
		return (
			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<Stack
					direction={'column'}
					spacing={1}
					marginTop={2}
					marginX={10}
				>
					<Button onClick={onEditClick} variant='contained'>
						Edit Activity
					</Button>
					<Button onClick={onSendReminderClick} variant='outlined'>
						Send Reminder
					</Button>
					{/* <Button onClick={onSendNoteClick} variant='outlined'>
						Send Note
					</Button> */}
				</Stack>
			</AppBar>
		)
	} else {
		return null
	}
}

export default ActivityButtons
