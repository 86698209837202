import React, { useState, useCallback } from 'react'
import {
	Button,
	IconButton,
	Stack,
	TextField,
	InputAdornment,
	Typography,
	Divider,
	Box,
} from '@mui/material'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { debounce } from '@mui/material/utils'
import { FormInputText } from '../elements'
import { yupResolver } from '@hookform/resolvers/yup'
import { set, useForm } from 'react-hook-form'
import { Validators } from 'utils/validators'
import { object } from 'yup'
import SearchIcon from '@mui/icons-material/Search'
import RadarAutocomplete from '../../../radar/radar-autocomplete'
import type { RadarAutocompleteAddress } from 'radar-sdk-js/dist/types'
import CloseIcon from '@mui/icons-material/Close'
import PlaceIcon from '@mui/icons-material/Place'
import SvgIcon from '@mui/material/SvgIcon'

export type VenueFormValues = {
	name: string
	phone?: string | null
	url?: string
	street?: string
	city?: string
	state?: string
	zip?: string
}

type Props = {
	defaultValues?: VenueFormValues
	includeSubmitButton?: boolean
	formId?: string
	onSubmit: (values: VenueFormValues) => void
}

const VenueForm: React.FC<Props> = ({
	defaultValues,
	includeSubmitButton,
	formId,
	onSubmit,
}) => {
	const { setValue, resetField, getValues, handleSubmit, control } =
		useForm<VenueFormValues>({
			resolver: yupResolver(
				object().shape({
					name: Validators.NAME.required(),
					phone: Validators.PHONE,
					url: Validators.URL,
					street: Validators.ADDRESS,
					city: Validators.CITY,
					state: Validators.STATE,
					zip: Validators.ZIP,
				})
			),
			defaultValues: defaultValues || {
				name: '',
				phone: '',
				url: '',
				street: '',
				city: '',
				state: '',
				zip: '',
			},
		})

	const [openAddressesDialog, setOpenAddressesDialog] = React.useState(false)
	const [showSearchResults, setShowSearchResults] = useState(false)
	const [searchResults, setSearchResults] = useState(
		[] as RadarAutocompleteAddress[]
	)

	const searchRadar = useCallback(
		debounce((search: string) => {
			RadarAutocomplete({
				input: search,
			})
				.then((adresses: RadarAutocompleteAddress[]) => {
					setSearchResults(adresses)
					setShowSearchResults(true)
				})
				.catch((error) => {
					console.log('Error', error)
				})
		}, 300),
		[]
	)

	const handleOpenAddressLookup = () => {
		setSearchResults([])
		setOpenAddressesDialog(true)
	}

	const handleAddressLookup = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const search = event.target.value
		searchRadar(search)
	}

	const handleListClick = (address: RadarAutocompleteAddress) => {
		console.log('user selected address ' + address.formattedAddress)
		resetField('street')
		setValue('street', address.number + ' ' + address.street)
		resetField('city')
		setValue('city', address.city)
		resetField('state')
		setValue('state', address.stateCode)
		resetField('zip')
		setValue('zip', address.postalCode)
		console.log('GetFormValues: ' + JSON.stringify(getValues()))
		handleClose()
	}

	const handleClearAddress = () => {
		resetField('street')
		setValue('street', '')
		resetField('city')
		setValue('city', '')
		resetField('state')
		setValue('state', '')
		resetField('zip')
		setValue('zip', '')
	}

	const handleClose = () => {
		setOpenAddressesDialog(false)
		setShowSearchResults(false)
	}

	return (
		<form
			id={formId || 'venue-form'}
			onSubmit={handleSubmit(onSubmit)}
			style={{
				padding: '2rem',
				display: 'flex',
				flexDirection: 'column',
				justifyItems: 'center',
				gap: '1rem',
				maxWidth: '600px',
			}}
		>
			<FormInputText
				name='name'
				label='Venue Name'
				variant='filled'
				required
				fullWidth
				control={control}
			/>

			<FormInputText
				name='phone'
				label='Phone'
				variant='filled'
				fullWidth
				control={control}
			/>

			<FormInputText
				name='url'
				label='Website'
				control={control}
				variant='filled'
				fullWidth
				placeholder='https://'
			/>

			<Divider />

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					gap: '5px',
					padding: '0px 0px 10px 0px',
				}}
			>
				<Button
					id='addresslookup'
					variant='contained'
					onClick={handleOpenAddressLookup}
				>
					Lookup Address
				</Button>
				<Button
					variant='outlined'
					color='warning'
					onClick={handleClearAddress}
				>
					Clear Address
				</Button>
			</div>

			<FormInputText
				name='street'
				label='Street Address'
				variant='outlined'
				fullWidth
				control={control}
				disabled
			/>

			<FormInputText
				name='city'
				label='City'
				variant='outlined'
				fullWidth
				control={control}
				disabled
			/>

			<Stack direction='row' spacing={2}>
				<FormInputText
					name='state'
					label='State'
					variant='outlined'
					fullWidth
					control={control}
					disabled
				/>

				<FormInputText
					name='zip'
					label='Zip'
					variant='outlined'
					fullWidth
					control={control}
					disabled
				/>
			</Stack>

			<Dialog
				id='popover-addresses'
				fullScreen
				sx={{ margin: '120px 20px 20px 20px' }}
				maxWidth='sm'
				open={openAddressesDialog}
				onClose={handleClose}
			>
				<DialogTitle>
					<Box
						display={'flex'}
						flexDirection={'row'}
						justifyContent={'space-between'}
						alignItems={'baseline'}
					>
						<Typography fontSize={'1.2rem'} fontWeight={'500'}>
							Lookup Address
						</Typography>{' '}
						<IconButton
							onClick={() => setOpenAddressesDialog(false)}
						>
							<CloseIcon />
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent>
					<TextField
						name='lookup'
						label='Search by Street Address'
						variant='standard'
						autoComplete='off'
						autoCorrect='off'
						autoCapitalize='off'
						spellCheck='false'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<SearchIcon />
								</InputAdornment>
							),
						}}
						fullWidth
						onChange={handleAddressLookup}
					/>
					<Typography
						fontSize={'1.0rem'}
						color={'grey.900'}
						paddingTop={'20px'}
						marginBottom={'5px'}
						sx={{
							display: showSearchResults ? 'block' : 'none',
						}}
					>
						Select address from list below
					</Typography>

					<List disablePadding>
						{searchResults.map(
							(address: RadarAutocompleteAddress, index) => (
								<ListItem
									key={index}
									disableGutters
									style={{ padding: '0px', margin: '0px' }}
								>
									<ListItemButton
										style={{
											padding: '0px',
											marginTop: '5px',
										}}
										onClick={() => handleListClick(address)}
									>
										<ListItemText>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '5px',
													alignItems: 'baseline',
												}}
											>
												<SvgIcon
													sx={{
														fontSize: '1.0rem',
														color: 'grey.500',
													}}
												>
													<PlaceIcon />
												</SvgIcon>
												<Typography>
													<span
														style={{
															fontWeight: '500',
															color: 'grey.800',
														}}
													>
														{address.number}{' '}
														{address.street}
														&nbsp;&nbsp;&nbsp;
													</span>
													<span
														style={{
															color: 'grey.400',
															fontSize: '0.9rem',
														}}
													>
														{address.city},{' '}
														{address.stateCode}{' '}
														{address.postalCode}
													</span>
												</Typography>
											</div>
										</ListItemText>
									</ListItemButton>
								</ListItem>
							)
						)}
					</List>
				</DialogContent>
			</Dialog>

			{includeSubmitButton && (
				<Button fullWidth type='submit' variant='contained'>
					Save
				</Button>
			)}
		</form>
	)
}

export default VenueForm
