import queryClient from '../../lib/reactQuery'
import supabase, { TABLE_ACTIVITIES } from 'lib/supabase'
import { useMutation } from '@tanstack/react-query'

import type { Activity } from '../../lib/supabase'
import type { MutationConfig } from '../../lib/reactQuery'
import { getQueryKey } from './get'

type UpdateActivityArgs = {
	activityId: string
	activity: Partial<Omit<Activity, 'id'>>
}
export const updateActivity = async ({
	activityId,
	activity,
}: UpdateActivityArgs) => {
	const { data, error } = await supabase
		.from(TABLE_ACTIVITIES)
		.update(activity)
		.eq('id', activityId)
		.select()
		.single()

	if (error) throw error

	return data
}

type UseUpdateActivityOptions = {
	options?: MutationConfig<typeof updateActivity>
}
export const useUpdateActivity = ({
	options,
}: UseUpdateActivityOptions = {}) => {
	return useMutation({
		onMutate: async (variables) => {
			const queryKey = getQueryKey({ activityId: variables.activityId })
			const previousActivity = queryClient.getQueryData(
				queryKey
			) as Activity
			queryClient.setQueryData(queryKey, variables.activity)
			return { previousActivity }
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError(error, variables, context: any) {
			if (context) {
				queryClient.setQueryData(
					getQueryKey({ activityId: variables.activityId }),
					context.previousActivity
				)
			}
		},
		onSuccess: (data) => {
			queryClient.setQueryData(getQueryKey({ activityId: data.id }), data)
		},
		...options,
		mutationFn: updateActivity,
	})
}
