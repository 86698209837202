import queryClient from '../../lib/reactQuery'
import { useMutation } from '@tanstack/react-query'
import { TABLE_GROUPS } from 'lib/supabase'
import { updateGeneric } from '../generic'

import type { MutationConfig } from '../../lib/reactQuery'
import type { Group } from '../../lib/supabase'
import type { ToUpdate } from '../generic'
import { getQueryKey } from './get'
import { getManyQueryKey } from './getMany'
import { useUserContext } from 'contexts/UserContext'
type GroupToUpdate = ToUpdate<Group>

type UpdateGroupsArgs = {
	group: GroupToUpdate
}
export const updateGroup = async ({ group }: UpdateGroupsArgs) =>
	updateGeneric({ tableName: TABLE_GROUPS, data: group })

type UseUpdateGroupOptions = {
	options?: MutationConfig<typeof updateGroup>
}
export const useUpdateGroup = ({ options }: UseUpdateGroupOptions = {}) => {
	const { profile } = useUserContext()

	return useMutation({
		onMutate: (variables) => {
			const previousGroup = queryClient.getQueryData(
				getQueryKey(variables.group.id)
			)
			// optimistically update the cache
			queryClient.setQueryData(
				getQueryKey(variables.group.id),
				variables.group
			)

			// optimistically update the groups list
			const previousGroups = queryClient.getQueryData<Group[]>(
				getManyQueryKey(profile?.id)
			)
			const groupToUpdate = previousGroups?.findIndex(
				(group) => group.id === variables.group.id
			)

			if (previousGroups && groupToUpdate && groupToUpdate > -1) {
				const newGroup: Group = {
					...previousGroups[groupToUpdate],
					...variables.group,
				}
				// we know groups is defined here
				const newGroups = [
					...previousGroups.slice(0, groupToUpdate),
					newGroup,
					...previousGroups.slice(groupToUpdate),
				]

				queryClient.setQueryData(
					getManyQueryKey(profile?.id),
					newGroups
				)
			}

			// used to revert optimistic update
			return {
				previousGroup,
				previousGroups,
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (error, variables, context: any) => {
			if (context) {
				// revert optimistic update on error
				queryClient.setQueryData(
					getQueryKey(context.previousGroup.id),
					context.previousGroup
				)
				// revert get many
				queryClient.setQueryData(
					getManyQueryKey(profile?.id),
					context.previousGroups
				)
			}
		},
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: getQueryKey(variables.group.id),
			})
			// invalidate get many
			queryClient.invalidateQueries({
				queryKey: getManyQueryKey(profile?.id),
			})
		},
		...options,
		mutationFn: updateGroup,
	})
}
