import { TABLE_ACTIVITIES } from 'lib/supabase'
import { useQuery } from '@tanstack/react-query'
import { getGeneric } from '../generic'
import queryClient from '../../lib/reactQuery'

import type { Activity } from '../../lib/supabase'
import type { QueryConfig } from '../../lib/reactQuery'

export const getQueryKey = ({ activityId }: GetActivityArgs) => [
	'activity',
	activityId,
]

type GetActivityArgs = {
	activityId: string
}
const getActivityFunction = ({ activityId }: GetActivityArgs) =>
	getGeneric<Activity>({ tableName: TABLE_ACTIVITIES, id: activityId })

export const getActivity = async ({ activityId }: GetActivityArgs) =>
	queryClient.fetchQuery({
		queryKey: getQueryKey({ activityId }),
		queryFn: () => getActivityFunction({ activityId }),
	})

type UseGetActivityOptions = {
	activityId: string
	options?: QueryConfig<typeof getActivityFunction>
}
export const useGetActivity = ({
	activityId,
	options,
}: UseGetActivityOptions) => {
	return useQuery({
		...options,
		queryKey: getQueryKey({ activityId }),
		queryFn: () => getActivityFunction({ activityId }),
	})
}
