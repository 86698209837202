import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material'
import React from 'react'

type Props = {
	open: boolean
	onCancel: () => void
	onSubscribe: () => void
}
const SubscribeDialog: React.FC<Props> = ({ open, onCancel, onSubscribe }) => {
	return (
		<Dialog
			fullWidth
			open={open}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>
				{'Subscription Required'}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					To create your own groups, you need to start your 90 day
					FREE trial. No credit card is required to start the free
					trial. There is no obligation to subscribe at the end of the
					trial.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel}>Cancel</Button>
				<Button
					onClick={onSubscribe}
					variant='contained'
					color='success'
				>
					Start Free Trial
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SubscribeDialog
