import supabase, {
	TABLE_PROFILES,
	TABLE_ACTIVITIES,
	TABLE_GROUPS,
	TABLE_VENUES,
	TABLE_MESSAGES,
	TABLE_INVITES,
	TABLE_GUESTS,
	TABLE_POLLS,
	TABLE_POLL_CHOICES,
} from 'lib/supabase'
import type {
	Group,
	Profile,
	Activity,
	Venue,
	Message,
	Invite,
	Guest,
	Poll,
	PollChoice,
} from 'lib/supabase'

type CreateGenericArgs =
	| {
			tableName: typeof TABLE_PROFILES
			data: Profile
	  }
	| {
			tableName: typeof TABLE_ACTIVITIES
			data: Activity
	  }
	| {
			tableName: typeof TABLE_GROUPS
			data: Group
	  }
	| {
			tableName: typeof TABLE_VENUES
			data: Venue
	  }
	| {
			tableName: typeof TABLE_MESSAGES
			data: Message
	  }
	| {
			tableName: typeof TABLE_INVITES
			data: Invite
	  }
	| {
			tableName: typeof TABLE_GUESTS
			data: Guest
	  }
	| {
			tableName: typeof TABLE_POLLS
			data: Poll
	  }
	| {
			tableName: typeof TABLE_POLL_CHOICES
			data: PollChoice
	  }
export const createGeneric = async ({ tableName, data }: CreateGenericArgs) => {
	if (data?.id == null) throw new Error('Function data is not defined')

	const { data: createdData, error } = await supabase
		.from(tableName)
		.insert(data)
		.select()
		.single<typeof data>()

	if (error) throw error

	return createdData
}
