import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Button, Icon, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

const ErrorComponent: React.FC = () => {
	const navigate = useNavigate()
	return (
		<Stack
			direction={'column'}
			alignItems={'center'}
			gap={1}
			padding='1rem'
		>
			<Icon fontSize='large'>
				<ErrorOutlineIcon fontSize='large' />
			</Icon>
			<Typography variant={'h6'}>An error has occurred</Typography>
			<Button
				variant='contained'
				onClick={() => window.location.reload()}
			>
				Reload
			</Button>
			<Button variant='contained' onClick={() => navigate('/')}>
				Home
			</Button>
		</Stack>
	)
}

export default ErrorComponent
export { ErrorComponent }
