import { TABLE_PROFILES } from 'lib/supabase'
import { useQuery } from '@tanstack/react-query'
import { getGeneric } from '../generic/get'
import queryClient from '../../lib/reactQuery'

import type { QueryConfig } from '../../lib/reactQuery'
import type { Profile } from '../../lib/supabase'

export const getQueryKey = (profileId?: string) => ['profile', profileId]

type GetProfileArgs = {
	profileId: string
}
const getProfileFunction = ({ profileId }: GetProfileArgs) =>
	getGeneric<Profile>({ tableName: TABLE_PROFILES, id: profileId })

export const getProfile = ({ profileId }: GetProfileArgs) =>
	queryClient.fetchQuery({
		queryKey: getQueryKey(profileId),
		queryFn: () => getProfileFunction({ profileId }),
	})

type UseGetProfileOptions = {
	profileId: string
	options?: QueryConfig<typeof getProfileFunction>
}
export const useGetProfile = ({ profileId, options }: UseGetProfileOptions) => {
	return useQuery({
		...options,
		queryKey: getQueryKey(profileId),
		queryFn: () => getProfileFunction({ profileId }),
	})
}
