import React, { useContext, useEffect, useState } from 'react'
import supabase, { Group, Profile, TABLE_PROFILES } from '../lib/supabase'
import { type User } from '@supabase/supabase-js'
import {
	useCreateProfile,
	getMaybe,
	useUpdateProfile,
	getQueryKey,
} from 'api/profile'
import { getManyQueryKey, useGetGroup } from 'api/group'
import { useAuthContext } from './AuthContext'
import queryClient from 'lib/reactQuery'
import { DEFAULT_ERROR_MESSAGE, GROUP_TIMESTAMP_COOKIE_KEY } from 'utils'
import { useSnackbarContext } from './SnackbarContext'
import { useLocalStorage } from 'usehooks-ts'
import { DateTime } from 'luxon'

type UserContextType = {
	user?: User
	profile?: Profile
	activeGroup?: Group
	prevLastAccess: string | null
	isAdminOfActiveGroup?: boolean
	updateProfile: (profile: Partial<Omit<Profile, 'id'>>) => Promise<void>
	setActiveGroup: (groupId: string | null) => Promise<void>
}
const UserContext = React.createContext<UserContextType | undefined>(undefined)

export const useUserContext = () => {
	const context = useContext(UserContext)
	if (!context) throw new Error('No user context provided')
	return context
}

export const UserContextProvider: React.FC<React.PropsWithChildren> = (
	props
) => {
	const { session, getNewUserInfo } = useAuthContext()
	const { showSnackbar } = useSnackbarContext()
	const [prevLastAccess, setPrevLastAccess] = useState<string | null>(null)
	const [user, setUser] = useState<User>()
	const [profile, setProfile] = useState<Profile>()
	const activeGroup = useGetGroup({
		groupId: profile?.active_group || undefined,
		options: { enabled: !!profile && profile?.active_group != null },
	})
	const updateProfile = useUpdateProfile()
	const createProfile = useCreateProfile()
	const [, setActiveGroupTimestamps] = useLocalStorage<{
		[groupId: string]: string
	}>(GROUP_TIMESTAMP_COOKIE_KEY, {})

	useEffect(() => {
		if (session) {
			setUser(session.user)
			getMaybe({ profileId: session.user.id }).then(async (profile) => {
				if (profile) {
					setProfile(profile)
					setPrevLastAccess(profile.last_access_at)
					profile.last_access_at = DateTime.now().toISO()
					await updateProfile.mutateAsync({
						profile: { ...profile },
						profileId: profile.id,
					})
				} else {
					const newUserInfo = getNewUserInfo()

					const data = await createProfile.mutateAsync({
						profile: {
							id: session.user.id,
							email: newUserInfo?.email || session.user.email!,
							active_group: newUserInfo?.groupId || null,
							phone_number: session.user.phone!,
							full_name:
								session.user.user_metadata?.full_name || null,
							subscription_status: 'inactive',
							stripe_customer_id: null,
							allow_sms: !!session.user.phone,
							activity_range: 'month',
							created_at: DateTime.now().toISO(),
							updated_at: null,
							subscribed_at: null,
							viewed_welcome: null,
							trialing: false,
							last_access_at: DateTime.now().toISO(),
						},
					})

					setProfile(data as Profile)
				}
			})
		}
	}, [session])

	const handleUpdateProfile = async (
		newProfileData: Partial<Profile>
	): Promise<void> => {
		if (!profile) return
		const prevProfile = { ...profile }
		try {
			// optimistic update
			// due to some closure shenanigans, we need to reference the previous value here
			setProfile((prev): Profile | undefined => {
				if (!prev) return undefined

				return {
					...prev,
					...newProfileData,
				}
			})

			await updateProfile.mutateAsync({
				profileId: profile.id,
				profile: { ...newProfileData },
			})
		} catch (err) {
			if (err instanceof Error) {
				console.error(err.message)
			}
			// revert optimistic update
			setProfile(prevProfile)
			setPrevLastAccess(prevProfile.last_access_at)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
		}
	}

	// subscribe to realtime changes for current profile
	useEffect(() => {
		if (profile) {
			const chan = supabase
				.channel('db-changes')
				.on(
					'postgres_changes',
					{
						event: 'UPDATE',
						schema: 'public',
						table: TABLE_PROFILES,
						filter: `id=eq.${profile.id}`,
					},
					(payload) => {
						// update the current profile when it changes
						const newProfile = payload.new as Profile
						setProfile(newProfile)
						queryClient.setQueryData(
							getQueryKey(profile.id),
							newProfile
						)
						queryClient.invalidateQueries({
							queryKey: getManyQueryKey(profile.id),
						})
					}
				)
				.subscribe()

			return () => {
				chan.unsubscribe()
			}
		}
	}, [profile])

	const value: UserContextType = {
		user,
		profile,
		prevLastAccess: prevLastAccess,
		activeGroup: activeGroup.data,
		isAdminOfActiveGroup: activeGroup.data?.admin === user?.id,
		updateProfile: async (newProfileData) => {
			await handleUpdateProfile(newProfileData)
		},
		setActiveGroup: async (groupId) => {
			await handleUpdateProfile({ active_group: groupId })

			if (groupId != null) {
				setActiveGroupTimestamps((prevTimestamps) => ({
					...prevTimestamps,
					[groupId]: new Date().toISOString(),
				}))
			}
		},
	}

	return (
		<UserContext.Provider value={value}>
			{props.children}
		</UserContext.Provider>
	)
}
