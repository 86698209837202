import Radar from 'radar-sdk-js'
import type { RadarAutocompleteAddress } from 'radar-sdk-js/dist/types'

type SearchProps = {
	input: string
	latitude?: number
	longitude?: number
}

export default function RadarAutocomplete(
	props: SearchProps
): Promise<RadarAutocompleteAddress[]> {
	const input = props.input
	const latitude = props?.latitude || 40.7039
	const longitude = props?.longitude || -73.9867

	const VITE_RADAR_KEY = import.meta.env.VITE_RADAR_KEY
	Radar.initialize(VITE_RADAR_KEY)

	return Radar.autocomplete({
		query: input,
		// near: { latitude: latitude, longitude: longitude },
		limit: 10,
	})
		.then((result) => {
			const { addresses } = result // do something with addresses
			return addresses
		})
		.catch((err) => {
			// handle error
			console.log('RadarAutocomplete', err)
			throw err
		})
}
