import React from 'react'
import type { Profile } from '../../lib/supabase'
import { Button, Box, Typography } from '@mui/material'
import AppHeader from 'components/AppHeader'
import { FormInputSwitch, FormInputText } from 'components/forms/elements'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import { Validators } from 'utils/validators'

type FormValues = {
	full_name: string
	email: string
	phone_number: string
	allow_sms: boolean
}

type Props = {
	readonly profile: Profile
	onSave: (newProfile: Partial<Omit<Profile, 'id'>>) => void
	onCancel: () => void
}

const EditProfile: React.FC<Props> = ({ profile, onSave, onCancel }) => {
	const { handleSubmit, control } = useForm<FormValues>({
		resolver: yupResolver(
			object().shape({
				full_name: Validators.NAME.required(),
				email: Validators.EMAIL.required(),
				phone_number: Validators.PHONE.required(),
				allow_sms: Validators.BOOLEAN.required(),
			})
		),
		defaultValues: {
			full_name: profile.full_name,
			email: profile.email || '',
			phone_number: profile.phone_number || '',
			allow_sms: profile.allow_sms,
		},
	})

	const handleFormSubmit = (data: FormValues) => {
		onSave(data)
	}

	return (
		<Box flexGrow={1}>
			<AppHeader
				leftComponent={
					<Button sx={{ color: '#0195f5' }} onClick={onCancel}>
						Cancel
					</Button>
				}
				title={'Profile'}
				rightComponent={
					<Button
						sx={{ color: 'white' }}
						type='submit'
						form='edit-form'
					>
						Save
					</Button>
				}
			/>
			<form
				id='edit-form'
				onSubmit={handleSubmit(handleFormSubmit)}
				style={{ padding: '2rem' }}
			>
				<FormInputText
					name='full_name'
					variant='filled'
					label='Name'
					control={control}
				/>

				<FormInputText
					name='email'
					label='Email'
					variant='filled'
					control={control}
					sx={{ marginTop: '1rem' }}
				/>

				<div
					style={{
						marginTop: '1.0rem',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'baseline',
					}}
				>
					<FormInputText
						name='phone_number'
						label='Phone Number'
						variant='filled'
						style={{ width: '200px' }}
						control={control}
						type='tel'
					/>

					<FormInputSwitch
						name='allow_sms'
						label='Allow SMS'
						control={control}
					/>
				</div>
				<Typography
					marginTop={'20px'}
					paddingRight={'10px'}
					fontSize={'0.8rem'}
					color={'#666666'}
				>
					If you turn off SMS, you will still receive Joyner
					notifications via email. You will continue to recieve Joyner
					sign-in codes via SMS, regardless of this setting, for
					security purposes.
				</Typography>
			</form>
		</Box>
	)
}

export default EditProfile
