import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import ViewPoll from './ViewPoll'
import EditPoll from './EditPoll'
import {
	useGetPoll,
	useGetChoicesByPollId,
	useUpdatePoll,
	useUpdateChoice,
	useCreateChoice,
	useDeleteChoice,
	useDeletePoll,
	useDeleteChoicesByPollId,
} from 'api/polls'
import { Poll, PollChoice } from 'lib/supabase'
import { useUserContext } from 'contexts/UserContext'
import { v4 as uuidV4 } from 'uuid'
import { DateTime } from 'luxon'
import { useGroupsContext } from 'contexts/GroupsContext'
import { voteController } from 'utils'
import { sendNotification } from '../../vercel/apicalls'
import { useSnackbarContext } from 'contexts/SnackbarContext'

const PollPage: React.FC = () => {
	const navigate = useNavigate()
	const profile = useUserContext().profile
	const [editing, setEditing] = useState(false)
	const { pollId } = useParams()
	const updatePoll = useUpdatePoll()
	const updateChoice = useUpdateChoice()
	const createChoice = useCreateChoice()
	const deleteChoice = useDeleteChoice()
	const deletePoll = useDeletePoll()
	const deleteChoicesByPollId = useDeleteChoicesByPollId()
	const { userIsAdminOfGroup } = useGroupsContext()
	const { showSnackbar } = useSnackbarContext()

	const poll: Poll | undefined = useGetPoll({
		pollId: pollId!,
		options: { enabled: !!pollId },
	}).data

	const choices: PollChoice[] =
		useGetChoicesByPollId({
			pollId: pollId!,
			options: { enabled: !!pollId },
		}).data || []

	useEffect(() => {
		let i = choices.length
		while (i < 6) {
			choices.push({
				id: '',
				created_at: '',
				poll_id: pollId || '',
				choice: '',
				votes: [],
				sort_order: null,
			})
			i++
		}
	}, [choices])

	const handleEdit = () => {
		setEditing(true)
	}

	const handleCancel = () => {
		setEditing(false)
	}

	const handleSave = async (
		newPoll: Partial<Omit<Poll, 'id'>>,
		newChoices: PollChoice[]
	) => {
		// pad newChoices with empty choices
		let i = newChoices.length
		while (i < 6) {
			newChoices.push({
				id: '',
				created_at: '',
				poll_id: pollId || '',
				choice: '',
				votes: [],
				sort_order: null,
			})
			i++
		}

		// loop newChoices for what to do with each
		// id='' and choice != '' means new choice
		// id!='' and choice !='' means update choice
		// id!='' and choice ='' means delete choice
		newChoices.forEach(async (choice, index) => {
			if (choice.id === '' && choice.choice !== null && pollId) {
				const choiceId = uuidV4()
				newChoices[index].id = choiceId
				newChoices[index].poll_id = pollId
				newChoices[index].created_at = DateTime.now().toISO()
				newChoices[index].choice = choice.choice
				newChoices[index].votes = []
				await createChoice.mutateAsync({ choice: newChoices[index] })
			} else if (choice.id !== '' && choice.choice !== null) {
				await updateChoice.mutateAsync({
					choice: {
						...newChoices[index],
						id: newChoices[index].id,
					},
				})
			} else if (choice.id !== '' && choice.choice === null) {
				await deleteChoice.mutateAsync({ choiceId: choice.id })
			} else if (choice.id === '' && choice.choice === null) {
				// padded empty choice so do nothing
			}
		})

		poll &&
			newPoll &&
			(await updatePoll.mutateAsync({
				poll: {
					...newPoll,
					id: poll.id,
				},
			}))

		setEditing(false)
	}

	const handleRemind = () => {
		if (!poll || !profile) return

		let furl = import.meta.env.VITE_VERCELAPI_HOST + 'email/poll-reminder'
		furl += '?gid=' + profile.active_group
		furl += '&pname=' + poll.title
		sendNotification(furl)
		showSnackbar('Reminder Sent to All Members', 'success')
	}

	const handleResetPoll = async () => {
		choices.forEach(async (choice) => {
			choice.votes = []
			if (choice.id !== '') {
				await updateChoice.mutateAsync({
					choice: {
						...choice,
						id: choice.id,
					},
				})
			}
		})
	}

	const handleVote = (voteChoices: voteController[]) => {
		// users can only vote once so no check needed for prior votes
		voteChoices.forEach(async (vote) => {
			if (vote.checked) {
				const choice = choices.find((c) => c.id === vote.choice_id)
				if (choice && profile) {
					choice.votes.push(profile.id)
					await updateChoice.mutateAsync({ choice })
				}
			}
		})
	}

	const handleDelete = async () => {
		if (!poll) return
		navigate('/polls')
		await deleteChoicesByPollId.mutateAsync({ pollId: poll.id })
		await deletePoll.mutateAsync({ pollId: poll.id })
	}

	const onBack = () => {
		navigate('/polls')
	}

	return (
		<main>
			{editing ? (
				<EditPoll
					poll={poll as Poll}
					choices={choices as PollChoice[]}
					onSave={handleSave}
					onCancel={handleCancel}
					onDelete={handleDelete}
				></EditPoll>
			) : (
				<ViewPoll
					poll={poll as Poll}
					choices={choices as PollChoice[]}
					onBack={onBack}
					canViewButtons={
						poll?.poll_owner_id === profile?.id ||
						userIsAdminOfGroup(poll?.group_id ?? '')
					}
					onEdit={handleEdit}
					onRemind={handleRemind}
					onVote={handleVote}
					onReset={handleResetPoll}
				></ViewPoll>
			)}
		</main>
	)
}

export default PollPage
