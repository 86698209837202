import queryClient from '../../lib/reactQuery'
import { useMutation } from '@tanstack/react-query'
import { TABLE_GROUPS } from 'lib/supabase'
import { createGeneric } from '../generic'

import type { MutationConfig } from '../../lib/reactQuery'
import type { Group } from '../../lib/supabase'
import { getQueryKey } from './get'
import { getManyQueryKey } from './getMany'
import { useUserContext } from 'contexts/UserContext'

type CreateGroupArgs = {
	group: Group
}
export const createGroup = async ({ group }: CreateGroupArgs) =>
	createGeneric({ tableName: TABLE_GROUPS, data: group })

type UseCreateGroupOptions = {
	options?: MutationConfig<typeof createGroup>
}
export const useCreateGroup = ({ options }: UseCreateGroupOptions = {}) => {
	const { profile } = useUserContext()
	return useMutation({
		onMutate: (variables) => {
			const previousGroup = queryClient.getQueryData(
				getQueryKey(variables.group.id)
			)
			// optimistically update the cache
			queryClient.setQueryData(
				getQueryKey(variables.group.id),
				variables.group
			)
			// for reverting the optimistic update
			return previousGroup
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: (error, variables, context: any) => {
			// revert the cache back to the previous value
			if (context) {
				queryClient.setQueryData(
					getQueryKey(context.previousGroup.id),
					context.previousGroup
				)
			}
		},
		onSuccess: (_, variables) => {
			// fetch the latest version in the background
			queryClient.invalidateQueries({
				queryKey: getQueryKey(variables.group.id),
			})
			// remove the list of groups from the cache, then fetch the new list
			queryClient.setQueryData(getManyQueryKey(profile?.id), undefined)
			queryClient.invalidateQueries({
				queryKey: getManyQueryKey(profile?.id),
			})
		},
		...options,
		mutationFn: createGroup,
	})
}
