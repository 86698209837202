import React, { useState } from 'react'
import ActivityForm, {
	type ActivityFormValues,
} from 'components/forms/prebuilt/ActivityForm'
import type { Activity, Group, Venue } from 'lib/supabase'
import { DateTime } from 'luxon'
import { DEFAULT_ERROR_MESSAGE, combineDateAndTime } from 'utils'
import { v4 as uuidV4 } from 'uuid'
import { AppBar, Button } from '@mui/material'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { useNavigate } from 'react-router'
import BinaryDialog from 'components/dialogs/BinaryDialog'

type Props = {
	profileId: string
	venues: Venue[]
	activeGroup: Group
	onCancel: () => void
	onNewActivity: (data: Activity, sendEmail: boolean) => Promise<Error | void>
	onCreateVenue: (venue: Venue) => Promise<Error | void>
}
const NewActivity: React.FC<Props> = ({
	profileId,
	venues,
	activeGroup,
	onCancel,
	onNewActivity,
	onCreateVenue,
}) => {
	const { showSnackbar } = useSnackbarContext()
	const navigate = useNavigate()

	const [showDialog, setShowDialog] = useState(false)
	const [newActivity, setNewActivity] = useState<Activity | null>(null)

	const handleQuickAddVenue = async (venue: Venue): Promise<Venue | null> => {
		const error = await onCreateVenue(venue)
		if (error) {
			console.error(error)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
			return null
		}

		return venue
	}

	const handleSubmit = async (data: ActivityFormValues) => {
		const datetime = combineDateAndTime(data.date, data.time)

		const activityId = uuidV4()
		const newActivity: Activity = {
			id: activityId,
			group_id: activeGroup!.id,
			name: data.name,
			venue_id: data.venue,
			datetime,
			note: data.note || null,
			link: data.url || null,
			max_spots: data.maxSpots,
			published: true,
			active: true,
			ins: [],
			outs: [],
			created_at: DateTime.now().toISO(),
			updated_at: null,
			activity_owner_id: profileId,
		}

		setNewActivity(newActivity)
		setShowDialog(true)
	}

	const handleAddNewActivity = async (sendEmail: boolean) => {
		if (newActivity === null) return
		const error = await onNewActivity(newActivity, sendEmail)
		if (error) {
			console.error(error)
			showSnackbar(DEFAULT_ERROR_MESSAGE, 'error')
			return
		}

		navigate(`/activity/${newActivity?.id}`)
	}

	return (
		<>
			<ActivityForm
				id='new-activity-form'
				venues={venues}
				onSubmit={handleSubmit}
				defaultValues={{
					name: '',
					venue: '',
					date: DateTime.now().startOf('hour').toISO(),
					time: DateTime.now().startOf('hour').toISO(),
					note: '',
					url: '',
					maxSpots: 0,
				}}
				onQuickAddVenue={handleQuickAddVenue}
			/>

			<BinaryDialog
				isOpen={showDialog}
				onAnswer={(answer: boolean) => handleAddNewActivity(answer)}
				title={'Send Group Notification?'}
				content={
					'Do you want Joyner to automatically send a notification to all group members about this new activity?'
				}
				yesButtonText='Send'
				noButtonText='Do Not Send'
			/>

			<AppBar
				position='fixed'
				sx={{
					top: 'auto',
					bottom: 0,
					background: '#ffffff',
					paddingBottom: '20px',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '1.0rem',
						justifyContent: 'end',
						margin: '10px 20px',
					}}
				>
					<Button onClick={onCancel}>Cancel</Button>
					<Button
						variant='contained'
						type='submit'
						form='new-activity-form'
					>
						Create Activity
					</Button>
				</div>
			</AppBar>
		</>
	)
}

export default NewActivity
