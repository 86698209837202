import { getProfile } from './get'
import { useQueries } from '@tanstack/react-query'

import type { QueryConfig } from '../../lib/reactQuery'

type UseGetProfilesOptions = {
	profileIds: string[]
	options?: QueryConfig<typeof getProfile>
}
export const useGetProfiles = ({
	profileIds,
	options,
}: UseGetProfilesOptions) => {
	return useQueries({
		...options,
		queries: profileIds.map((profileId) => ({
			queryKey: ['profile', profileId],
			queryFn: () => getProfile({ profileId }),
		})),
	})
}
