import React from 'react'
import { Typography, Button } from '@mui/material'
import AnonHeader from '../../components/AnonHeader'
import { useAuthContext } from 'contexts/AuthContext'
import { useNavigate } from 'react-router'
import CreateAccountForm, {
	CreateAccountFormValues,
} from 'components/forms/prebuilt/CreateAccountForm'
import { AuthApiError } from '@supabase/supabase-js'
import { useSnackbarContext } from 'contexts/SnackbarContext'
import { sendNotification } from '../../vercel/apicalls'

const CreateAccount = () => {
	const { signUpWithPhone } = useAuthContext()
	const { showSnackbar } = useSnackbarContext()
	const navigate = useNavigate()

	const handleFormSubmit = async (data: CreateAccountFormValues) => {
		const { phone, password, fullName, email } = data
		try {
			await signUpWithPhone(phone, password, fullName, { email })

			// send user to home screen
			navigate('/')

			// send welcome email to new user
			let furl =
				import.meta.env.VITE_VERCELAPI_HOST + 'email/user-welcome'
			furl += '?un=' + encodeURIComponent(fullName)
			furl += '&ue=' + encodeURIComponent(email)
			sendNotification(furl)

			// post slack message about new user sign up
			let surl =
				import.meta.env.VITE_VERCELAPI_HOST + 'slack/notify-newuser'
			surl += '?un=' + encodeURIComponent(fullName)
			sendNotification(surl)
		} catch (err) {
			if (err instanceof AuthApiError) {
				if (err.message === 'User already registered') {
					showSnackbar(
						'Phone number already registered, do you already have an account?',
						'error'
					)
				}
			} else {
				showSnackbar('Unable to create account', 'error')
			}
		}
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
			}}
		>
			<AnonHeader />

			<Typography
				component='h1'
				sx={{
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '1.6em',
					marginTop: '2rem',
					marginBottom: '2rem',
				}}
			>
				Create Joyner Account
			</Typography>

			<div
				style={{
					padding: '0 2rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
					alignItems: 'left',
					justifyContent: 'left',
					maxWidth: '600px',
				}}
			>
				<CreateAccountForm onSubmit={handleFormSubmit} />
				<Button variant='outlined' onClick={() => navigate(-1)}>
					Cancel
				</Button>
			</div>
		</div>
	)
}

export default CreateAccount
